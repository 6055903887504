import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrentDateDisplay from "./scheduleComponents/currentDateDisplay";
import CurrentTime from "./scheduleComponents/currentTime";
import DateNavigator from "./scheduleComponents/dateNavigator";
import DurationSelector from "./scheduleComponents/durationSelector";
import WideSidePanel from "../../applicationUi/components/WideSidePanel";
import { fetchClinicians } from "../../store/thunks/cliniciansThunk";
import {
  fetchAppointmentsByClinicianId,
  createAppointment,
  fetchAppointmentsList,
  fetchAppointmentsByDateRange,
  fetchAppointmentsByDate,
} from "../../store/thunks/appointmentsThunk";
import ClinicianSchedule from "./scheduleComponents/clinicianSchedule";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ClinicScheduleModal({
  setModalOpen,
  onTimeSlotSelect,
  selectedClient,
  selectedService,
  selectedProduct,
  selectedClinician,
  rescheduleData, // Ensure this prop is being passed correctly
}) {
  const dispatch = useDispatch();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [showDurationSelector, setShowDurationSelector] = useState(false);
  const [selectorPosition, setSelectorPosition] = useState({ x: 0, y: 0 });
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [showWorkingHours, setShowWorkingHours] = useState(true);
  const [isWideSidePanelOpen, setIsWideSidePanelOpen] = useState(false);
  const [rescheduleDataState, setRescheduleDataState] =
    useState(rescheduleData);

  const container = useRef(null);

  const appointments = useSelector(
    (state) => state.appointments.appointments || []
  );
  const user = useSelector((state) => state.auth.user || []);
  const clinicians = useSelector((state) => state.clinicians.clinicians || []);

  useEffect(() => {
    console.log("ClinicScheduleModal received props:", {
      selectedClient,
      selectedService,
      selectedProduct,
      selectedClinician,
      rescheduleData,
    });
    dispatch(fetchClinicians());
  }, [dispatch]);

  useEffect(() => {
    // dispatch(fetchAppointmentsList());
    console.log("FETCH APPOINTMENTS BY DATE " + currentDate);
    dispatch(
      fetchAppointmentsByDate({
        date: currentDate,
      })
    );
  }, [dispatch, currentDate, isWideSidePanelOpen]);

  // useEffect(() => {
  //     if (selectedClinician) {
  //         console.log(
  //             `Fetching appointments for initial selected clinician: ${selectedClinician._id}`
  //         );
  //         dispatch(fetchAppointmentsByClinicianId(selectedClinician._id));
  //     } else {
  //         console.log("No clinician selected on initial load");
  //     }
  // }, [dispatch, selectedClinician]);

  // const refetchAppointments = useCallback(() => {
  //     console.log("refetchAppointments function called");
  //     if (selectedClinician) {
  //         console.log(
  //             `Fetching appointments for clinician ID: ${selectedClinician._id}`
  //         );
  //         dispatch(fetchAppointmentsByClinicianId(selectedClinician._id));
  //     } else {
  //         console.log("No clinician selected, skipping fetch.");
  //     }
  // }, [dispatch, selectedClinician]);

  const onPrevDay = () => {
    setCurrentDate(
      (prevDate) =>
        new Date(
          prevDate.getFullYear(),
          prevDate.getMonth(),
          prevDate.getDate() - 1
        )
    );
    // refetchAppointments(); // Ensure appointments are refreshed
  };

  const onToday = () => {
    setCurrentDate(new Date());
    // refetchAppointments(); // Ensure appointments are refreshed
  };

  const onNextDay = () => {
    setCurrentDate(
      (prevDate) =>
        new Date(
          prevDate.getFullYear(),
          prevDate.getMonth(),
          prevDate.getDate() + 1
        )
    );
    // refetchAppointments(); // Ensure appointments are refreshed
  };

  const onPrevWeek = () => {
    setCurrentDate(
      (prevDate) =>
        new Date(
          prevDate.getFullYear(),
          prevDate.getMonth(),
          prevDate.getDate() - 7
        )
    );
    // refetchAppointments(); // Ensure appointments are refreshed
  };

  const onNextWeek = () => {
    setCurrentDate(
      (prevDate) =>
        new Date(
          prevDate.getFullYear(),
          prevDate.getMonth(),
          prevDate.getDate() + 7
        )
    );
    // refetchAppointments(); // Ensure appointments are refreshed
  };

  const onDateChange = (date) => {
    setCurrentDate(date);
    // refetchAppointments(); // Ensure appointments are refreshed
  };

  const handleDurationSelect = (duration) => {
    setShowDurationSelector(false);
    const title = rescheduleDataState
      ? rescheduleDataState.title
      : prompt("Enter the appointment title:");
    const patientName = rescheduleDataState
      ? `${rescheduleDataState.client.firstName} ${rescheduleDataState.client.lastName}`
      : prompt("Enter the patient's name:");
    if (title && patientName) {
      const startTime = selectedTimeSlot;
      const endTime = calculateEndTime(startTime, parseInt(duration));
      const newAppointment = {
        ...rescheduleDataState,
        clinic: selectedClinician.clinic,
        clinician: selectedClinician._id,
        client: selectedClient._id,
        title,
        start: new Date(
          `${currentDate.toDateString()} ${startTime}`
        ).toISOString(),
        end: new Date(`${currentDate.toDateString()} ${endTime}`).toISOString(),
        service: selectedService._id,
        status: ["Booked"],
        history: [
          {
            status: "Booked",
            comments: `Created for ${patientName}`,
            user: user._id,
          },
          ...(rescheduleDataState
            ? [
                {
                  status: "Changed",
                  comments: "Rescheduled",
                  timestamp: new Date().toISOString(),
                  user: user._id,
                },
              ]
            : []),
        ],
      };
      console.log("New Appointment Created:", newAppointment);
      dispatch(createAppointment(newAppointment)).then(() => {
        // refetchAppointments();
      });
    }
    setRescheduleDataState(null);
  };

  const calculateEndTime = (startTime, duration) => {
    const [hour, minute] = startTime.split(":").map(Number);
    const time = new Date();
    time.setHours(hour, minute + duration, 0);
    return `${time.getHours()}:${time
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  };

  const handleCancel = () => {
    setShowDurationSelector(false);
  };

  const handleAppointmentClick = (appointment) => {
    setSelectedEvent(appointment);
    setIsWideSidePanelOpen(true);
    console.log("in clinicschedulemodal, this is the apppt", appointment);
  };

  const handleCloseWideSidePanel = useCallback(() => {
    setIsWideSidePanelOpen(false);
    setSelectedEvent(null);
    // refetchAppointments();
    console.log("WideSidePanel closed");
  });

  const handleReschedule = (appointment) => {
    console.log("handleReschedule called with appointment:", appointment);
    setRescheduleDataState(appointment);
    console.log("rescheduleDataState set to:", appointment);
    setIsWideSidePanelOpen(false);
    console.log("isWideSidePanelOpen set to false");
    setModalOpen(true);
    console.log("setModalOpen called with true");
  };

  const filteredClinicians = useMemo(
    () =>
      clinicians.filter((clinician) =>
        `${clinician.firstName} ${clinician.lastName}`
          .toLowerCase()
          .includes(filterText.toLowerCase())
      ),
    [clinicians, filterText]
  );

  return (
    <div className="flex h-full flex-col bg-gray-100">
      {showDurationSelector && (
        <DurationSelector
          x={selectorPosition.x}
          y={selectorPosition.y}
          onSelect={handleDurationSelect}
          onCancel={handleCancel}
        />
      )}
      <header className="flex items-center justify-between border-b-2 border-gray-300 p-4">
        <div className="flex items-center">
          <input
            type="text"
            placeholder="Filter clinicians"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className="ml-2 p-2 border rounded"
          />
          <div className="flex items-center pl-4">
            <Switch
              checked={showWorkingHours}
              onChange={setShowWorkingHours}
              className={classNames(
                showWorkingHours ? "bg-indigo-600" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
              )}>
              <span
                aria-hidden="true"
                className={classNames(
                  showWorkingHours ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <span className="ml-3 text-sm font-medium text-gray-900">
              Working Hours (9AM - 6PM)
            </span>
          </div>
        </div>

        <CurrentTime />
        <div className="flex items-center">
          <CurrentDateDisplay currentDate={currentDate} />
          <DateNavigator
            onPrevDay={onPrevDay}
            onToday={onToday}
            onNextDay={onNextDay}
            onPrevWeek={onPrevWeek}
            onNextWeek={onNextWeek}
            onDateChange={onDateChange}
          />
          <button
            className="ml-2 p-2 rounded-full hover:bg-gray-200 focus:outline-none"
            onClick={() => {
              setModalOpen(false);
              console.log("ClinicScheduleModal closed");
            }}>
            <span className="sr-only">Close</span>
            &times;
          </button>
        </div>
      </header>

      <div className="overflow-auto">
        <div className="isolate flex flex-auto bg-white">
          {filteredClinicians.map((clinician, index) => (
            <div
              ref={container}
              className="flex flex-auto flex-col min-w-80"
              key={index}>
              <ClinicianSchedule
                clinician={clinician}
                appointments={appointments}
                selectedClient={selectedClient}
                selectedService={selectedService}
                selectedClinician={selectedClinician}
                currentDate={currentDate}
                handleAppointmentClick={handleAppointmentClick}
                showWorkingHours={showWorkingHours}
                onReschedule={handleReschedule}
                rescheduleData={rescheduleData}
              />
            </div>
          ))}
        </div>
      </div>

      {selectedEvent && (
        <WideSidePanel
          title={"Appointment Summary"}
          open={isWideSidePanelOpen}
          onClose={handleCloseWideSidePanel}
          client={selectedEvent.client}
          appointment={selectedEvent}
          onReschedule={handleReschedule}
          // refetchAppointments={refetchAppointments} // Pass the refetch function here
        />
      )}
    </div>
  );
}
