import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const generatePDF = createAsyncThunk(
  "pdf/generate",
  async (data, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token, responseType: "blob" });

    try {
      const response = await axios.post(
        `${baseURL}/api/generate-pdf`,
        data,
        config
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);
