import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateInvoice } from "../../store/thunks/invoiceThunk";
import { PlusCircleIcon } from "@heroicons/react/20/solid";

const EditInvoiceForm = ({ invoice, closeForm, refetchInvoices }) => {
  const [formData, setFormData] = useState({ ...invoice });
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleServiceChange = (index, e) => {
    const { name, value } = e.target;
    const updatedServices = formData.services.map((service, i) =>
      i === index ? { ...service, [name]: value } : service
    );
    setFormData({ ...formData, services: updatedServices });
  };

  const handleAddService = () => {
    setFormData({
      ...formData,
      services: [
        ...formData.services,
        { description: "", cost: 0, quantity: 1, taxRate: 0, isTaxable: false },
      ],
    });
  };

  const handleDeleteService = (indexToRemove) => {
    // Create a copy of the services array
    const updatedServices = [...formData.services];

    // Remove the service at the specified index
    updatedServices.splice(indexToRemove, 1);

    // Update the formData state with the new services array
    setFormData({
      ...formData,
      services: updatedServices,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(updateInvoice({ id: invoice._id, updateData: formData }));
    refetchInvoices();
    closeForm();
  };

  return (
    <div className="bg-white p-4 rounded shadow">
      <h2 className="text-lg font-semibold mb-4">Edit Invoice</h2>
      <form onSubmit={handleSubmit}>
        {/* Invoice ID and Service Date */}
        <div className="mb-4 flex">
          <div className="flex-1 mr-2">
            <label className="block text-sm font-medium text-gray-700 font-semibold">
              Invoice ID
            </label>

            <input
              type="text"
              name="invoiceId"
              value={formData.invoiceId}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              disabled
            />
          </div>

          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700">
              Service Date
            </label>
            <input
              type="date"
              name="serviceDate"
              value={new Date(formData.serviceDate)
                .toISOString()
                .substring(0, 10)}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        {/* Client Name and Address */}
        <div className="mb-4 flex">
          <div className="mr-2 flex-1">
            <label className="block text-sm font-medium text-gray-700">
              Client Name
            </label>
            <input
              type="text"
              value={`${formData.client.firstName.trim()} ${formData.client.lastName.trim()}`}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              disabled
            />
          </div>

          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700">
              Client Address
            </label>
            <input
              type="text"
              value={`${formData.client.address.streetNumber} ${formData.client.address.streetName}, ${formData.client.address.city}, ${formData.client.address.province}, ${formData.client.address.postalCode}, ${formData.client.address.country}`}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              disabled
            />
          </div>
        </div>

        {/* Clinic Name and Address */}
        <div className="mb-4 flex">
          <div className="mr-2 flex-1">
            <label className="block text-sm font-medium text-gray-700">
              Clinic Name
            </label>
            <input
              type="text"
              value={formData.clinic.name}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              disabled
            />
          </div>

          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700">
              Clinic Address
            </label>
            <input
              type="text"
              value={`${formData.clinic.address.street}, ${formData.clinic.address.city}, ${formData.clinic.address.province}, ${formData.clinic.address.postalCode}, ${formData.clinic.address.country}`}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              disabled
            />
          </div>
        </div>

        {/* Services */}
        {formData.services.map((service, index) => (
          <div
            key={index}
            className="my-10 border border-gray-300 rounded-md p-4">
            <div className="flex justify-between">
              <h3 className="text-md font-semibold mb-2">
                Service {index + 1}
              </h3>
              <button
                type="button"
                onClick={() => handleDeleteService(index)}
                className="text-red-600 hover:text-red-900 text-sm font-medium focus:outline-none">
                Delete Service
              </button>
            </div>
            <div className="mb-4 flex">
              <div className="mr-2 flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <input
                  type="text"
                  name="description"
                  value={service.description}
                  onChange={(e) => handleServiceChange(index, e)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              <div className="mr-2 flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  Cost ($)
                </label>
                <input
                  type="number"
                  name="cost"
                  value={service.cost}
                  onChange={(e) => handleServiceChange(index, e)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  Quantity
                </label>
                <input
                  type="number"
                  name="quantity"
                  value={service.quantity}
                  onChange={(e) => handleServiceChange(index, e)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="mb-4 flex">
              <div className="mr-2 flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  Tax Rate (% as decimal)
                </label>
                <input
                  type="number"
                  step="0.01"
                  name="taxRate"
                  value={service.taxRate}
                  onChange={(e) => handleServiceChange(index, e)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              <div className="mr-2 flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  Is Taxable
                </label>
                <select
                  name="isTaxable"
                  value={service.isTaxable}
                  onChange={(e) => handleServiceChange(index, e)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700">
                  Notes
                </label>
                <input
                  type="text"
                  name="notes"
                  value={service.notes}
                  onChange={(e) => handleServiceChange(index, e)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        ))}

        <button
          type="button"
          onClick={handleAddService}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 mb-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <PlusCircleIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
          Add Service
        </button>

        {/* Notes */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Notes
          </label>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Submit and Cancel Buttons */}
        <div className="flex justify-end">
          <button
            type="button"
            onClick={closeForm}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-400 px-3 py-2 mb-4 mr-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 mb-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditInvoiceForm;
