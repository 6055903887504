// UploadClients.js

import React, { useState } from 'react';
import axios from 'axios';
import { generateConfig } from '../../utils/api';
import { useSelector } from 'react-redux';

function UploadAppointments() {
    const token = useSelector((state) => (state.auth.token));
    const { baseURL } = generateConfig({token: token});
    const [file, setFile] = useState(null);

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onUpload = async () => {
        const formData = new FormData();
        formData.append('csv', file);

        try {
            const response = await axios.post(`${baseURL}/api/appointments/import`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "x-api-token": process.env.REACT_APP_TOKEN,
                    "x-api-secret": process.env.REACT_APP_SECRET,
                }
            });
            console.log(response.data);
            alert('File upload completed successfully!');
        } catch (err) {
            console.error("Error uploading file:", err);
        }
    };

    return (
        <div className="container mt-5">
            <h2>Upload Client Data CSV</h2>
            <div className="mb-3">
                <label className="form-label">CSV File</label>
                <input type="file" className="form-control" onChange={onFileChange} />
            </div>
            <button className="btn btn-primary" onClick={onUpload}>Upload</button>
        </div>
    );
}

export default UploadAppointments;
