import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyPassword } from "../store/thunks/authThunk";

export default function LockScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, isPasswordVerified, user } = useSelector(
    (state) => state.auth
  );
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  // Redirect if not authenticated or password is already verified
  useEffect(() => {
    if (!isAuthenticated || isPasswordVerified) {
      navigate("/");
    }
  }, [isAuthenticated, isPasswordVerified, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null); // Clear any previous errors

    try {
      const email = user?.email;
      if (!email) {
        throw new Error("Email not found");
      }

      const resultAction = await dispatch(verifyPassword({ email, password }));

      if (verifyPassword.fulfilled.match(resultAction)) {
        navigate("/");
      } else {
        setError(resultAction.payload);
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
      console.error("Error verifying password:", err);
    }

    setPassword("");
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Unlock screen
        </h2>
        <label className="block text-lg font-medium">
          {user.firstName} {user.lastName}
        </label>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900">
              Password
            </label>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Unlock
              </button>
            </div>
            {error && <div className="text-red-500 text-sm">{error}</div>}
          </div>
        </form>
      </div>
    </div>
  );
}
