// src/store/thunks/payrollThunk.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const createPayroll = createAsyncThunk(
  "payroll/createPayroll",
  async (payrollData, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/payroll`,
        {
          ...payrollData,
          hrId: payrollData.hrId || null,
        },
        config
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 409) {
        return rejectWithValue(
          "Payroll entry already exists for this appointment."
        );
      }
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchPayrolls = createAsyncThunk(
  "payroll/fetchPayrolls",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      console.log("Fetching payrolls with config:", config); // Log the config

      const response = await axios.get(`${baseURL}/api/payroll`, config);
      console.log("Payrolls fetched:", response.data); // Log the response data

      return response.data;
    } catch (error) {
      console.error("Error fetching payrolls:", error); // Log the error
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchPayrollsByClinicianId = createAsyncThunk(
  "payroll/fetchPayrollsByClinicianId",
  async (clinicianId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/payroll/clinician/${clinicianId}`,
        config
      );

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchPayrollsByClinicianAndPayPeriod = createAsyncThunk(
  "payroll/fetchPayrollsByClinicianAndPayPeriod",
  async (
    { clinicianId, payPeriod },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/payroll/clinician/${clinicianId}/byPayPeriod?payPeriod=${payPeriod}`,
        config
      );

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchPayrollsByClinicianStatusAndPayPeriod = createAsyncThunk(
  "payroll/fetchPayrollsByClinicianStatusAndPayPeriod",
  async (
    { clinicianId = null, statuses, payPeriod }, // clinicianId is now optional
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      // Join statuses array into a comma-separated string
      const statusQuery = statuses.join(",");

      const response = await axios.get(
        `${baseURL}/api/payroll/clinician/${
          clinicianId ? clinicianId : "all"
        }/byStatusAndPayPeriod?status=${statusQuery}&payPeriod=${payPeriod}`,
        config
      );

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// NEW - AUG 22 (after vaca)
// Thunk for fetching payrolls by pay period
// Thunk for fetching payrolls by pay period
export const fetchPayrollsByPayPeriod = createAsyncThunk(
  "payroll/fetchPayrollsByPayPeriod",
  async ({ payPeriod }, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    console.log("Fetching payrolls for payPeriod:", payPeriod);
    try {
      const response = await axios.get(
        `${baseURL}/api/payroll/period/${encodeURIComponent(payPeriod)}`,
        config
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching payrolls:", error.message);
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// NEW - Aug 2
// Thunk for fetching payrolls by pay period dates
export const fetchPayrollsByPayPeriodDates = createAsyncThunk(
  "payroll/fetchPayrollsByPayPeriodDates",
  async ({ payPeriodDates }, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    console.log("payperioddates in thunk", payPeriodDates);
    try {
      const response = await axios.get(
        `${baseURL}/api/payroll/byPayPeriodDates?payPeriodDates=${encodeURIComponent(
          payPeriodDates
        )}`,
        config
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching payrolls:", error.message);
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchPayrollById = createAsyncThunk(
  "payroll/fetchPayrollById",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/payroll/${id}`, config);
      return response.data.payroll;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const saveToPayroll = createAsyncThunk(
  "payroll/saveToPayroll",
  async (appointment, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/payroll`,
        {
          appointmentId: appointment._id,
          clinicianId: appointment.clinician._id,
          invoiceId: appointment.invoice._id,
          originalAmount: appointment.invoice.amount,
          percentReceived: 100,
          payrollAmount: appointment.invoice.amount,
          payPeriod: null, // Add appropriate payPeriod data if available
          payPeriodDates: null, // Add appropriate payPeriodDates data if available
          status: "Pending",
        },
        config
      );

      return response.data.payroll;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const updatePayroll = createAsyncThunk(
  "payroll/updatePayroll",
  async ({ id, updateData }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.patch(
        `${baseURL}/api/payroll/${id}`,
        updateData,
        config
      );

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const deletePayroll = createAsyncThunk(
  "payroll/deletePayroll",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      await axios.delete(`${baseURL}/api/payroll/${id}`, config);
      return id;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);
