    import { useState } from "react";
    import { ChevronRightIcon } from "@heroicons/react/24/outline";
    import StatusLights from "./StatusLights";

    function AppointmentList({ appointments = [], onAppointmentClick }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items per page

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    const calculateDuration = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const duration = (endDate - startDate) / (1000 * 60); // Duration in minutes
        return duration;
    };

    function formatPrice(price) {
        if (price === undefined || price === null) {
        return "$0.00"; // Return a default value or handle it as needed
        }
        const numPrice = Number(price);
        return `$${numPrice.toFixed(2)}`;
    }

    let uniqueAppointments = [];
    // Filter out duplicate appointments based on the _id
    if (appointments) {
        uniqueAppointments = Array.from(
        new Set(appointments.map((a) => a._id))
        ).map((id) => appointments.find((a) => a._id === id));
    }

    // Sort appointments by start date and time
    uniqueAppointments.sort((a, b) => new Date(a.start) - new Date(b.start));

    // Pagination logic
    const totalItems = uniqueAppointments.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const paginatedAppointments = uniqueAppointments.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const TableHeader = () => (
        <thead>
        <tr>
            <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900 w-1/4">
            Appointment
            </th>
            <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
            Date & Time
            </th>
            <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
            Duration
            </th>
            <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
            Client
            </th>
            <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
            Amount
            </th>
            <th className="hidden bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900 md:block">
            Status
            </th>
        </tr>
        </thead>
    );

    const TableRow = ({ appointment, index }) => (
        <tr
        key={`${appointment._id}-${index}`}
        className="bg-white hover:bg-gray-100 cursor-pointer"
        onClick={() => onAppointmentClick(appointment)}
        >
        <td className="w-1/3 max-w-0 whitespace-nowrap px-2 py-4 text-sm text-gray-900">
            <div className="overflow-x-auto" style={{ maxWidth: "350px" }}>
            <p className="truncate text-gray-500 group-hover:text-gray-900">
                {appointment.service?.description || "N/A"}
            </p>
            </div>
        </td>
        <td className="whitespace-nowrap px-2 py-4 text-left text-sm text-gray-500">
            <time dateTime={appointment.datetime}>
            {new Date(appointment.start).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
            })}
            , {new Date(appointment.start).toLocaleTimeString("en-US")}
            </time>
        </td>
        <td className="whitespace-nowrap px-2 py-4 text-left text-sm text-gray-500">
            <span className="font-medium text-gray-900">
            {calculateDuration(appointment.start, appointment.end)}
            </span>{" "}
            min
        </td>
        <td className="whitespace-nowrap px-2 py-4 text-left text-sm text-gray-500">
            <div className="overflow-x-auto" style={{ maxWidth: "150px" }}>
            <span className="font-medium text-gray-900">
                {appointment.client?.firstName} {appointment.client?.lastName}
            </span>
            </div>
        </td>
        <td className="whitespace-nowrap px-2 py-4 text-left text-sm text-gray-500">
            <span className="font-medium text-gray-900">
            {appointment.service
                ? formatPrice(appointment.service.price)
                : "$0.00"}
            </span>
        </td>
        <td className="hidden whitespace-nowrap px-2 py-4 text-sm text-gray-500 md:block">
            <span>
            <StatusLights appointment={appointment} />
            </span>
        </td>
        </tr>
    );

    return (
        <>
        <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
            Recent activity
        </h2>

        {/* Activity list (smallest breakpoint only) */}
        <div className="shadow sm:hidden">
            <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
            {paginatedAppointments.map((appointment, index) => (
                <li key={`${appointment._id}-${index}`}>
                <span className="flex items-center space-x-4">
                    <span className="flex flex-1 space-x-2 truncate">
                    <span className="flex flex-col truncate text-sm text-gray-500">
                        <span className="truncate">
                        {appointment.service?.description || "N/A"}
                        </span>
                        <span>
                        <span className="font-medium text-gray-900">
                            {appointment.service
                            ? formatPrice(appointment.service.price)
                            : "$0.00"}
                        </span>{" "}
                        </span>
                        <time dateTime={appointment.start}>
                        {new Date(appointment.start).toLocaleString()}
                        </time>
                    </span>
                    </span>
                    <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                    />
                </span>
                </li>
            ))}
            </ul>

            <nav
            className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3"
            aria-label="Pagination"
            >
            <div className="flex flex-1 justify-between">
                <button
                type="button"
                onClick={() => setCurrentPage((page) => Math.max(page - 1, 1))}
                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                disabled={currentPage === 1}
                >
                Previous
                </button>
                <button
                type="button"
                onClick={() => setCurrentPage((page) => Math.min(page + 1, totalPages))}
                className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                disabled={currentPage === totalPages}
                >
                Next
                </button>
            </div>
            </nav>
        </div>

        {/* Activity table (small breakpoint and up) */}
        <div className="hidden sm:block">
            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="mt-2 flex flex-col">
                {/* Top Pagination */}
                <nav
                className="flex items-center justify-between border-b border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
                >
                <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> to{" "}
                    <span className="font-medium">
                        {Math.min(currentPage * itemsPerPage, totalItems)}
                    </span> of{" "}
                    <span className="font-medium">{totalItems}</span> results
                    </p>
                </div>
                <div className="flex flex-1 justify-between gap-x-3 sm:justify-end">
                    <button
                    type="button"
                    onClick={() => setCurrentPage((page) => Math.max(page - 1, 1))}
                    className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                    disabled={currentPage === 1}
                    >
                    Previous
                    </button>
                    <button
                    type="button"
                    onClick={() => setCurrentPage((page) => Math.min(page + 1, totalPages))}
                    className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                    disabled={currentPage === totalPages}
                    >
                    Next
                    </button>
                </div>
                </nav>
                <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                    <TableHeader />
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {paginatedAppointments.map((appointment, index) => (
                        <TableRow
                        key={`${appointment._id}-${index}`}
                        appointment={appointment}
                        index={index}
                        />
                    ))}
                    </tbody>
                </table>
                {/* Pagination */}
                <nav
                    className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                    aria-label="Pagination"
                >
                    <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> to{" "}
                        <span className="font-medium">
                        {Math.min(currentPage * itemsPerPage, totalItems)}
                        </span> of{" "}
                        <span className="font-medium">{totalItems}</span> results
                    </p>
                    </div>
                    <div className="flex flex-1 justify-between gap-x-3 sm:justify-end">
                    <button
                        type="button"
                        onClick={() => setCurrentPage((page) => Math.max(page - 1, 1))}
                        className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <button
                        type="button"
                        onClick={() => setCurrentPage((page) => Math.min(page + 1, totalPages))}
                        className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                    </div>
                </nav>
                </div>
            </div>
            </div>
        </div>
        </>
    );
    }

    export default AppointmentList;
