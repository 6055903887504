import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchApi } from "../../store/thunks/searchApiThunk";
import { setQuery } from "../../store/slices/searchApiSlice";

function Search({ className, placeholder = "Search..." }) {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [showResults, setShowResults] = useState(false);
  const { results, loading, error } = useSelector((state) => state.search);

  useEffect(() => {
    if (searchQuery) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  }, [searchQuery, results]);

  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    dispatch(setQuery(newQuery));
    dispatch(searchApi(newQuery));
  };

  const handleResultClick = (result) => {
    console.log("Clicked result:", result);
    setShowResults(false);
  };

  useEffect(() => {
    if (results.length > 0) {
      // console.log("Search results:", results);
    }
  }, [results]);

  return (
    <div className={className}>
      <input
        type="text"
        name="search"
        id="search"
        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
        placeholder={placeholder}
        value={searchQuery}
        onChange={handleSearchChange}
        onFocus={() => setShowResults(true)}
        onBlur={() => setShowResults(false)}
        autoComplete="off"
      />

      {showResults && results.length > 0 && (
        <ul className="absolute z-10 mt-2 w-full bg-white rounded-md shadow-lg max-h-60 overflow-y-auto">
          {results.slice(0, 10).map((result, index) => (
            <li
              key={index}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100 relative"
              onClick={() => handleResultClick(result)}>
              <span
                className={`absolute top-2 right-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium 
                                ${
                                  result.type === "Client"
                                    ? "bg-blue-100 text-blue-800"
                                    : result.type === "Appointment"
                                    ? "bg-green-100 text-green-800"
                                    : result.type === "Product"
                                    ? "bg-yellow-100 text-yellow-800"
                                    : result.type === "Service"
                                    ? "bg-yellow-100 text-orange-800"
                                    : result.type === "Invoice"
                                    ? "bg-red-100 text-red-800"
                                    : result.type === "Clinic"
                                    ? "bg-purple-100 text-purple-800"
                                    : "bg-gray-100 text-gray-800"
                                }`}>
                {result.type}
              </span>

              <span className="ml-4">
                {result.type === "Client" &&
                  `${result.firstName || "N/A"} ${result.lastName || "N/A"}`}
                {result.type === "Appointment" && (result.title || "N/A")}
                {result.type === "Product" &&
                  `${result.catalogCode || "N/A"} - ${result.item || "N/A"}`}
                {result.type === "Service" &&
                  `${result.serviceCode || ""} - ${
                    result.description || "N/A"
                  }`}
                {result.type === "Invoice" && (result.invoiceId || "N/A")}
                {result.type === "Clinic" && (result.name || "N/A")}
                {result.type === "User" &&
                  `${result.firstName || "N/A"} ${result.lastName || "N/A"}`}
              </span>
            </li>
          ))}
        </ul>
      )}

      {loading && <div>Loading...</div>}
      {error && <div>Error: {error.message}</div>}
    </div>
  );
}

export default Search;
