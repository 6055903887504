import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api"; 
import { handleApiError } from "../../utils/apiErrorHandler";

export const fetchReferrals = createAsyncThunk(
    "referral/fetchReferrals",
    async (_, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.get(
                `${baseURL}/api/referral/`,
                config
            );
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

export const createReferral = createAsyncThunk(
    "referral/createReferral",
    async (referralData, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.post(
                `${baseURL}/api/referral/`,
                referralData,
                config
            );
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);
