// UploadClients.js

import React, { useState } from "react";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { useSelector } from "react-redux";

function UploadClients() {
    const token = useSelector((state) => (state.auth.token));
    const { baseURL } = generateConfig();
    const [file, setFile] = useState(null);

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onUpload = async () => {
        const formData = new FormData();
        formData.append("csv", file);

        try {
            const response = await axios.post(
                `${baseURL}/api/clients/upload`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "x-api-token": process.env.REACT_APP_TOKEN,
                        "x-api-secret": process.env.REACT_APP_SECRET,
                        "x-user-token": token,
                    },
                }
            );
            console.log(response.data);
            alert("File upload completed successfully!");
        } catch (err) {
            console.error("Error uploading file:", err);
        }
    };

    return (
        <div class="container mx-auto mt-5">
            <h2 class="text-2xl font-semibold mb-3">Upload Client Data CSV</h2>
            <div class="mb-4">
                <label
                    for="csvFile"
                    class="block text-gray-700 text-sm font-bold mb-2"
                >
                    CSV File
                </label>
                <input
                    type="file"
                    id="csvFile"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    onChange={onFileChange}
                />
            </div>
            <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={onUpload}
            >
                Upload
            </button>
        </div>
    );
}

export default UploadClients;
