import React, { useState } from 'react';
import AddFamilyMemberModal from './AddFamilyMemberModal';
import Table from '../../applicationUi/components/Table'; // Adjust the path as necessary

const FamilyDetails = ({ client }) => {
  const [showModal, setShowModal] = useState({ type: '', open: false });

  console.log(client);

  // Utility function to calculate age from date of birth
  const calculateAge = (dateOfBirth) => {
    const dob = new Date(dateOfBirth);
    const diff = Date.now() - dob.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  // Utility function to format phone numbers
  const formatPhoneNumbers = (phoneNumbers) => {
    if (!phoneNumbers || !Array.isArray(phoneNumbers)) {
      return 'No phone numbers available'; // Handle missing phone numbers
    }
    return phoneNumbers.map((phone) => `${phone.type}: ${phone.number}`).join(', ');
  };

  // Format family member data to fit table structure
  const formatFamilyMember = (member) => {
    return {
      firstName: member.firstName,
      lastName: member.lastName,
      dateOfBirth: member.dateOfBirth ? new Date(member.dateOfBirth).toLocaleDateString() : 'N/A',
      age: calculateAge(member.dateOfBirth),
      email: member.email,
      formattedPhoneNumbers: formatPhoneNumbers(member.phoneNumbers),
    };
  };

  // Example formatted data for parents, children, and siblings
  const parents = client.parents?.map((parent) => formatFamilyMember(parent)) || [];
  const children = client.children?.map((child) => formatFamilyMember(child)) || [];
  // const siblings = client.siblings?.map((sibling) => formatFamilyMember(sibling)) || [];

  // Open the modal for adding a family member
  const handleOpenModal = (type) => {
    setShowModal({ type, open: true });
  };

  // Close the modal
  const handleCloseModal = () => {
    setShowModal({ type: '', open: false });
  };

  // Save the newly added family member (parent/child/sibling)
  const handleSaveMember = (formData) => {
    console.log(formData);
    // Logic to add new family member here
    handleCloseModal();
  };

  return (
    <div className="family-details space-y-8">
      <h1 className="header text-2xl font-bold">Family Details</h1>

      {/* Parents Table */}
      <Table
        columns={[
          { header: 'First Name', accessor: 'firstName' },
          { header: 'Last Name', accessor: 'lastName' },
          { header: 'Age', accessor: 'age' },
          { header: 'Email', accessor: 'email' },
          { header: 'Phone Numbers', accessor: 'formattedPhoneNumbers' },
        ]}
        data={parents}
        onRowClick={(row) => console.log(row)} // You can add custom row click actions
        actions={[
          {
            label: 'Link/Add Parent',
            onClick: () => handleOpenModal('parent'),
          },
        ]}
        title="Parents"
        description="List of parents"
        showPagination={false} // Remove pagination
      />

      {/* Children Table */}
      <Table
        columns={[
          { header: 'First Name', accessor: 'firstName' },
          { header: 'Last Name', accessor: 'lastName' },
          { header: 'Age', accessor: 'age' },
          { header: 'Email', accessor: 'email' },
          { header: 'Phone Numbers', accessor: 'formattedPhoneNumbers' },
        ]}
        data={children}
        onRowClick={(row) => console.log(row)} // You can add custom row click actions
        actions={[
          {
            label: 'Link/Add Child',
            onClick: () => handleOpenModal('child'),
          },
        ]}
        title="Children"
        description="List of children"
        showPagination={false} // Remove pagination
      />

      {/* Modal for adding family members */}
      {showModal.open && (
        <AddFamilyMemberModal
          memberType={showModal.type}
          clientId={client._id}
          onSave={handleSaveMember}
          onClose={handleCloseModal}
          onCancel={handleCloseModal}
        />
      )}
    </div>
  );
};

export default FamilyDetails;
