import React, { useEffect, useRef } from "react";
import payrollDates from "./payrollDates";

const findClosestPeriodIndex = () => {
  const currentDate = new Date();
  let closestIndex = 0;
  let minDiff = Number.MAX_VALUE;

  payrollDates.forEach((period, index) => {
    const [startDateString, endDateString] = period.includeDates.split("-");
    const startDate = new Date(`${startDateString} 2024`);
    const endDate = new Date(`${endDateString} 2024`);

    if (currentDate >= startDate && currentDate <= endDate) {
      closestIndex = index;
      minDiff = 0;
    } else {
      const diff = Math.min(
        Math.abs(currentDate - startDate),
        Math.abs(currentDate - endDate)
      );
      if (diff < minDiff) {
        closestIndex = index;
        minDiff = diff;
      }
    }
  });

  return closestIndex;
};

const closestPeriodIndex = findClosestPeriodIndex();

export default function PayrollScheduleTable() {
  const rowRefs = useRef([]);

  useEffect(() => {
    if (rowRefs.current[closestPeriodIndex]) {
      rowRefs.current[closestPeriodIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Payroll Schedule
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all payroll periods including their end date, included
            appointment dates, submission date, and payment date.
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <div className="max-h-96 overflow-y-auto">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50 sticky top-0 z-10 border-b border-gray-500">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 sticky top-0 bg-gray-50 border-b border-gray-300">
                        Pay Period
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 bg-gray-50 border-b border-gray-300">
                        Pay Period Ending Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 bg-gray-50 border-b border-gray-300">
                        Include Dates
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 bg-gray-50 border-b border-gray-300">
                        Submission Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sticky top-0 bg-gray-50 border-b border-gray-300">
                        Payment Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {payrollDates.map((period, index) => {
                      const isClosest = index === closestPeriodIndex;
                      const isBeforeClosest = index < closestPeriodIndex;
                      return (
                        <tr
                          key={period.payPeriodNumber}
                          ref={(el) => (rowRefs.current[index] = el)}
                          className={`${
                            isClosest
                              ? "bg-indigo-300"
                              : isBeforeClosest
                              ? "bg-gray-100 opacity-50"
                              : "bg-white"
                          }`}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {period.payPeriodNumber}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {period.payPeriodEndingDate}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {period.includeDates}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {period.submissionDate}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {period.paymentDate}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
