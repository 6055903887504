// src/store/slices/userSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { fetchUsers, createUser, fetchUser } from '../thunks/userThunk'; // Import thunks

const userSlice = createSlice({
  name: 'users',
  initialState: {
    users: [], // List of users
    currentUser: null,
    loading: false,
    error: null,
  },
  reducers: {
    // Add other reducers for user management if needed
  },
  extraReducers: (builder) => {
    builder
      // Handle fetching users
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload; // Update the users list with fetched data
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Error message from the API
      })
      // Handle creating a user
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally update the users list with the newly created user
        state.users.push(action.payload);
        console.log('User created:', action.payload);
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Error message from the API
      })
      // Handle fetching a single user
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
