"use client";

import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Menu } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";

export default function SevenDayCalendar({ appointments = [] }) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showWorkingHours, setShowWorkingHours] = useState(true);
  const [hideWeekends, setHideWeekends] = useState(false);
  const [hoverInfo, setHoverInfo] = useState(null);
  const [weekOffset, setWeekOffset] = useState(0);

  const calendarRef = useRef(null);
  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);
  const grayLineRef = useRef(null);

  useEffect(() => {
    if (container.current && containerNav.current && containerOffset.current) {
      const currentMinute = currentDate.getHours() * 60;

      const scrollHeight = container.current.scrollHeight;
      const navHeight = containerNav.current.offsetHeight;
      const offsetHeight = containerOffset.current.offsetHeight;

      if (scrollHeight && navHeight && offsetHeight) {
        container.current.scrollTop =
          ((scrollHeight - navHeight - offsetHeight) * currentMinute) / 1440;
      }
    }
  }, [currentDate]);

  const getCurrentWeek = () => {
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(
      currentDate.getDate() - currentDate.getDay() + 7 * weekOffset
    );

    return Array.from({ length: 7 }).map((_, i) => {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + i);
      return date;
    });
  };

  const handlePreviousWeek = () => {
    setWeekOffset(weekOffset - 1);
  };

  const handleNextWeek = () => {
    setWeekOffset(weekOffset + 1);
  };

  const handleToday = () => {
    setCurrentDate(new Date());
    setWeekOffset(0);
  };

  const handleMouseMove = (e) => {
    if (!calendarRef.current) return;

    const rect = calendarRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const dayIndex = Math.floor((x / rect.width) * (hideWeekends ? 5 : 7));
    const minutesSinceMidnight =
      (y / rect.height) * (showWorkingHours ? 600 : 1440) +
      (showWorkingHours ? 480 : 0);

    const date = new Date(currentDate);
    date.setDate(date.getDate() - date.getDay() + dayIndex + 7 * weekOffset);
    date.setHours(0, 0, 0, 0);
    date.setMinutes(minutesSinceMidnight);

    setHoverInfo({
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
      left: e.clientX,
      top: e.clientY,
    });
  };

  const getWeekRange = (date, weekOffset) => {
    const start = new Date(date);
    start.setDate(start.getDate() - start.getDay() + 7 * weekOffset);
    const end = new Date(start);
    end.setDate(end.getDate() + 6);
    return `${start.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    })} - ${end.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    })}`;
  };

  const renderCalendarHeader = () => {
    return (
      <header className="flex flex-none items-center justify-between border-b border-gray-200 px-6 py-4">
        <h1 className="text-base font-semibold leading-6 text-gray-900">
          {getWeekRange(currentDate, weekOffset)}
        </h1>

        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <button
              type="button"
              onClick={handlePreviousWeek}
              className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50">
              <span className="sr-only">Previous week</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={handleToday}
              className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block">
              Today
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              onClick={handleNextWeek}
              className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50">
              <span className="sr-only">Next week</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden md:ml-4 md:flex md:items-center">
            <div className="ml-6 h-6 w-px bg-gray-300" />
            <button
              type="button"
              className="ml-6 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Add event
            </button>
          </div>
          <div className="ml-6 flex items-center">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={showWorkingHours}
                onChange={() => setShowWorkingHours(!showWorkingHours)}
                className="mr-2"
              />
              Show working hours
            </label>
            <label className="ml-4 flex items-center">
              <input
                type="checkbox"
                checked={hideWeekends}
                onChange={() => setHideWeekends(!hideWeekends)}
                className="mr-2"
              />
              Hide weekends
            </label>
          </div>
        </div>
      </header>
    );
  };

  const renderCalendarGrid = () => {
    return (
      <div
        ref={container}
        className="flex flex-auto flex-col overflow-auto bg-white"
        onMouseMove={handleMouseMove}>
        <div className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
          <div
            ref={containerNav}
            className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5">
            <div className="grid grid-cols-7 text-sm leading-6 text-gray-500">
              {getCurrentWeek().map((day, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center py-3 border-r border-gray-200">
                  <span>
                    {day.toLocaleDateString("en-US", { weekday: "short" })}{" "}
                    <span className="items-center justify-center font-semibold text-gray-900">
                      {day.getDate()}
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-auto">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              <div
                ref={calendarRef}
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-200 relative"
                style={{
                  gridTemplateRows: `repeat(${
                    showWorkingHours ? 10 * 12 : 24 * 12
                  }, minmax(0.5rem, 1fr))`,
                  gridTemplateColumns: `repeat(7, minmax(0, 1fr))`,
                }}>
                <div ref={containerOffset} className="row-end-1 h-7"></div>
                {Array.from({ length: showWorkingHours ? 10 : 24 }).map(
                  (_, i) => (
                    <React.Fragment key={i}>
                      <div className="border-b border-gray-200">
                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                          {showWorkingHours
                            ? `${(i + 8) % 12 || 12}${i + 8 < 12 ? "AM" : "PM"}`
                            : `${i % 12 || 12}${i < 12 ? "AM" : "PM"}`}
                        </div>
                      </div>
                      {Array.from({ length: 7 }).map((_, j) => (
                        <div
                          key={`${i}-${j}`}
                          className="border-r border-gray-200"
                        />
                      ))}
                    </React.Fragment>
                  )
                )}
                {renderAppointments()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAppointments = () => {
    if (!appointments || appointments.length === 0) return null;

    return appointments.map((appointment) => {
      const start = new Date(appointment.start);
      const end = new Date(appointment.end);
      const startOffset =
        (start.getHours() - (showWorkingHours ? 8 : 0)) * 12 +
        Math.floor(start.getMinutes() / 5);
      const endOffset =
        (end.getHours() - (showWorkingHours ? 8 : 0)) * 12 +
        Math.floor(end.getMinutes() / 5);
      const duration = endOffset - startOffset;

      const dayIndex = start.getDay();
      if (hideWeekends && (dayIndex === 0 || dayIndex === 6)) return null;

      return (
        <div
          key={appointment._id}
          className="absolute bg-blue-200 border border-blue-300 rounded-md overflow-hidden"
          style={{
            gridRow: `${startOffset + 2} / span ${duration}`,
            gridColumn: `${dayIndex + 1}`,
            left: "2px",
            right: "2px",
          }}>
          <div className="p-1 text-xs">
            <div className="font-bold truncate">{appointment.title}</div>
            <div className="truncate">{`${start.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })} - ${end.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}`}</div>
            <div className="truncate">{appointment.status.join(", ")}</div>
          </div>
        </div>
      );
    });
  };

  // const renderCalendarGrid = () => {
  //   return (
  //     <div
  //       ref={container}
  //       className="flex flex-auto flex-col overflow-auto bg-white"
  //       onMouseMove={handleMouseMove}>
  //       <div
  //         style={{ width: "165%" }}
  //         className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
  //         <div
  //           ref={containerNav}
  //           className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
  //           <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
  //             {getCurrentWeek().map((day, index) => (
  //               <button
  //                 key={index}
  //                 type="button"
  //                 className="flex flex-col items-center pb-3 pt-2">
  //                 {day
  //                   .toLocaleDateString("en-US", { weekday: "short" })
  //                   .charAt(0)}
  //                 <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">
  //                   {day.getDate()}
  //                 </span>
  //               </button>
  //             ))}
  //           </div>
  //           <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
  //             <div className="col-end-1 w-14" />
  //             {getCurrentWeek().map((day, index) => (
  //               <div
  //                 key={index}
  //                 className="flex items-center justify-center py-3 border-r border-gray-200">
  //                 <span>
  //                   {day.toLocaleDateString("en-US", { weekday: "short" })}{" "}
  //                   <span className="items-center justify-center font-semibold text-gray-900">
  //                     {day.getDate()}
  //                   </span>
  //                 </span>
  //               </div>
  //             ))}
  //           </div>
  //         </div>
  //         <div className="flex flex-auto">
  //           <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
  //           <div className="grid flex-auto grid-cols-1 grid-rows-1">
  //             <div
  //               ref={calendarRef}
  //               className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-200 relative"
  //               style={{
  //                 gridTemplateRows: `repeat(${
  //                   showWorkingHours ? 10 * 12 : 24 * 12
  //                 }, minmax(0.5rem, 1fr))`,
  //                 gridTemplateColumns: `repeat(${
  //                   hideWeekends ? 5 : 7
  //                 }, minmax(0, 1fr))`,
  //               }}>
  //               <div ref={containerOffset} className="row-end-1 h-7"></div>
  //               {Array.from({ length: showWorkingHours ? 10 : 24 }).map(
  //                 (_, i) => (
  //                   <div key={i} className="border-b border-gray-200">
  //                     <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
  //                       {showWorkingHours
  //                         ? `${(i + 8) % 12 || 12}${i + 8 < 12 ? "AM" : "PM"}`
  //                         : `${i % 12 || 12}${i < 12 ? "AM" : "PM"}`}
  //                     </div>
  //                   </div>
  //                 )
  //               )}
  //               {renderAppointments()}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderAppointments = () => {
  //   if (!appointments || appointments.length === 0) return null;

  //   return appointments.map((appointment) => {
  //     const start = new Date(appointment.start);
  //     const end = new Date(appointment.end);
  //     const startOffset =
  //       (start.getHours() - (showWorkingHours ? 8 : 0)) * 12 +
  //       Math.floor(start.getMinutes() / 5);
  //     const endOffset =
  //       (end.getHours() - (showWorkingHours ? 8 : 0)) * 12 +
  //       Math.floor(end.getMinutes() / 5);
  //     const duration = endOffset - startOffset;

  //     const dayIndex = start.getDay();
  //     if (hideWeekends && (dayIndex === 0 || dayIndex === 6)) return null;

  //     return (
  //       <li
  //         key={appointment._id}
  //         className="absolute bg-blue-200 border border-blue-300 rounded-md overflow-hidden"
  //         style={{
  //           gridRow: `${startOffset + 2} / span ${duration}`,
  //           gridColumn: hideWeekends ? dayIndex + 1 : dayIndex + 1,
  //           left: "0.5%",
  //           right: "0.5%",
  //         }}>
  //         <div className="p-1 text-xs">
  //           <div className="font-bold truncate">{appointment.title}</div>
  //           <div className="truncate">{`${start.toLocaleTimeString([], {
  //             hour: "2-digit",
  //             minute: "2-digit",
  //           })} - ${end.toLocaleTimeString([], {
  //             hour: "2-digit",
  //             minute: "2-digit",
  //           })}`}</div>
  //           <div className="truncate">{appointment.status.join(", ")}</div>
  //         </div>
  //       </li>
  //     );
  //   });
  // };

  return (
    <div className="flex h-full flex-col">
      {renderCalendarHeader()}

      <div
        ref={container}
        className="flex flex-auto flex-col overflow-auto bg-white">
        {renderCalendarGrid()}
      </div>

      {hoverInfo && (
        <div
          className="absolute bg-white border border-gray-200 rounded-md p-2 shadow-md"
          style={{ left: hoverInfo.left + 10, top: hoverInfo.top + 10 }}>
          <p>{hoverInfo.date}</p>
          <p>{hoverInfo.time}</p>
        </div>
      )}
    </div>
  );
}
