const calculateCounts = (appointments = []) => {
  // Ensure appointments is an array
  if (!Array.isArray(appointments)) {
    console.error(
      "Expected appointments to be an array, but got:",
      typeof appointments
    );
    return {
      lastMonth: 0,
      lastWeek: 0,
      yesterday: 0,
      today: 0,
      thisWeek: 0,
      nextWeek: 0,
    };
  }

  const now = new Date();
  const counts = {
    lastMonth: 0,
    lastWeek: 0,
    yesterday: 0,
    today: 0,
    thisWeek: 0,
    nextWeek: 0,
  };

  const startOfToday = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
  );
  const startOfYesterday = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 1)
  );
  const startOfTomorrow = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1)
  );
  const startOfWeek = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() - now.getUTCDay()
    )
  );
  const startOfLastWeek = new Date(startOfWeek);
  startOfLastWeek.setUTCDate(startOfLastWeek.getUTCDate() - 7);
  const startOfNextWeek = new Date(startOfWeek);
  startOfNextWeek.setUTCDate(startOfNextWeek.getUTCDate() + 7);
  const startOfThisWeek = new Date(startOfWeek);
  const startOfMonth = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1)
  );
  const startOfLastMonth = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth() - 1, 1)
  );
  const endOfLastMonth = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 0)
  );

  appointments.forEach((appointment) => {
    const start = new Date(appointment.start);

    if (start >= startOfLastMonth && start <= endOfLastMonth) {
      counts.lastMonth++;
    }
    if (start >= startOfLastWeek && start < startOfWeek) {
      counts.lastWeek++;
    }
    if (start >= startOfYesterday && start < startOfToday) {
      counts.yesterday++;
    }
    if (start >= startOfToday && start < startOfTomorrow) {
      counts.today++;
    }
    if (
      start >= startOfThisWeek &&
      start < new Date(startOfNextWeek.getTime())
    ) {
      counts.thisWeek++;
    }
    if (
      start >= startOfNextWeek &&
      start < new Date(startOfNextWeek.getTime() + 7 * 24 * 60 * 60 * 1000)
    ) {
      counts.nextWeek++;
    }
  });

  return counts;
};

export default calculateCounts;
