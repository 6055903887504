import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { generateConfig } from "../../utils/api";

export default function QuickBooksSuccess() {
    const token = useSelector((state) => (state.auth.token));
    const { baseURL, config } = generateConfig({ token: token });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isConnected, setIsConnected] = useState(null);

    useEffect(() => {
        const checkQbConnection = async () => {
            try {
                const response = await axios.get(
                    `${baseURL}/api/qbConnected`,
                    config
                );
                setIsConnected(response.data.connected);
            } catch (error) {
                console.error("Error checking QuickBooks connection:", error);
                setIsConnected(false);
            }
        };

        checkQbConnection();
    }, [dispatch]);

    useEffect(() => {
        if (isConnected) {
            navigate("/dashboard");
        }
    }, [isConnected, navigate]);

    return (
        <div className="mt-4 text-center">
            {isConnected === null ? (
                <p className="text-gray-500">
                    Checking QuickBooks connection...
                </p>
            ) : isConnected ? (
                <div className="flex items-center justify-center space-x-2 text-green-500">
                    <CheckCircleIcon className="h-6 w-6" />
                    <p className="text-lg font-semibold">
                        Successfully connected to QuickBooks!
                    </p>
                </div>
            ) : (
                <div className="flex items-center justify-center space-x-2 text-red-500">
                    <ExclamationCircleIcon className="h-6 w-6" />
                    <p className="text-lg font-semibold">
                        Failed to connect to QuickBooks.
                    </p>
                </div>
            )}
        </div>
    );
}
