import React from 'react';

const metrics = [
  { name: 'Total Revenue', lastWeek: '$13,617.60', prevWeek: '$11,323.80', mtd: '-', lastMonth: '$55,628.05', target: '$85,000.00', percentCurr: '0%', percentLast: '65%' },
  { name: 'Total Phone Calls', lastWeek: '40', prevWeek: '34', mtd: '0', lastMonth: '161', target: '100', percentCurr: '0%', percentLast: '161%' },
  { name: 'Total Appointments', lastWeek: '101', prevWeek: '88', mtd: '0', lastMonth: '448', target: '650', percentCurr: '0%', percentLast: '69%' },
  { name: 'Total Intake', lastWeek: '0', prevWeek: '0', mtd: '0', lastMonth: '9', target: '25', percentCurr: '0%', percentLast: '36%' },
  { name: 'Total Consults', lastWeek: '0', prevWeek: '2', mtd: '0', lastMonth: '3', target: '-', percentCurr: '-', percentLast: '-' },
  { name: 'Total Assessments', lastWeek: '1', prevWeek: '1', mtd: '0', lastMonth: '3', target: '15', percentCurr: '0%', percentLast: '7%' },
  { name: 'Total Therapy Hours', lastWeek: '69.5', prevWeek: '55.25', mtd: '0', lastMonth: '291.25', target: '415', percentCurr: '0%', percentLast: '70%' },
  { name: 'Active Clients (60 days)', lastWeek: '214', prevWeek: '207', mtd: '-', lastMonth: '-', target: '-', percentCurr: '-', percentLast: '-' },
  { name: 'Active Clients (30 days)', lastWeek: '171', prevWeek: '161', mtd: '-', lastMonth: '-', target: '-', percentCurr: '-', percentLast: '-' },
  { name: 'Total New Clients', lastWeek: '1', prevWeek: '2', mtd: '0', lastMonth: '12', target: '20', percentCurr: '0%', percentLast: '60%' },
  { name: 'Total Drop Offs (2 months)', lastWeek: '7', prevWeek: '10', mtd: '0', lastMonth: '39', target: '-', percentCurr: '-', percentLast: '-' },
  { name: 'Net Clients', lastWeek: '0', prevWeek: '-8', mtd: '0', lastMonth: '0', target: '-', percentCurr: '-', percentLast: '-' },
];

const WaagChart = () => {
  return (
    <div className="overflow-x-auto bg-white shadow-md rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Metric</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Week</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prev Week</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">MTD</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Month</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Target</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">% curr</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">% last</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {metrics.map((metric) => (
            <tr key={metric.name}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{metric.name}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.lastWeek}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.prevWeek}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.mtd}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.lastMonth}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.target}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.percentCurr}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.percentLast}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WaagChart;
