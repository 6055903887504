import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./auth/authContext";
import App from "./App";
import Login from "./auth/login";
import Register from "./auth/register";
import PasswordReset from "./auth/passwordReset";

export default function AppRouter() {
  const { isAuthenticated, user } = useAuth();

  return (
    <Routes>
      {/* Protected Routes */}
      <Route
        path="/dashboard"
        element={
          isAuthenticated ? (
            user.passwordResetRequired ? (
              <Navigate to="/password-reset" replace />
            ) : (
              <App />
            )
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      {/* ... other protected routes ... */}

      {/* Password Reset Required Route */}
      <Route
        path="/password-reset"
        element={
          isAuthenticated && user.passwordResetRequired ? (
            <PasswordReset />
          ) : (
            <Navigate to="/dashboard" replace />
          )
        }
      />

      {/* Public Routes */}
      <Route
        path="/login"
        element={
          isAuthenticated ? (
            user.passwordResetRequired ? (
              <Navigate to="/password-reset" replace />
            ) : (
              <Navigate to="/dashboard" replace />
            )
          ) : (
            <Login />
          )
        }
      />
      <Route path="/register" element={<Register />} />

      {/* Redirect to Dashboard if authenticated */}
      <Route
        path="/"
        element={
          isAuthenticated ? (
            user.passwordResetRequired ? (
              <Navigate to="/password-reset" replace />
            ) : (
              <Navigate to="/dashboard" replace />
            )
          ) : (
            <Login />
          )
        }
      />
    </Routes>
  );
}
