import React, { useState, useEffect } from "react";
import Table from "../../applicationUi/components/Table";
import QuickAddClinicianModal from "../../modals/QuickAddClinicianModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchClinicians } from "../../store/thunks/cliniciansThunk";

const CliniciansPage = () => {
  const dispatch = useDispatch();
  const clinicians = useSelector((state) => state.clinicians.clinicians || []); // Default to an empty array if undefined

  useEffect(() => {
    dispatch(fetchClinicians());
  }, [dispatch]);

  const handleRefresh = () => {
    dispatch(fetchClinicians());
  };

  const [isQuickAddClinicianModalOpen, setIsQuickAddClinicianModalOpen] =
    useState(false);

  const handleCloseQuickAddClinicianModal = () => {
    setIsQuickAddClinicianModalOpen(false); // Close Add Clinician modal
  };

  const handleAddClinician = () => {
    setIsQuickAddClinicianModalOpen(true); // Open Add Clinician modal
  };

  const handleClinicianClick = (clinicianId) => {
    console.log("Clinician Click" + clinicianId);
  };

  return (
    <div>
      <Table
        columns={[
        //   { header: "Title", accessor: "title" },
          { header: "First Name", accessor: "firstName" },
          { header: "Last Name", accessor: "lastName" },
          { header: "Email", accessor: "email" },
          { header: "Phone", accessor: "phone" },
          { header: "Clinic Name", accessor: "defaultLocation.name" },
        //   { header: "Job Title", accessor: "jobTitle" },
        //   { header: "Roles", accessor: "roles" },
          { header: "Qualifications", accessor: "qualifications" },
        ]}
        data={clinicians}
        onRowClick={handleClinicianClick}
        actions={[
          { label: "Add Clinician", onClick: handleAddClinician },
        //   { label: "Refresh Clinicians", onClick: handleRefresh },
        ]}
        title="Clinicians"
        description="A list of all clinicians connected to your account."
      />

      {isQuickAddClinicianModalOpen && (
        <QuickAddClinicianModal onClose={handleCloseQuickAddClinicianModal} />
      )}
    </div>
  );
};

export default CliniciansPage;
