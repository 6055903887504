import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClients, deleteClient } from "../../store/thunks/clientsThunk";
import { fetchInvoicesByClientId } from "../../store/thunks/invoiceThunk";
import { fetchSoapNotesByClientId } from "../../store/thunks/appointmentsThunk";
import EditClientModal from "./EditClientModal";
import ConfirmDeleteDialog from "../../dialogs/ConfirmDeleteDialog";
import ClientOverview from "./ClientOverview"; // Import the new component
import Table from "../../applicationUi/components/Table";
import SearchDropdown from "../../applicationUi/components/SearchDropdown";
import { format, differenceInYears } from "date-fns";

export default function ClientsPage() {
  const dispatch = useDispatch();
  const {
    clients = [],
    pagination = { currentPage: 1, pageSize: 10 },
    status: clientStatus,
    error: clientError,
  } = useSelector((state) => state.clients);

  const [selectedClient, setSelectedClient] = useState(null); // Track selected client
  const [isEditClientModalOpen, setIsEditClientModalOpen] = useState(false);
  const [isDeleteClientModalOpen, setIsDeleteClientModalOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  useEffect(() => {
    dispatch(
      fetchClients({
        page: pagination.currentPage,
        limit: pagination.pageSize,
      })
    )
      .unwrap()
      .catch((error) => {
        console.error("Failed to fetch clients:", error);
      });
  }, [dispatch, pagination.currentPage, pagination.pageSize]);

  const fetchInvoicesDataByClientId = async (clientId) => {
    try {
      const response = await dispatch(
        fetchInvoicesByClientId(clientId)
      ).unwrap();
      return response;
    } catch (error) {
      console.error("Failed to fetch invoices by client ID:", error);
      return [];
    }
  };

  const fetchSoapNotesDataByClientId = async (clientId) => {
    try {
      const response = await dispatch(
        fetchSoapNotesByClientId(clientId)
      ).unwrap();
      return response;
    } catch (error) {
      console.error("Failed to fetch SOAP notes by client ID:", error);
      return [];
    }
  };

  const handleRowClick = (clientId) => {
    console.log(clientId);
    const client = clients.find((client) => client._id === clientId);
    setSelectedClient(client);
  };

  const handleSearchClick = (client) => {
    console.log(client);
    setSelectedClient(client);
  };

  const handleBackToClients = () => {
    setSelectedClient(null); // Reset selected client
  };

  const formattedClients =
    clients?.map((client) => {
      const dateOfBirth = client.dateOfBirth
        ? new Date(client.dateOfBirth)
        : null;
      const formattedDateOfBirth = dateOfBirth
        ? format(dateOfBirth, "MMMM d, yyyy")
        : "N/A";
      const age = dateOfBirth
        ? differenceInYears(new Date(), dateOfBirth)
        : "N/A";

      const formattedPhoneNumbers = client.phoneNumbers
        ? client.phoneNumbers.map((phone) => `${phone.number}`).join(", ")
        : "N/A";

      return {
        ...client,
        dateOfBirth: formattedDateOfBirth,
        age,
        formattedPhoneNumbers,
      };
    }) || [];

  return (
    <div>
      {/* Display Client Overview if a client is selected */}
      {selectedClient ? (
        <div className="p-6">
          <ClientOverview
            selectedClient={selectedClient}
            onClose={handleBackToClients} // Button to go back to clients list
            fetchInvoicesData={() =>
              fetchInvoicesDataByClientId(selectedClient._id)
            }
            fetchSoapNotesData={() =>
              fetchSoapNotesDataByClientId(selectedClient._id)
            }
          />
        </div>
      ) : (
        <>
          {/* Client search */}
          <div className="m-8">
            <div className="text-base font-semibold leading-6 text-gray-900">
              Search for clients by first name, last name, or email:
            </div>
            <SearchDropdown
              prefix=":client:"
              displayFields={["firstName", "lastName", "email", "phoneNumbers"]}
              onSelection={(client) => handleSearchClick(client)}
              placeholder={"Type to start searching..."}
            />
          </div>

          {/* Client table */}
          {clientStatus === "loading" && <p>Loading clients...</p>}
          {clientStatus === "failed" && (
            <p>
              Error loading clients:{" "}
              {typeof clientError === "object"
                ? JSON.stringify(clientError)
                : clientError}
            </p>
          )}
          {clientStatus === "succeeded" && clients.length === 0 && (
            <p>No clients available</p>
          )}
          {clientStatus === "succeeded" && clients.length > 0 && (
            <Table
              columns={[
                { header: "First Name", accessor: "firstName" },
                { header: "Last Name", accessor: "lastName" },
                {
                  header: "Date of Birth",
                  accessor: "dateOfBirth",
                },
                { header: "Age", accessor: "age" },
                { header: "Email", accessor: "email" },
                {
                  header: "Phone Numbers",
                  accessor: "formattedPhoneNumbers",
                },
              ]}
              data={formattedClients}
              onRowClick={handleRowClick}
              actions={[
                {
                  label: "Add Client",
                  onClick: () => setIsEditClientModalOpen(true),
                },
              ]}
              title="Clients"
              description="A list of all clients. Use pagination to view more."
              showPagination={pagination.totalPages > 1}
              currentPage={pagination.currentPage}
              totalPages={pagination.totalPages}
              onPageChange={(page) =>
                dispatch(
                  fetchClients({
                    page,
                    limit: pagination.pageSize,
                  })
                )
              }
            />
          )}
        </>
      )}

      {/* Edit Client Modal */}
      {isEditClientModalOpen && selectedClient && (
        <EditClientModal
          client={selectedClient}
          onClose={() => setIsEditClientModalOpen(false)}
        />
      )}

      {/* Confirm Delete Dialog */}
      {isDeleteClientModalOpen && (
        <ConfirmDeleteDialog
          open={isDeleteClientModalOpen}
          setOpen={setIsDeleteClientModalOpen}
          onDelete={() => dispatch(deleteClient(clientToDelete))}
        />
      )}
    </div>
  );
}
