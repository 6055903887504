import React, { useEffect, useState } from "react";
import SearchDropdown from "../applicationUi/components/SearchDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  createAppointment,
  fetchAppointmentsByClinicianId,
  checkForConflicts,
} from "../store/thunks/appointmentsThunk";
import { fetchClinicianById } from "../store/thunks/cliniciansThunk";

const QuickAddAppointmentModal = ({
  isOpen,
  onClose,
  initialDate,
  initialTime,
  initialClinicianFname,
  initialClinicianLname,
  selectedClinician: initialSelectedClinician,
  rescheduleData,
}) => {
  const dispatch = useDispatch();
  const [isNewClient, setIsNewClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedReferral, setSelectedReferral] = useState(null);
  const [isBlock, setIsBlock] = useState(false);
  const [blockDates, setBlockDates] = useState([]);
  const conflicts = useSelector((state) => state.appointments.conflicts) || [];
  const [modalContent, setModalContent] = useState({
    date: initialDate,
    time: initialTime,
    clinicianFname: initialClinicianFname,
    clinicianLname: initialClinicianLname,
  });
  const [selectedClinician, setSelectedClinician] = useState(null);
  const [passedRescheduleData, setPassedRescheduleData] =
    useState(rescheduleData);

  useEffect(() => {
    console.log("rescheduleData prop:", rescheduleData);
    if (rescheduleData) {
      console.log("Setting passedRescheduleData:", rescheduleData);
      setPassedRescheduleData(rescheduleData);

      if (rescheduleData.client) {
        console.log(
          rescheduleData.client.firstName,
          rescheduleData.client.lastName
        );
        console.log(rescheduleData.service.description);
      } else {
        console.log("Client data is not available in rescheduleData");
      }
    }
  }, [rescheduleData]); // useEffect(() => {

  //   if (rescheduleData) {
  //     console.log(
  //       "Setting passedRescheduleData with rescheduledata:",
  //       rescheduleData
  //     );
  //     setPassedRescheduleData(rescheduleData);

  //     if (rescheduleData.client) {
  //       console.log(
  //         rescheduleData.client.firstName,
  //         rescheduleData.client.lastName
  //       );
  //       console.log(rescheduleData.service.description);
  //     } else {
  //       console.log("Client data is not available in rescheduleData");
  //     }
  //   }
  // }, [rescheduleData]);

  useEffect(() => {
    if (initialDate && initialTime) {
      setModalContent({
        date: initialDate,
        time: initialTime,
        clinicianFname: initialClinicianFname,
        clinicianLname: initialClinicianLname,
      });

      if (initialSelectedClinician && initialSelectedClinician._id) {
        dispatch(fetchClinicianById(initialSelectedClinician._id)).then(
          (response) => {
            setSelectedClinician(response.payload);
          }
        );
      }
    }
  }, [
    initialDate,
    initialTime,
    initialClinicianFname,
    initialClinicianLname,
    initialSelectedClinician,
    dispatch,
  ]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (isBlock) {
      const dates = [];
      for (let i = 0; i < 10; i++) {
        const date = new Date(modalContent.date);
        date.setDate(date.getDate() + i * 7);
        date.setHours(initialTime.split(":")[0]);
        date.setMinutes(initialTime.split(":")[1]);
        dates.push(date);
      }
      setBlockDates(dates);

      const clinicianId = selectedClinician?._id;
      console.log("Clinician ID:", clinicianId);

      dispatch(checkForConflicts({ blockDates: dates, clinicianId }))
        .then((action) => {
          console.log("Conflicts:", action.payload);
        })
        .catch((error) => {
          console.error("Failed to fetch conflicts:", error);
        });

      console.log("Block dates:", dates);
    } else {
      setBlockDates([]);
    }
  }, [isBlock, modalContent.date, initialTime, dispatch, selectedClinician]);

  const handleServiceSelection = (service) => {
    setSelectedService(service);
  };

  const handleReferralSelection = (referral) => {
    setSelectedReferral(referral);
    console.log(referral.source);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModalContent((prevContent) => ({
      ...prevContent,
      [name]: value,
    }));
  };

  const handleSave = (event) => {
    event.preventDefault();

    const { date, time } = modalContent;

    if (!time) {
      console.error("Time is not defined");
      return;
    }

    if (
      !selectedClient ||
      !selectedService ||
      !selectedClinician ||
      !selectedClinician.defaultLocation
    ) {
      alert("All fields must be nonempty");
      return;
    }

    const [hours, minutes] = time.split(":").map(Number);
    const start = new Date(date);
    start.setHours(hours, minutes);

    const end = new Date(start);
    end.setMinutes(start.getMinutes() + 30);

    const appointmentData = {
      clinic: selectedClinician.defaultLocation,
      clinician: selectedClinician._id,
      client: selectedClient._id,
      title: selectedService.description,
      start: start.toISOString(),
      end: end.toISOString(),
      service: selectedService._id,
      status: ["Booked"],
      history: [
        {
          status: "Booked",
          comments: "",
          timestamp: new Date().toISOString(),
          user: selectedClinician._id,
        },
      ],
      isBlock,
      blockInfo: isBlock
        ? {
            totalSessions: 10,
            sessionsCompleted: 0,
          }
        : undefined,
    };

    dispatch(createAppointment(appointmentData))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          dispatch(fetchAppointmentsByClinicianId(selectedClinician._id));
          onClose();
        }
      })
      .catch((error) => {
        console.error("Error creating appointment:", error);
      });
  };

  const clientFields = [
    {
      label: "First name",
      name: "client-first-name",
      type: "text",
      autoComplete: "given-name",
      colSpan: 2,
    },
    {
      label: "Last name",
      name: "client-last-name",
      type: "text",
      autoComplete: "family-name",
      colSpan: 2,
    },
    {
      label: "Date of Birth",
      name: "client-date-of-birth",
      type: "date",
      colSpan: 2,
    },
    {
      label: "Phone Number",
      name: "client-phone-number",
      type: "tel",
      colSpan: 2,
    },
    {
      label: "Email",
      name: "client-email",
      type: "email",
      colSpan: 2,
    },
    {
      label: "Referral Source",
      name: "client-referral-source",
      type: "text",
      colSpan: 2,
    },
  ];

  const renderInputField = (field) => {
    const { label, name, type, autoComplete, options, colSpan } = field;
    return (
      <div className={`sm:col-span-${colSpan}`} key={name}>
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
        <div className="mt-2">
          {type === "textarea" ? (
            <textarea
              name={name}
              id={name}
              rows="3"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
          ) : type === "select" ? (
            <select
              id={name}
              name={name}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              {options.map((option) => (
                <option key={option}>{option}</option>
              ))}
            </select>
          ) : (
            <input
              type={type}
              name={name}
              id={name}
              autoComplete={autoComplete}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={modalContent[name] || ""}
              onChange={handleInputChange}
            />
          )}
        </div>
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
      <div className="fixed inset-0 bg-gray-900 opacity-50" onClick={onClose} />
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4 relative z-10 overflow-y-auto max-h-[90vh]">
        <form onSubmit={handleSave}>
          {/* Header */}
          <div className="border-b border-gray-900/10 pb-6 mb-6">
            <h2 className="text-lg font-semibold leading-7 text-gray-900">
              Quick Add Appointment
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Use this form to quickly create an appointment. Date and time are
              selected by right clicking on the schedule.
            </p>

            {passedRescheduleData && (
              <p className="mt-1 text-sm leading-6 text-red-600">
                Reschedule data: {passedRescheduleData.client?.firstName}{" "}
                {passedRescheduleData.client?.lastName}
              </p>
            )}

            {rescheduleData && (
              <p className="mt-1 text-sm leading-6 text-red-600">
                Reschedule data: {rescheduleData.client?.firstName}{" "}
                {rescheduleData.client?.lastName}
              </p>
            )}
          </div>

          {/* Appointment Information */}
          <div className="mt-8 border-b border-gray-900/10 pb-8">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Appointment Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Date, Time, and Clinician are prepopulated by your right click.
              Search and find the service for the client.
            </p>

            <div className="flex justify-between mt-4 mb-4 space-x-4">
              <div className="flex-1">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Date
                </label>
                <div className="mt-2 text-gray-700">
                  {modalContent.date && modalContent.date.toDateString()}
                </div>
              </div>

              <div className="flex-1">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Time
                </label>
                <div className="mt-2 text-gray-700">
                  {new Date(
                    `1970-01-01T${modalContent.time}:00`
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </div>
              </div>

              <div className="flex-1">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Clinician
                </label>
                <div className="mt-2 text-gray-700">
                  {modalContent.clinicianFname} {modalContent.clinicianLname}
                </div>
              </div>
            </div>

            <div className="flex flex-row space-x-4">
              <div className="flex-1">
                <SearchDropdown
                  prefix=":service:"
                  displayFields={["serviceCode", "description", "price"]}
                  onSelection={handleServiceSelection}
                  placeholder={"Search for a service by name or service code"}
                />
              </div>
            </div>

            <div className="flex items-center mt-4">
              <input
                id="isBlock"
                name="isBlock"
                type="checkbox"
                checked={isBlock}
                onChange={() => setIsBlock(!isBlock)}
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
              <label
                htmlFor="isBlock"
                className="ml-2 block text-sm text-gray-900">
                Block Appointment (Book 10 consecutive weeks)
              </label>
            </div>

            {isBlock && (
              <dl className="mt-5 grid grid-cols-1 overflow-hidden rounded-lg md:grid-cols-5">
                {blockDates.map((date, index) => (
                  <div key={index} className="px-2 py-4">
                    <dt className="text-sm font-semibold text-gray-900">
                      Block #{index + 1}
                    </dt>
                    <dd className="flex items-baseline justify-between">
                      <div className="text-sm font-normal text-gray-900">
                        {new Date(date).toDateString()}
                        <div className="text-sm font-normal text-gray-900">
                          at{" "}
                          {new Date(date).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </div>
                        {conflicts.length > 0 && (
                          <div>
                            {conflicts.map((conflict, conflictIndex) => {
                              if (index === conflictIndex) {
                                return (
                                  <span
                                    key={conflictIndex}
                                    className={`text-sm ${
                                      conflict.status === "No conflict"
                                        ? "text-green-600"
                                        : "text-red-600"
                                    }`}>
                                    {conflict.status}
                                  </span>
                                );
                              }
                              return null;
                            })}
                          </div>
                        )}
                      </div>
                    </dd>
                  </div>
                ))}
              </dl>
            )}
          </div>

          {/* Client Information */}
          <div className="mt-8 border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Client Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Search for an existing client or create a new one.
            </p>

            {!isNewClient ? (
              <div>
                <SearchDropdown
                  prefix=":client:"
                  displayFields={[
                    "firstName",
                    "lastName",
                    "email",
                    "phoneNumbers",
                  ]}
                  onSelection={(client) => setSelectedClient(client)}
                  placeholder={"Search for an existing client"}
                />

                <button
                  type="button"
                  onClick={() => setIsNewClient(true)}
                  className="mt-4 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Add a New Client
                </button>
              </div>
            ) : (
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {clientFields.slice(0, -1).map(renderInputField)}

                <div className={`sm:col-span-2`}>
                  <label
                    htmlFor="client-referral-source"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Referral Source
                  </label>
                  <div className="mt-2">
                    <SearchDropdown
                      prefix=":referral:"
                      displayFields={["source"]}
                      onSelection={handleReferralSelection}
                      placeholder={"Search for referral source"}
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <button
                    type="button"
                    onClick={() => setIsNewClient(false)}
                    className="mt-4 block w-full rounded-md bg-gray-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                    Search Existing Client
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Save and Cancel Buttons */}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={onClose}
              className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Create Appointment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuickAddAppointmentModal;
