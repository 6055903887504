// src/store/thunks/todoThunks.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

// Fetch all todos
export const fetchTodos = createAsyncThunk(
    "todos/fetchTodos",
    async ({ userRole, clinicianId }, { getState, rejectWithValue }) => {
        console.log(userRole);
        const state = getState();
        const token = state.auth.token;
        const { baseURL, config } = generateConfig({ token });

        // Construct the URL with role and optionally clinicianId
        let url = `${baseURL}/api/todos?role=${userRole}`;
        if (userRole === "clinician" && clinicianId) {
            url += `&clinician=${clinicianId}`;
        }

        try {
            const response = await axios.get(url, config);
            return response.data; // Data should be filtered based on role and clinician ID
        } catch (error) {
            return handleApiError(error, rejectWithValue);
        }
    }
);
