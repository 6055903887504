import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addFamilyRelationship } from "../../store/thunks/clientsThunk"; // Import the thunk
import SearchDropdown from "../../applicationUi/components/SearchDropdown";

export default function AddFamilyMemberModal({
  memberType,
  clientId, // Pass the ID of the client we are adding a family member to
  onSave,
  onClose,
  onCancel,
}) {
  const [selectedClient, setSelectedClient] = useState(null); // Store the selected client
  const dispatch = useDispatch(); // Initialize dispatch from Redux

  useEffect(() => {
    // Close the modal on Escape key press
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onCancel]);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedClient) {
      alert("Please select a family member.");
      return;
    }

    // Determine whether we are adding a parent or a child
    const relationshipData =
      memberType === "parent"
        ? { parentId: selectedClient._id, childId: clientId }
        : { parentId: clientId, childId: selectedClient._id };

    try {
      // Dispatch the action to update the relationship
      await dispatch(addFamilyRelationship(relationshipData));
      // Trigger onSave callback
      onSave({ selectedClient, relation: memberType });
    } catch (error) {
      console.error("Error updating family relationship:", error);
    }
  };

  // Handle selection from SearchDropdown
  const handleClientSelection = (client) => {
    setSelectedClient(client); // Update the state with selected client data
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
      <div
        className="fixed inset-0 bg-gray-900 opacity-50"
        onClick={onCancel}
      />
      <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4 relative z-10">
        <form onSubmit={handleSubmit}>
          {/* Header */}
          <div className="border-b border-gray-900/10 pb-4 mb-4">
            <h2 className="text-lg font-semibold leading-7 text-gray-900">
              Add {memberType === "parent" ? "Parent" : "Child"}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Please search for the {memberType}.
            </p>
          </div>

          {/* SearchDropdown for selecting family member */}
          <div className="mb-6">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Search for {memberType}
            </label>
            <div className="mt-2">
              <SearchDropdown
                prefix=":client:"
                displayFields={["firstName", "lastName", "email"]}
                onSelection={handleClientSelection} // Update with selected client
                placeholder={`Search for a ${memberType}`}
              />
            </div>
          </div>

          {/* Action buttons */}
          <div className="mt-6 flex items-center justify-end gap-x-4">
            <button
              type="button"
              onClick={onCancel}
              className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
