import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoices } from "../../store/thunks/invoiceThunk";
import InvoiceTable from "./InvoiceTable";
import SearchDropdown from "../../applicationUi/components/SearchDropdown";
import InvoiceGenerator from "../../applicationUi/components/InvoiceGenerator";
import InvoicePaymentModal from "../../modals/InvoicePaymentModal";

const InvoicesPage = () => {
  const dispatch = useDispatch();
  const invoices = useSelector((state) => state.invoices.invoices);
  const isLoading = useSelector((state) => state.invoices.loading);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(fetchInvoices());
  }, [dispatch]);

  const handleRowClick = (invoice) => {
    setSelectedInvoice(invoice);
    setShowModal(true); // Open the modal when a row is clicked
  };

  const handleModalClose = () => {
    setShowModal(false); // Close the modal
    setSelectedInvoice(null); // Clear selected invoice
  };

  return (
    <div>
      {isLoading ? (
        <p>Loading invoices...</p>
      ) : (
        <div>
          <div className="py-4">
            <SearchDropdown
              prefix=":invoice:"
              displayFields={[
                "invoiceId",
                "client.fullName",
                "clinician.fullName",
                "amount",
                "status",
              ]}
              onSelection={(invoice) => {
                setSelectedInvoice(invoice);
                setShowModal(true); // Open the modal when a search result is clicked
              }}
              placeholder={"Search for an invoice using Invoice ID"}
            />
          </div>

          <InvoiceTable
            columns={[
              {
                header: "Invoice ID",
                accessor: "invoiceId",
              },
              {
                header: "Client Name",
                accessor: (row) =>
                  row.client
                    ? `${row.client.firstName || "Unknown"} ${
                        row.client.lastName || ""
                      }`
                    : "Unknown", // Handle undefined client
              },
              {
                header: "Clinician",
                accessor: (row) =>
                  row.clinician
                    ? `${row.clinician.firstName || "Unknown"} ${
                        row.clinician.lastName || ""
                      }`
                    : "Unknown", // Handle undefined clinician
              },
              {
                header: "Amount",
                accessor: (row) => `$${row.amount?.toFixed(2) || "0.00"}`, // Handle undefined amount
              },
              {
                header: "Split Invoice?",
                accessor: (row) =>
                  row.splitBilling && row.splitBilling.isSplit ? "Yes" : "No", // Check for splitBilling and isSplit
              },
              {
                header: "Status",
                accessor: "status",
              },
            ]}
            data={invoices}
            onRowClick={handleRowClick} // Pass the full invoice object on row click
            renderInvoiceGenerator={(invoice) => (
              <InvoiceGenerator data={invoice} />
            )}
            title="Invoices"
            description="List of all invoices"
            showPagination={false} // Set this to true if you want pagination
          />

          {showModal && selectedInvoice && (
            <InvoicePaymentModal
              invoice={selectedInvoice}
              onClose={handleModalClose}></InvoicePaymentModal>
          )}
        </div>
      )}
    </div>
  );
};

export default InvoicesPage;
