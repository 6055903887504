// Accordion.js
import { useState } from 'react';

export default function Accordion({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border border-gray-200 rounded-md mb-2">
      <button
        onClick={toggleAccordion}
        className="w-full px-4 py-2 text-left font-medium text-gray-900 bg-gray-100 border-b border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        {title}
      </button>
      {isOpen && (
        <div className="p-4">
          {children}
        </div>
      )}
    </div>
  );
}
