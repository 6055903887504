import React from "react";
import {
  FolderIcon,
  DocumentIcon,
  LockClosedIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";

const FileExplorer = ({
  files,
  clientId,
  onFileClick,
  onPasswordRequired,
  onRevealPassword,
  revealedPasswordFiles,
}) => {
  console.log("IN FILES FROM PROP", files);

  const handleFileClick = (fileName) => {
    onFileClick(fileName);
  };

  const renderTree = (tree, path = "") => {
    if (!tree || typeof tree !== "object") {
      return null;
    }

    return Object.keys(tree).map((key) => {
      const fullPath = `${path}/${key}`;
      const fileData = tree[key]; // Access the current node in the tree

      if (fileData && fileData.name) {
        // Handle files (with a name property)
        const isPasswordProtected = fileData.isPasswordProtected;
        const isPasswordRevealed = revealedPasswordFiles?.[fullPath];

        return (
          <li key={fullPath} className="flex items-center space-x-2 py-1">
            <DocumentIcon className="h-5 w-5 text-gray-400" />
            <button
              onClick={() => onFileClick(fullPath)}
              className="text-sm text-blue-600 hover:underline">
              {fileData.name}
            </button>
            {isPasswordProtected ? (
              <>
                <LockClosedIcon className="h-4 w-4 text-yellow-500" />
                {!isPasswordRevealed ? (
                  <button
                    onClick={() => onRevealPassword(fullPath)}
                    className="text-xs text-gray-500 hover:text-gray-700">
                    <EyeIcon className="h-4 w-4" />
                  </button>
                ) : (
                  <span className="text-xs text-gray-500 ml-2">
                    Password:{" "}
                    {fileData.password || revealedPasswordFiles[fullPath]}
                  </span>
                )}
              </>
            ) : (
              <span className="text-xs text-green-500 ml-2">NPP</span>
            )}
          </li>
        );
      } else if (typeof fileData === "object") {
        // Handle directories (folders)
        return (
          <ul key={fullPath}>
            <div className="flex items-center space-x-2 py-1">
              <FolderIcon className="h-5 w-5 text-gray-500" />
              <span className="text-sm font-medium">{key}</span>
            </div>
            <ul className="pl-6">{renderTree(fileData, fullPath)}</ul>
          </ul>
        );
      } else {
        // Unexpected structure or plain string (file path)
        console.warn(`Unexpected file structure for key: ${key}`, fileData);
        return null;
      }
    });
  };

  return (
    <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
      {files && typeof files === "object" ? (
        renderTree(files)
      ) : (
        <p>No files to display.</p>
      )}
    </div>
  );
};

export default FileExplorer;
