import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUploadsWithDetails,
  fetchFile,
  checkFilePassword,
  recordPasswordAccess,
  fetchFilePassword,
} from "../../store/thunks/uploadsThunk";
import FileExplorer from "../../applicationUi/components/FileExplorer";

export default function ClientUploads({ client, clientId }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const actualClientId = client?._id || clientId;
  const [revealedPasswordFiles, setRevealedPasswordFiles] = useState({});

  // New local state to store the result of fetchUploadsWithDetails
  const [uploadsWithDetails, setUploadsWithDetails] = useState(null);

  useEffect(() => {
    if (actualClientId) {
      dispatch(fetchUploadsWithDetails(actualClientId))
        .unwrap()
        .then((result) => {
          console.log("IN UPLOADS FROM THUNK:", result);
          setUploadsWithDetails(result); // Store the response in local state
        })
        .catch((error) => {
          console.error("Failed to fetch uploads with details:", error);
        });
    }
  }, [dispatch, actualClientId]);

  const handleFileClick = (fileName) => {
    const sanitizedFileName = fileName.replace(/^\//, "");

    dispatch(
      fetchFile({ clientId: actualClientId, fileName: sanitizedFileName })
    )
      .unwrap()
      .then((result) => {
        const url = URL.createObjectURL(
          new Blob([result.fileBlob], { type: result.fileType })
        );

        // Open file in new tab
        const newTab = window.open(url, "_blank");
        if (newTab) {
          newTab.document.write(
            `<iframe src="${url}" style="width:100%; height:100%;" frameborder="0"></iframe>`
          );
          // Clean up the URL object after use
          URL.revokeObjectURL(url);
        }
      })
      .catch((err) => {
        console.error("Failed to fetch file:", err);
      });
  };

  const renderFiles = (files) => {
    if (!files || files.length === 0) {
      return <p>No files found.</p>;
    }

    const fileTree = {};

    files.forEach((file) => {
      if (typeof file === "string") {
        // Handle the case where file is just a string path
        const parts = file.split("/");
        let current = fileTree;
        parts.forEach((part, index) => {
          if (!current[part]) {
            current[part] = index === parts.length - 1 ? { name: part } : {};
          }
          current = current[part];
        });
      } else if (file && typeof file === "object" && file.path) {
        // Handle the case where file is an object with a path property
        const parts = file.path.split("/");
        let current = fileTree;
        parts.forEach((part, index) => {
          if (!current[part]) {
            current[part] = {
              name: part,
              isPasswordProtected: file.isPasswordProtected || false, // Ensure we include the password protection status
              password: file.isPasswordProtected ? file.password : null, // Include the password if available
            };
          }
          current = current[part];
        });
      } else {
        console.error("Unexpected file structure:", file);
      }
    });

    console.log("File tree structure:", JSON.stringify(fileTree, null, 2)); // Log the result of the function with indentation

    return (
      <FileExplorer
        files={fileTree}
        clientId={actualClientId}
        onFileClick={handleFileClick}
        onPasswordRequired={handlePasswordRequired}
        onRevealPassword={handleRevealPassword}
        revealedPasswordFiles={revealedPasswordFiles}
      />
    );
  };

  const handleRevealPassword = (fileName) => {
    dispatch(fetchFilePassword({ clientId: actualClientId, fileName }))
      .unwrap()
      .then((result) => {
        if (result.password) {
          setRevealedPasswordFiles((prevState) => ({
            ...prevState,
            [fileName]: result.password,
          }));
        } else {
          alert("Failed to retrieve password");
        }
      })
      .catch((err) => {
        console.error("Failed to fetch password:", err);
      });
  };

  const handlePasswordRequired = (fileName) => {
    // Prompt for password
    const password = prompt("Enter password to view file:");
    if (password) {
      dispatch(
        checkFilePassword({ clientId: actualClientId, fileName, password })
      )
        .unwrap()
        .then((result) => {
          if (result.success) {
            handleFileClick(fileName);
            recordPasswordView(fileName);
          } else {
            alert("Incorrect password");
          }
        })
        .catch((err) => {
          console.error("Failed to check password:", err);
        });
    }
  };

  const recordPasswordView = (fileName) => {
    const userId = user._id;
    dispatch(
      recordPasswordAccess({ clientId: actualClientId, fileName, userId })
    );
  };

  // Render the files from uploadsWithDetails if available
  return (
    <div>
      <h3 className="text-sm font-medium text-gray-700 pb-4">
        Uploaded Documents
      </h3>
      {uploadsWithDetails === null ? (
        <p className="text-sm font-medium text-gray-700">Loading uploads...</p>
      ) : (
        renderFiles(uploadsWithDetails.files) // Use the fetched files from uploadsWithDetails
      )}
    </div>
  );
}
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchUploadsWithDetails,
//   fetchFile,
//   checkFilePassword,
//   recordPasswordAccess,
//   fetchFilePassword,
// } from "../../store/thunks/uploadsThunk";
// import FileExplorer from "../../applicationUi/components/FileExplorer";

// export default function ClientUploads({ client, clientId }) {
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.auth.user);
//   const { files, status, error } = useSelector((state) => state.uploads);
//   const actualClientId = client?._id || clientId;
//   const [revealedPasswordFiles, setRevealedPasswordFiles] = useState({});

//   console.log("files from store", files);

//   useEffect(() => {
//     if (actualClientId) {
//       dispatch(fetchUploadsWithDetails(actualClientId))
//         .unwrap()
//         .then((result) => {
//           console.log("IN UPLOADS FROM THUNK:", result);
//         });
//     }
//   }, [dispatch, actualClientId]);

//   const handleFileClick = (fileName) => {
//     const sanitizedFileName = fileName.replace(/^\//, "");

//     dispatch(
//       fetchFile({ clientId: actualClientId, fileName: sanitizedFileName })
//     )
//       .unwrap()
//       .then((result) => {
//         const url = URL.createObjectURL(
//           new Blob([result.fileBlob], { type: result.fileType })
//         );

//         // Open file in new tab
//         const newTab = window.open(url, "_blank");
//         if (newTab) {
//           newTab.document.write(
//             `<iframe src="${url}" style="width:100%; height:100%;" frameborder="0"></iframe>`
//           );
//           // Clean up the URL object after use
//           URL.revokeObjectURL(url);
//         }
//       })
//       .catch((err) => {
//         console.error("Failed to fetch file:", err);
//       });
//   };

//   const renderFiles = (files) => {
//     if (!files || files.length === 0) {
//       return <p>No files found.</p>;
//     }

//     const fileTree = {};

//     files.forEach((file) => {
//       if (typeof file === "string") {
//         // Handle the case where file is just a string path
//         const parts = file.split("/");
//         let current = fileTree;
//         parts.forEach((part, index) => {
//           if (!current[part]) {
//             current[part] = index === parts.length - 1 ? { name: part } : {};
//           }
//           current = current[part];
//         });
//       } else if (file && typeof file === "object" && file.path) {
//         // Handle the case where file is an object with a path property
//         const parts = file.path.split("/");
//         let current = fileTree;
//         parts.forEach((part, index) => {
//           if (!current[part]) {
//             current[part] = {
//               name: part,
//               isPasswordProtected: file.isPasswordProtected || false, // Ensure we include the password protection status
//               password: file.isPasswordProtected ? file.password : null, // Include the password if available
//             };
//           }
//           current = current[part];
//         });
//       } else {
//         console.error("Unexpected file structure:", file);
//       }
//     });

//     console.log("File tree structure:", JSON.stringify(fileTree, null, 2)); // Log the result of the function with indentation

//     return (
//       <FileExplorer
//         files={fileTree}
//         clientId={actualClientId}
//         onFileClick={handleFileClick}
//         onPasswordRequired={handlePasswordRequired}
//         onRevealPassword={handleRevealPassword}
//         revealedPasswordFiles={revealedPasswordFiles}
//       />
//     );
//   };

//   const handleRevealPassword = (fileName) => {
//     dispatch(fetchFilePassword({ clientId: actualClientId, fileName }))
//       .unwrap()
//       .then((result) => {
//         if (result.password) {
//           setRevealedPasswordFiles((prevState) => ({
//             ...prevState,
//             [fileName]: result.password,
//           }));
//         } else {
//           alert("Failed to retrieve password");
//         }
//       })
//       .catch((err) => {
//         console.error("Failed to fetch password:", err);
//       });
//   };

//   const handlePasswordRequired = (fileName) => {
//     // Prompt for password
//     const password = prompt("Enter password to view file:");
//     if (password) {
//       dispatch(
//         checkFilePassword({ clientId: actualClientId, fileName, password })
//       )
//         .unwrap()
//         .then((result) => {
//           if (result.success) {
//             handleFileClick(fileName);
//             recordPasswordView(fileName);
//           } else {
//             alert("Incorrect password");
//           }
//         })
//         .catch((err) => {
//           console.error("Failed to check password:", err);
//         });
//     }
//   };

//   const recordPasswordView = (fileName) => {
//     const userId = user._id;
//     dispatch(
//       recordPasswordAccess({ clientId: actualClientId, fileName, userId })
//     );
//   };

//   return (
//     <div>
//       <h3 className="text-sm font-medium text-gray-700 pb-4">
//         Uploaded Documents
//       </h3>
//       {status === "loading" && (
//         <p className="text-sm font-medium text-gray-700">Loading uploads...</p>
//       )}
//       {status === "failed" && (
//         <p className="text-sm font-medium text-red-700">Error: {error}</p>
//       )}
//       {status === "succeeded" && files.length === 0 && (
//         <p className="text-sm font-medium text-gray-700">
//           No uploads found for this client.
//         </p>
//       )}
//       {status === "succeeded" && files.length > 0 && renderFiles(files)}
//     </div>
//   );
// }
