import { createSlice } from "@reduxjs/toolkit";
import {
    loginUserAsync,
    logoutUserAsync,
    registerUserAsync,
    verifyPassword,
    checkTokenValidity,
} from "../thunks/authThunk";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuthenticated: null, 
        isPasswordVerified: null,
        isRegistrationSuccessful: null,
        user: null,
        token: null,
        loading: false,
        error: null,
        passwordResetRequired: false,
    },
    reducers: {
        setLockScreen: (state) => {
            state.isPasswordVerified = false;
        },
        setLogout: (state) => {
            state.isAuthenticated = false;
            state.isPasswordVerified = false;
            state.user = null;
            state.token = null;
        },
        clearPasswordResetRequired: (state) => {
            state.passwordResetRequired = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // loginUserAsync reducers:
            .addCase(loginUserAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUserAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.isPasswordVerified = true;
                state.user = action.payload.user;
                state.token = action.payload.token;
                state.passwordResetRequired = action.payload.passwordResetRequired;
            })
            .addCase(loginUserAsync.rejected, (state, action) => {
                console.log("Action" + JSON.stringify(action.payload));
                state.loading = false;
                state.error = action.payload
                    ? action.payload.error
                    : "Login Failed";
                state.isAuthenticated = false;
            })
            // registerUserAsync reducers:
            .addCase(registerUserAsync.pending, (state) => {
                state.isRegistrationSuccessful = false;
                state.loading = true;
                state.error = null;
            })
            .addCase(registerUserAsync.fulfilled, (state, action) => {
                state.loading = false;

                // After registration, the user isn't authenticated yet (needs to log in)
                state.isAuthenticated = false;
                state.isPasswordVerified = false;
                state.isRegistrationSuccessful = true;
                state.user = null; // Clear any previous user data
                state.token = null; // No token yet
                state.passwordResetRequired = action.payload.passwordResetRequired;
            })
            .addCase(registerUserAsync.rejected, (state, action) => {
                state.isRegistrationSuccessful = false;
                state.loading = false;
                state.error = action.payload
                    ? action.payload.message
                    : "Registration Failed";
            })
            .addCase(verifyPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(verifyPassword.fulfilled, (state) => {
                state.loading = false;
                state.isPasswordVerified = true; // Mark password as verified
            })
            .addCase(verifyPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.isPasswordVerified = false; // Explicitly set to false on failure
            })
            .addCase(logoutUserAsync.fulfilled, (state) => {
                state.isAuthenticated = false;
                state.isPasswordVerified = false; // Also reset password verification
                state.user = null;
                state.token = null;
            })
            .addCase(logoutUserAsync.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(checkTokenValidity.fulfilled, (state, action) => {
                state.loading = false;
                state.isValid = action.payload.isValid;
            })
            .addCase(checkTokenValidity.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { setLockScreen, setLogout } = authSlice.actions; // Export the actions
export default authSlice.reducer;
