import React, { useState } from 'react';
import axios from 'axios';
import { generateConfig } from "../../utils/api";

function QuickBooksCashFlow(_, { getState }) {
    const [file, setFile] = useState(null);
    const state = getState();
    const token = state.auth.token;
    const { baseURL } = generateConfig({ token: token });

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileProcess = () => {
        if (!file) return;
    
        const formData = new FormData();
        formData.append('csv', file);
    
        axios.post(`${baseURL}/api/quickBooks/cashFlow`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "x-api-token": process.env.REACT_APP_TOKEN,
                "x-api-secret": process.env.REACT_APP_SECRET,
                "x-user_token": token
            }
        })
        .then((response) => {
            alert('Data successfully stored in the database!');
        })
        .catch((error) => {
            console.error('Error storing data in the database', error);
            alert('Failed to store data in the database.');
        });
    };

    return (
        <div>
            <h1>QuickBooks Cash Flow</h1>
            <input type="file" accept=".csv" onChange={handleFileChange} />
            <button onClick={handleFileProcess}>Process</button>
        </div>
    );
}

export default QuickBooksCashFlow;
