import React from "react";

const FullScreenSchedule = ({ isOpen, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-75 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-white overflow-auto p-4">{children}</div>
    </div>
  );
};

export default FullScreenSchedule;
