import React, { useState } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import { Menu } from "@headlessui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateNavigator = ({
  onPrevDay,
  onToday,
  onNextDay,
  onPrevWeek, // Add onPrevWeek prop
  onNextWeek, // Add onNextWeek prop
  onDateChange,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onDateChange(date);
    setIsCalendarOpen(false); // Close the calendar after selecting a date
  };

  const handleCalendarClose = () => {
    setIsCalendarOpen(false);
  };

  const handleCalendarOpen = (e) => {
    e.preventDefault(); // Prevent the default menu close behavior
    setIsCalendarOpen(true);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* Prev week */}
      <button
        type="button"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0.5rem",
          border: "none",
          background: "none",
          cursor: "pointer",
        }}
        onClick={onPrevWeek}
        title="Previous Week">
        <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
        <div
          style={{
            fontSize: "0.75rem",
            color: "#4a4a4a",
            marginTop: "0.25rem",
          }}>
          Prev week
        </div>
      </button>

      {/* Prev day */}
      <button
        type="button"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0.5rem",
          border: "none",
          background: "none",
          cursor: "pointer",
          marginLeft: "6px",
        }}
        onClick={onPrevDay}
        title="Previous Day">
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        <div
          style={{
            fontSize: "0.75rem",
            color: "#4a4a4a",
            marginTop: "0.25rem",
          }}>
          Prev day
        </div>
      </button>

      {/* Calendar Icon */}
      <Menu as="div" className="relative">
        <div>
          <Menu.Button
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0.5rem",
              background: "none",
              cursor: "pointer",
              borderRadius: "0.25rem",
            }}
            onClick={handleCalendarOpen}>
            <CalendarIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        {isCalendarOpen && (
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              marginTop: "0.5rem",
              width: "12rem",
              background: "white",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "0.25rem",
            }}>
            <div style={{ padding: "0.5rem" }}>
              <div>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  inline
                  onClickOutside={handleCalendarClose}
                  onCalendarClose={handleCalendarClose}
                />
              </div>
            </div>
          </div>
        )}
      </Menu>

      {/* Today */}
      <button
        type="button"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "6px",
          background: "none",
          cursor: "pointer",
          borderRadius: "0.25rem",
        }}
        onClick={onToday}>
        Today
      </button>

      {/* Next day */}
      <button
        type="button"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0.5rem",
          border: "none",
          background: "none",
          cursor: "pointer",
          marginRight: "6px",
        }}
        onClick={onNextDay}
        title="Next Day">
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        <div
          style={{
            fontSize: "0.75rem",
            color: "#4a4a4a",
            marginTop: "0.25rem",
          }}>
          Next day
        </div>
      </button>

      {/* Next week */}
      <button
        type="button"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0.5rem",
          border: "none",
          background: "none",
          cursor: "pointer",
        }}
        onClick={onNextWeek}
        title="Next Week">
        <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
        <div
          style={{
            fontSize: "0.75rem",
            color: "#4a4a4a",
            marginTop: "0.25rem",
          }}>
          Next week
        </div>
      </button>
    </div>
  );
};

export default DateNavigator;
