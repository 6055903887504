import React from "react";
import SevenDayCalendar from "./SevenDayCalendar";

export default function ClinicSchedule() {
  const hardcodedAppointment = {
    _id: "665511886d3a98d154eb46b0",
    clinic: "664a30000fbe947bb6d769c9",
    clinician: "664ea8338d9e2ba57297d46c",
    client: "66515445cee514e5040438fc",
    title: "Administration Form Fees (Audiology)",
    start: new Date("2024-05-27T15:55:34.043Z"),
    end: new Date("2024-05-27T16:25:34.043Z"),
    service: "66527e194b59b4e952b41fd4",
    status: ["Carryover"],
    history: [
      {
        status: "Tentative",
        timestamp: new Date("2024-05-27T23:04:40.156Z"),
        comments: "",
        _id: "665511886d3a98d154eb46b1",
      },
      {
        status: "Comments",
        timestamp: new Date("2024-06-06T22:21:22.841Z"),
        comments: "comment on individual event",
        user: "664ea8338d9e2ba57297d46c",
        _id: "66623663f07ea0de7ac1c14a",
      },
      {
        status: "Comments",
        timestamp: new Date("2024-06-08T06:03:04.422Z"),
        comments: "hi greg how are u!",
        user: "664ea8338d9e2ba57297d46c",
        _id: "6663f418c0b5231148099604",
      },
      {
        status: "Comments",
        timestamp: new Date("2024-06-08T06:03:36.351Z"),
        comments: "1 second",
        user: "664ea8338d9e2ba57297d46c",
        _id: "6663f438c0b5231148099615",
      },
      {
        status: "Confirmed",
        timestamp: new Date("2024-09-04T03:52:16.257Z"),
        comments: "Status changed to Confirmed",
        user: "664174b5f3c90e26dcd77242",
        _id: "66d7d97010fe7defc61cbf1f",
      },
      {
        status: "Carryover",
        timestamp: new Date("2024-09-09T21:23:21.647Z"),
        comments: "Status changed to Carryover",
        user: "664ea8338d9e2ba57297d46c",
        _id: "66df67491d071ae5a275e288",
      },
    ],
    carryoverPayPeriod: 2024.13,
    isCarryover: true,
    carryoverSubmissionDate: new Date("2024-06-11T04:00:00.000Z"),
    isBlock: false,
    soapNote: [],
    soapNoteFinalized: false,
  };

  return (
    <>
      <SevenDayCalendar appointments={[hardcodedAppointment]} />
    </>
  );
}
// import React, { useEffect, useState } from "react";
// import SevenDayCalendar from "./SevenDayCalendar";
// import { Calendar, momentLocalizer } from "react-big-calendar";
// import moment from "moment";
// import "react-big-calendar/lib/css/react-big-calendar.css"; // Import the CSS
// import ical from "ical.js";

// const localizer = momentLocalizer(moment);

// export default function ClinicSchedule() {
//   const [events, setEvents] = useState([]); // Your appointment data

//   const clinicians = [
//     {
//       id: 1,
//       title: "Yvonne, Oliveira",
//       icalUrl:
//         "https://ca-alb1.aws.bp-solutions.net:8443/ca_ical/ical?T05faHNz/EfRSBvUt71hBIp9",
//     },
//   ];

//   const handleSelectSlot = ({ start, end }) => {
//     // Open your AppointmentPicker modal here
//     // ...
//   };

//   const handleAddAppointment = (newAppointment) => {
//     setEvents([...events, newAppointment]);
//     // ... close the modal
//   };

//   useEffect(() => {
//     const fetchEvents = async () => {
//       const allEvents = [];
//       for (const clinician of clinicians) {
//         try {
//           const response = await fetch(clinician.icalUrl); // Assuming clinician.icalUrl exists
//           const icalData = await response.text();
//           const parsedData = ical.parse(icalData);
//           const clinicianEvents = parsedData.subComponents
//             .filter((comp) => comp.name === "VEVENT")
//             .map((event) => ({
//               title: event.getFirstPropertyValue("summary"),
//               start: new Date(
//                 event.getFirstPropertyValue("dtstart").toJSDate()
//               ),
//               end: new Date(event.getFirstPropertyValue("dtend").toJSDate()),
//               resourceId: clinician.id, // Associate with the clinician
//             }));
//           allEvents.push(...clinicianEvents);
//         } catch (error) {
//           console.error(`Error fetching events for ${clinician.title}:`, error);
//         }
//       }
//       setEvents(allEvents);
//     };

//     fetchEvents();
//   }, []);

//   return (
//     <>
//       <SevenDayCalendar />
//       {/* <Calendar
//         localizer={localizer}
//         events={events}
//         selectable
//         defaultView="week" // Or 'day', 'month', etc.
//         onSelectSlot={handleSelectSlot}
//         resources={clinicians} // Array of clinician data
//         // ... other props for customization (time slots, headers, etc.)
//       /> */}

//       {/* <AppointmentPicker onAddAppointment={handleAddAppointment} />  */}
//     </>
//   );
// }
