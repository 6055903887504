import React, { useEffect, useState } from "react";
import Table from "../../applicationUi/components/Table"; // Adjust the path as necessary
import ClientUploads from "./ClientUploads"; // Adjust the path as necessary
import ClientDetails from "./ClientDetails";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { calculateAge } from "../../utils/calculateAge";
import { UsersIcon } from "@heroicons/react/24/outline";
import FamilyDetails from "./FamilyDetails";
import { useDispatch } from "react-redux";
import { fetchClientById } from "../../store/thunks/clientsThunk";
import { uploadFile, fetchUploads } from "../../store/thunks/uploadsThunk";

export default function ClientOverview({
  selectedClient,
  invoices,
  invoiceStatus,
  soapNotes,
  soapNoteStatus,
  onClose,
}) {
  const dispatch = useDispatch();
  const [client, setClient] = useState(null); // Store the fetched client data
  const [selectedTab, setSelectedTab] = useState("details");
  const [folderName, setFolderName] = useState("");
  const [password, setPassword] = useState("");
  const [newFolder, setNewFolder] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [existingFolders, setExistingFolders] = useState(null);
  const [file, setFile] = useState(null);

  // Fetch the client data when the component mounts or when clientId changes
  useEffect(() => {
    const loadClient = async () => {
      const result = await dispatch(fetchClientById(selectedClient._id));
      setClient(result.payload); // Update client state with fetched data
    };

    loadClient();
  }, [selectedClient, dispatch]);

  const clientAge = client?.dateOfBirth
    ? calculateAge(client.dateOfBirth)
    : "N/A";

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  //   const handleFileUpload = (e) => {
  //     e.preventDefault();
  //     console.log("Uploading file:", file);
  //   };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (file && folderName) {
      try {
        const formData = new FormData();
        formData.append("document", file);
        formData.append("folderName", folderName);
        if (password) {
          formData.append("password", password);
        }

        const resultAction = await dispatch(
          uploadFile({
            clientId: selectedClient?._id ?? "",
            formData,
            folderName,
          })
        );

        if (uploadFile.fulfilled.match(resultAction)) {
          setUploadResponse({
            message: "File uploaded successfully",
            type: "success",
          });
          // Clear the form
          setFile(null);
          setFolderName("");
          setPassword("");
        } else if (uploadFile.rejected.match(resultAction)) {
          setUploadResponse({
            message: resultAction.payload || "File upload failed",
            type: "error",
          });
        }
      } catch (error) {
        console.error("File upload failed", error);
        setUploadResponse({
          message: "An unexpected error occurred",
          type: "error",
        });
      }
    } else {
      setUploadResponse({
        message: "Please provide both a file and a folder name",
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (client?._id) {
      dispatch(fetchUploads(client._id))
        .then((resultAction) => {
          if (fetchUploads.fulfilled.match(resultAction)) {
            const files = resultAction.payload;
            if (files && Array.isArray(files)) {
              // Extract folder names from file paths
              const folderSet = new Set();
              files.forEach((filePath) => {
                const folderName = filePath.split("/")[0];
                folderSet.add(folderName);
              });
              setExistingFolders(Array.from(folderSet));
              console.log("folders", existingFolders);
            } else {
              console.error(
                "Files data is not in expected format:",
                resultAction.payload
              );
            }
          } else {
            console.error("Failed to fetch uploads:", resultAction.error);
          }
        })
        .catch((error) => {
          console.error("Error fetching uploads:", error);
        });
    }
  }, [client, dispatch]);

  const handleCopyEmail = () => {
    if (client?.email) {
      navigator.clipboard.writeText(client.email);
      toast.success("Copied to clipboard!");
    }
  };

  const invoiceColumns = [
    { header: "Invoice ID", accessor: "invoiceId" },
    { header: "Amount", accessor: "amount" },
    { header: "Date", accessor: "date" },
  ];

  if (!client) {
    return <div>Loading client data...</div>;
  }

  return (
    <div className="p-6 bg-white shadow rounded-lg">
      <div className="flex justify-between items-center">
        {/* Left side: Client Name */}
        <div className="flex items-center">
          <div>
            <div className="flex items-center ml-3 text-xl font-bold text-gray-900 sm:truncate sm:leading-9">
              <p>
                {client.firstName} {client.lastName} ({clientAge})
              </p>
              <UsersIcon className="h-6 w-6 text-black ml-2" />
            </div>
            <p className="ml-3 sm:truncate">
              {client.address?.streetNumber} {client.address?.streetName}
            </p>
            <p className="ml-3 sm:truncate">
              {client.address?.city} {client.address?.province}{" "}
              {client.address?.postalCode}
            </p>
            <p className="ml-3">{client.phoneNumbers[0]?.number}</p>
            <div>
              <p
                className="ml-3 cursor-pointer text-blue-500 hover:underline"
                onClick={handleCopyEmail}>
                {client.email}
              </p>
              <ToastContainer autoClose={1000} />
            </div>
          </div>
        </div>

        {/* Appointment and Clinician Details */}
        <div className="flex items-center">
          <div>
            <p>
              Next Appointment Date: {client?.appointments?.[0]?.start || "N/A"}
            </p>
            <p>
              Treating Clinician:{" "}
              {client?.treatingClinician?.firstName || "N/A"}
            </p>
          </div>
        </div>

        {/* Right side: Buttons */}
        <div className="flex items-center space-x-4">
          <button
            type="button"
            onClick={onClose}
            className="inline-flex items-center rounded-md bg-gray-200 p-2 text-gray-500 hover:bg-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Tabs Navigation */}
      <div className="mt-4">
        <nav className="flex space-x-4" aria-label="Tabs">
          <button
            onClick={() => handleTabChange("details")}
            className={classNames(
              selectedTab === "details"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}
            aria-current={selectedTab === "details" ? "page" : undefined}>
            Client Details
          </button>
          <button
            onClick={() => handleTabChange("family")}
            className={classNames(
              selectedTab === "family"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}
            aria-current={selectedTab === "family" ? "page" : undefined}>
            Family Details
          </button>
          <button
            onClick={() => handleTabChange("invoices")}
            className={classNames(
              selectedTab === "invoices"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}>
            Invoices
          </button>
          <button
            onClick={() => handleTabChange("soapNotes")}
            className={classNames(
              selectedTab === "soapNotes"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}>
            SOAP Notes
          </button>
          <button
            onClick={() => handleTabChange("upload")}
            className={classNames(
              selectedTab === "upload"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}>
            Upload
          </button>
          <button
            onClick={() => handleTabChange("files")}
            className={classNames(
              selectedTab === "files"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}>
            Files
          </button>
        </nav>
      </div>

      {/* Content for each tab */}
      <div className="mt-6">
        {selectedTab === "details" && <ClientDetails client={client} />}
        {selectedTab === "family" && <FamilyDetails client={client} />}
        {selectedTab === "invoices" && (
          <Table
            data={invoices}
            columns={invoiceColumns}
            status={invoiceStatus}
          />
        )}
        {selectedTab === "soapNotes" &&
          (soapNotes && soapNotes.length > 0 ? (
            <Table data={soapNotes} status={soapNoteStatus} />
          ) : (
            <p>No SOAP notes available. this is for testing</p>
          ))}
        {selectedTab === "upload" && (
          <div>
            {/* Upload Form */}
            <form onSubmit={handleFileUpload}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Folder Name
                </label>
                <div className="flex items-center">
                  <select
                    className="mt-1 block w-full text-sm text-gray-500"
                    value={newFolder ? "" : folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    disabled={newFolder}
                    required>
                    <option value="" disabled>
                      Select an existing folder
                    </option>
                    {existingFolders.map((folder) => (
                      <option key={folder} value={folder}>
                        {folder}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    className="ml-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 whitespace-nowrap"
                    onClick={() => setNewFolder(!newFolder)}>
                    {newFolder ? "Select Existing Folder" : "New Folder"}
                  </button>
                </div>
                {newFolder && (
                  <input
                    type="text"
                    className="mt-1 block w-full text-sm text-gray-500"
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    placeholder="Enter new folder name"
                    required
                  />
                )}
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Upload Document
                </label>
                <input
                  type="file"
                  className="mt-1 block w-full text-sm text-gray-500"
                  onChange={(e) => setFile(e.target.files[0])}
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Password (optional)
                </label>
                <input
                  type="password"
                  className="mt-1 block w-full text-sm text-gray-500"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="If file is password protected, enter password here"
                />
              </div>

              <button
                type="submit"
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
                Upload
              </button>
            </form>

            {uploadResponse && (
              <div
                className={`mt-4 ${
                  uploadResponse.type === "success"
                    ? "text-green-500"
                    : "text-red-500"
                } text-sm font-medium`}>
                {uploadResponse.message}
              </div>
            )}
          </div>
        )}
        {selectedTab === "files" && (
          <ClientUploads
            client={selectedClient}
            existingFolders={existingFolders}
          />
        )}
      </div>
    </div>
  );
}

// Helper function for dynamic class names
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
