import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAppointmentsByClinicianId } from "../../../store/thunks/appointmentsThunk";
import DashboardCards from "./DashboardCards";
import WaagChart from "./WaagChart";

export default function OwnerDashboardComponent({
    clinician,
    handleClientClick,
    handleAppointmentClick,
    handleReschedule, // Add this prop
}) {
    const dispatch = useDispatch();
    const appointments = useSelector(
        (state) => state.appointments.appointments || []
    );
    // const { clinician: clinicianDetails, appointments: appointmentData } =
    //   useSelector(
    //     (state) => state.appointments || { clinician: {}, appointments: [] }
    //   );

    // const { appointments } = appointmentData;
    const [filteredAppointments, setFilteredAppointments] = useState([]);

    useEffect(() => {
        if (appointments) {
            setFilteredAppointments(appointments);
        }
    }, [appointments]);

    useEffect(() => {
        setFilteredAppointments(appointments);
    }, [appointments]);

    const handleFilterAppointments = (filterFunction) => {
        setFilteredAppointments(filterFunction(appointments));
    };

    useEffect(() => {
        dispatch(fetchAppointmentsByClinicianId(clinician._id));
    }, [dispatch, clinician]);

    return (
        <div className="grid grid-cols-3 gap-6 pb-20">
            <div className="col-span-3">
                <h3>OWNER DASHBOARD</h3>
                <DashboardCards />
                <WaagChart />
            </div>
        </div>
    );
}
