import { useEffect, useState } from "react";
import { CalendarDaysIcon, CashIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";

const DashboardCards = () => {
    const [counts, setCounts] = useState({
        lastMonth: 0,
        lastWeek: 0,
        yesterday: 0,
        today: 0,
        thisWeek: 0,
        nextWeek: 0,
        nextMonth: 0,
    });
    const [selectedCard, setSelectedCard] = useState(null);

    const now = new Date();
    const startOfToday = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    );
    const startOfYesterday = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 1)
    );
    const startOfTomorrow = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1)
    );
    const startOfWeek = new Date(
        Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate() - now.getUTCDay()
        )
    );
    const startOfThisWeek = new Date(startOfWeek);
    const startOfLastWeek = new Date(startOfWeek);
    startOfLastWeek.setUTCDate(startOfLastWeek.getUTCDate() - 7);
    const startOfNextWeek = new Date(startOfWeek);
    startOfNextWeek.setUTCDate(startOfNextWeek.getUTCDate() + 7);
    const startOfMonth = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1)
    );
    const startOfLastMonth = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth() - 1, 1)
    );
    const endOfLastMonth = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 0)
    );
    const startOfNextMonth = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 1)
    );
    const endOfNextMonth = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 2, 0)
    );

    const cards = [
        {
            name: "Last Month's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts.lastMonth,
            filter: (appointments) =>
                appointments.filter(
                    (appointment) =>
                        new Date(appointment.start) >= startOfLastMonth &&
                        new Date(appointment.start) <= endOfLastMonth
                ),
        },
        {
            name: "Last Week's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts.lastWeek,
            filter: (appointments) =>
                appointments.filter(
                    (appointment) =>
                        new Date(appointment.start) >= startOfLastWeek &&
                        new Date(appointment.start) < startOfWeek
                ),
        },
        {
            name: "This Week's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts.thisWeek,
            filter: (appointments) =>
                appointments.filter(
                    (appointment) =>
                        new Date(appointment.start) >= startOfThisWeek &&
                        new Date(appointment.start) <
                            new Date(
                                startOfNextWeek.getTime() + 24 * 60 * 60 * 1000
                            )
                ),
        },
        {
            name: "Next Week's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts.nextWeek,
            filter: (appointments) =>
                appointments.filter(
                    (appointment) =>
                        new Date(appointment.start) >= startOfNextWeek &&
                        new Date(appointment.start) <
                            new Date(
                                startOfNextWeek.getTime() +
                                    7 * 24 * 60 * 60 * 1000
                            )
                ),
        },
        {
            name: "Next Month's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts.nextMonth, // Ensure you update `counts` to include `nextMonth`
            filter: (appointments) =>
                appointments.filter(
                    (appointment) =>
                        new Date(appointment.start) >= startOfNextMonth &&
                        new Date(appointment.start) <= endOfNextMonth
                ),
        },
        {
            name: "Last Month's Revenue",
            href: "#",
            icon: CurrencyDollarIcon, // Use CurrencyDollarIcon for financial representation
            amount: counts.lastMonth,
            filter: (appointments) =>
                appointments.filter(
                    (appointment) =>
                        new Date(appointment.start) >= startOfLastMonth &&
                        new Date(appointment.start) <= endOfLastMonth
                ),
        },
        {
            name: "Last Week's Revenue",
            href: "#",
            icon: CurrencyDollarIcon, // Use CurrencyDollarIcon for financial representation
            amount: counts.lastWeek,
            filter: (appointments) =>
                appointments.filter(
                    (appointment) =>
                        new Date(appointment.start) >= startOfLastWeek &&
                        new Date(appointment.start) < startOfWeek
                ),
        },
        {
            name: "This Week's Revenue",
            href: "#",
            icon: CurrencyDollarIcon, // Use CurrencyDollarIcon for financial representation
            amount: counts.thisWeek,
            filter: (appointments) =>
                appointments.filter(
                    (appointment) =>
                        new Date(appointment.start) >= startOfThisWeek &&
                        new Date(appointment.start) <
                            new Date(startOfNextWeek.getTime() + 24 * 60 * 60 * 1000)
                ),
        },
        {
            name: "Next Week's Revenue",
            href: "#",
            icon: CurrencyDollarIcon, // Use CurrencyDollarIcon for financial representation
            amount: counts.nextWeek,
            filter: (appointments) =>
                appointments.filter(
                    (appointment) =>
                        new Date(appointment.start) >= startOfNextWeek &&
                        new Date(appointment.start) <
                            new Date(startOfNextWeek.getTime() + 7 * 24 * 60 * 60 * 1000)
                ),
        },
        {
            name: "Next Month's Revenue",
            href: "#",
            icon: CurrencyDollarIcon, // Use CurrencyDollarIcon for financial representation
            amount: counts.nextMonth,
            filter: (appointments) =>
                appointments.filter(
                    (appointment) =>
                        new Date(appointment.start) >= startOfNextMonth &&
                        new Date(appointment.start) <= endOfNextMonth
                ),
        },
    ];

    return (
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8 2xl:max-w-7xl">
            <h2 className="text-lg font-medium leading-6 text-gray-900">
                Overview
            </h2>
            <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
                {cards.map((card) => (
                    <div
                        key={card.name}
                        className={`overflow-hidden rounded-lg bg-white shadow cursor-pointer ${
                            selectedCard === card.name
                                ? "ring-2 ring-indigo-500"
                                : ""
                        }`}
                        onClick={() => {
                            setSelectedCard(card.name);
                        }}
                    >
                        <div className="p-5">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <card.icon
                                        className="h-6 w-6 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt className="truncate text-sm font-medium text-gray-500">
                                            {card.name}
                                        </dt>
                                        <dd>
                                            <div className="text-lg font-medium text-gray-900">
                                                {card.amount}
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DashboardCards;
