import React, { useState } from "react";
import { PhoneIcon, PlusIcon, MinusIcon } from "@heroicons/react/24/outline";

export default function IncrementButton({ onClick }) {
  const [count, setCount] = useState(0);

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  return (
    <div className="flex items-center bg-indigo-600 p-2 text-white rounded-full shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
      <div className="flex items-center">
        <div
          onClick={handleDecrement}
          className="mr-1 cursor-pointer rounded-l-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <MinusIcon className="h-4 w-4" aria-hidden="true" />
        </div>
        <PhoneIcon className="h-5 w-5" aria-hidden="true" />
        <span className="ml-1 mr-1">{count}</span>
        <div
          onClick={handleIncrement}
          className="ml-1 cursor-pointer rounded-r-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <PlusIcon className="h-4 w-4" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
}
