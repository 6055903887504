import React from "react";

function GenderSelector({ value, onChange }) {
  const genderOptions = [
    { value: "", label: "Prefer not to say" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "transgender", label: "Transgender" },
    { value: "genderqueer", label: "Genderqueer" },
    { value: "non-binary", label: "Non-binary" },
    { value: "agender", label: "Agender" },
    { value: "bigender", label: "Bigender" },
    { value: "other", label: "Other" },
  ];

  return (
    <select
      value={value}
      onChange={onChange}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
      {genderOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default GenderSelector;
