import React from "react";

const AppointmentButtonContainer = ({ appointment, onButtonClick }) => {
  const isCompletedOrTreated = appointment.history.some(
    (entry) => entry.status === "Completed" || entry.status === "Treated"
  );

  const canCancel = !isCompletedOrTreated;
  const canReschedule = !isCompletedOrTreated;
  const canNotify = !isCompletedOrTreated;
  const canCreateSOAPNote = isCompletedOrTreated;

  // Define all status transitions
  const statusTransitions = {
    // Phase 1
    Booked: ["Tentative", "Changed", "Cancelled"],
    Tentative: ["Confirmed", "Changed", "Cancelled"],
    Confirmed: ["Treated", "Changed", "Cancelled"],
    Carryover: ["Tentative", "Changed", "Cancelled"],

    // Phase 2 - Invoicing Flow
    Treated: ["Invoiced", "Soap Notes Complete"],
    Invoiced: ["Invoice Paid"],
    "Invoice Paid": ["Paid to Clinician"],
    "Paid to Clinician": ["Completed"],

    // Phase 2 - SOAP Notes Flow
    "Soap Notes Complete": ["Soap Notes Approved"],
    "Soap Notes Approved": ["Paid to Clinician"],

    // Other
    Changed: ["Tentative"],
    Cancelled: [],
    Completed: [],
  };

  // Get the latest status from history
  const getLatestStatus = (phaseStatuses) => {
    const latestStatus = appointment.history
      .filter((entry) => phaseStatuses.includes(entry.status))
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0]?.status;

    return latestStatus || null;
  };

  const completedStatuses = appointment.history.map((entry) => entry.status);

  // Determine next possible statuses based on the latest completed status for each phase
  const getNextStatuses = () => {
    const latestPhase1Status = getLatestStatus([
      "Booked",
      "Tentative",
      "Confirmed",
      "Changed",
      "Cancelled",
      "Treated",
    ]);

    const latestInvoiceStatus = getLatestStatus([
      "Invoiced",
      "Invoice Paid",
      "Paid to Clinician",
    ]);

    const latestSoapStatus = getLatestStatus([
      "Soap Notes Complete",
      "Soap Notes Approved",
    ]);

    let nextStatuses = [];

    // Phase 1: Show the next status in Phase 1 until Treated
    if (
      latestPhase1Status &&
      latestPhase1Status !== "Treated" &&
      statusTransitions[latestPhase1Status]
    ) {
      nextStatuses = [
        ...nextStatuses,
        ...statusTransitions[latestPhase1Status],
      ];
    }

    // Phase 2: After Treated, show both Invoicing and SOAP statuses
    if (latestPhase1Status === "Treated") {
      nextStatuses = [...nextStatuses, ...statusTransitions["Treated"]];
    }

    // Phase 2: Show next invoicing-related statuses based on the latest in the invoicing flow
    if (latestInvoiceStatus && statusTransitions[latestInvoiceStatus]) {
      nextStatuses = [
        ...nextStatuses,
        ...statusTransitions[latestInvoiceStatus],
      ];
    }

    // Phase 2: Show next SOAP-related statuses based on the latest SOAP status
    if (latestSoapStatus && statusTransitions[latestSoapStatus]) {
      nextStatuses = [...nextStatuses, ...statusTransitions[latestSoapStatus]];
    }

    // Filter out statuses that have already been completed
    return [...new Set(nextStatuses)].filter(
      (status) => !completedStatuses.includes(status)
    ); // Ensure no duplicates using Set
  };

  const nextStatuses = getNextStatuses();

  // Separate flows for SOAP and Invoice
  const invoiceStatuses = [
    "Invoiced",
    "Invoice Paid",
    "Paid to Clinician",
    "Completed",
  ];
  const soapStatuses = ["Soap Notes Complete", "Soap Notes Approved"];

  const nextInvoiceStatuses = nextStatuses.filter((status) =>
    invoiceStatuses.includes(status)
  );

  const nextSoapStatuses = nextStatuses.filter((status) =>
    soapStatuses.includes(status)
  );

  // Filter phase 1 statuses
  const phase1Statuses = nextStatuses.filter(
    (status) =>
      !invoiceStatuses.includes(status) && !soapStatuses.includes(status)
  );

  return (
    <div className="flex flex-col space-y-2">
      {/* Main Buttons (Cancel, Reschedule, Notify, SOAP) */}
      <div className="flex space-x-2 overflow-x-auto">
        {canCancel && (
          <button
            className="rounded-md bg-red-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible-outline-2 focus-visible-outline-offset-2 focus-visible-outline-red-600"
            onClick={() => onButtonClick("cancel")}>
            Cancel
          </button>
        )}

        {canReschedule && (
          <button
            className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible-outline-2 focus-visible-outline-offset-2 focus-visible-outline-indigo-600"
            onClick={() => onButtonClick("reschedule")}>
            Reschedule
          </button>
        )}

        {canNotify && (
          <button
            className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible-outline-2 focus-visible-outline-offset-2 focus-visible-outline-indigo-600"
            onClick={() => onButtonClick("notify")}>
            Notify
          </button>
        )}

        {canCreateSOAPNote && (
          <button
            className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible-outline-2 focus-visible-outline-offset-2 focus-visible-outline-indigo-600"
            onClick={() => onButtonClick("createSOAPNote")}>
            SOAP
          </button>
        )}
      </div>

      {/* Mark as Buttons for Phase 1 (Treated, Changed, Cancelled) */}
      {phase1Statuses.length > 0 && (
        <div className="flex space-x-2 overflow-x-auto">
          {phase1Statuses.map((status, index) => (
            <button
              key={index}
              className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible-outline-2 focus-visible-outline-offset-2 focus-visible-outline-indigo-600"
              onClick={() => onButtonClick(`status-${status}`)}>
              Mark as: {status}
            </button>
          ))}
        </div>
      )}

      {/* Mark as Buttons for Invoice flow */}
      {nextInvoiceStatuses.length > 0 && (
        <div className="flex space-x-2 overflow-x-auto">
          {nextInvoiceStatuses.map((status, index) => (
            <button
              key={index}
              className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible-outline-2 focus-visible-outline-offset-2 focus-visible-outline-indigo-600"
              onClick={() => onButtonClick(`status-${status}`)}>
              Mark as: {status}
            </button>
          ))}
        </div>
      )}

      {/* Mark as Buttons for SOAP Notes flow */}
      {nextSoapStatuses.length > 0 && (
        <div className="flex space-x-2 overflow-x-auto">
          {nextSoapStatuses.map((status, index) => (
            <button
              key={index}
              className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible-outline-2 focus-visible-outline-offset-2 focus-visible-outline-indigo-600"
              onClick={() => onButtonClick(`status-${status}`)}>
              Mark as: {status}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default AppointmentButtonContainer;
