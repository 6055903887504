import React from "react";

const ContextMenu = ({ x, y, options, onClose }) => {
  return (
    <ul
      className="absolute bg-white shadow-lg border rounded-md z-50"
      style={{ top: y, left: x }}
      onClick={onClose}
    >
      {options.map((option, index) => (
        <li
          key={index}
          className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
          onClick={option.onClick}
        >
          {option.label}
        </li>
      ))}
    </ul>
  );
};

export default ContextMenu;
