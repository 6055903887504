import React from "react";

export default function ReportButton({ 
  icon, 
  reportName, 
  onClick, 
  ariaLabel = "Report Button" 
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="relative flex items-center justify-center rounded-lg bg-indigo-600 p-3 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      aria-label={ariaLabel}
    >
      <div className="flex items-center">
        {icon && <span className="mr-2">{icon}</span>}
        <span className="text-sm font-medium">{reportName}</span>
      </div>
    </button>
  );
}
