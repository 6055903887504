import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchClinicById } from "../../store/thunks/clinicThunk";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline"; // Clinic icon

function ClinicDetails({ clinicId }) {
  console.log("ClinicDetails ID: " + clinicId);
  const dispatch = useDispatch();
  const clinic = useSelector((state) => state.clinics?.currentClinic);
  const isLoading = useSelector((state) => state.clinics?.status === "loading");
  const error = useSelector((state) => state.clinics?.error);

  useEffect(() => {
    // Check if clinic data is already in the store
    if (!clinic || clinic._id !== clinicId) {
      console.log("Dispatching fetchClinicById for ID: ", clinicId);
      dispatch(fetchClinicById(clinicId)).catch((error) => {
        console.error("Dispatch error: ", error);
      });
    }
  }, [clinicId, dispatch, clinic]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!clinic) {
    return <div>Clinic not found.</div>;
  }

  const formatHours = (hours) => {
    if (!hours || !hours.open || !hours.close) {
      return "Closed";
    }
    return `${hours.open} - ${hours.close}`;
  };

  return (
    <div className="px-4 sm:px-0">
      <div className="flex items-start justify-between">
        <h3 className="flex items-center text-base font-semibold leading-7 text-gray-900">
          <BuildingOffice2Icon className="h-5 w-5 mr-2" aria-hidden="true" />
          Clinic Information
        </h3>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          {/* Clinic Name */}
          <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Name
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
              {clinic.name}
            </dd>
          </div>
          {/* Address */}
          <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Address
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
              {clinic.address.street}, {clinic.address.city},{" "}
              {clinic.address.province} {clinic.address.postalCode},{" "}
              {clinic.address.country}
            </dd>
          </div>
          {/* Contact Details */}
          <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Phone Number
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
              {clinic.phoneNumber}
            </dd>
          </div>
          <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Email
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
              {clinic.email}
            </dd>
          </div>
          {/* Website */}
          {clinic.website && (
            <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Website
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                <a
                  href={clinic.website}
                  target="_blank"
                  rel="noopener noreferrer">
                  {clinic.website}
                </a>
              </dd>
            </div>
          )}
          {/* Hours of Operation */}
          <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Hours of Operation
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
              <ul className="space-y-1">
                {Object.entries(clinic.hoursOfOperation || {}).map(
                  ([day, hours]) => (
                    <li key={day}>
                      {day}: {formatHours(hours)}
                    </li>
                  )
                )}
              </ul>
            </dd>
          </div>
          {/* Payment Methods */}
          <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Payment Methods
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
              {clinic.paymentMethods.join(", ")}
            </dd>
          </div>
          {/* Currency & Tax Rate */}
          <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Currency
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
              {clinic.currency}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Tax Rate
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
              {clinic.taxRate * 100}%
            </dd>
          </div>
          {/* Services */}
          {clinic.servicesOffered?.length > 0 && (
            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Services Offered
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                <ul className="list-disc list-inside">
                  {clinic.servicesOffered.map((service) => (
                    <li key={service}>{service}</li>
                  ))}
                </ul>
              </dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
}

export default ClinicDetails;
