import { createSlice } from "@reduxjs/toolkit";
import { searchApi } from "../thunks/searchApiThunk";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    query: "",
    results: [],
    loading: false,
    error: null,
  },
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    clearResults(state) {
        state.results = []; // Clear the results array
        state.error = null;  // Optionally clear any errors
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchApi.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
      })
      .addCase(searchApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setQuery, clearResults } = searchSlice.actions;
export default searchSlice.reducer;
