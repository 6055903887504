// src/store/slices/todoSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { fetchTodos } from "../thunks/todoThunk"; // Updated import path

const initialState = {
  todos: {
    Treat: [],
    Soap: [],
    Confirm: [],
    Invoice: [],
    Payment: []
  },
  loading: false,
  error: null,
};

const todoSlice = createSlice({
  name: "todos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTodos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTodos.fulfilled, (state, action) => {
        state.loading = false;
        state.todos = action.payload; // Updated to match new state structure
      })
      .addCase(fetchTodos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default todoSlice.reducer;
