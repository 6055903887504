import { createSlice } from "@reduxjs/toolkit";
import {
    createService,
    fetchServices,
    fetchServiceById,
    updateService,
    deleteService,
} from "../thunks/servicesThunk";

const servicesSlice = createSlice({
    name: "services",
    initialState: {
        services: [],
        status: "idle",
        error: null,
        selectedService: null, // To store a single selected service if needed
        pagination: {
            totalServices: 0,
            currentPage: 1,
            totalPages: 1,
            pageSize: 20,
        },
    },
    reducers: {
        // your other reducers can go here
    },
    extraReducers: (builder) => {
        builder
            // Create Service
            .addCase(createService.pending, (state) => {
                state.status = "loading";
            })
            .addCase(createService.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.services.push(action.payload);
            })
            .addCase(createService.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })

            // Fetch All Services
            .addCase(fetchServices.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchServices.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.services = action.payload.services
                state.pagination = action.payload.pagination;
            })
            .addCase(fetchServices.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })

            // Fetch Service by ID
            .addCase(fetchServiceById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchServiceById.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.selectedService = action.payload;
            })
            .addCase(fetchServiceById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })

            // Update Service
            .addCase(updateService.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateService.fulfilled, (state, action) => {
                state.status = "succeeded";
                const index = state.services.findIndex(
                    (service) => service._id === action.payload._id
                );
                if (index !== -1) {
                    state.services[index] = action.payload;
                }
            })
            .addCase(updateService.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })

            // Delete Service
            .addCase(deleteService.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteService.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.services = state.services.filter(
                    (service) => service._id !== action.meta.arg
                );
            })
            .addCase(deleteService.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export default servicesSlice.reducer;
