import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { searchApi } from "../../store/thunks/searchApiThunk";
import { setQuery, clearResults } from "../../store/slices/searchApiSlice"; // Import the clear action

const SearchDropdown = ({
  displayFields,
  onSelection,
  prefix,
  placeholder,
  filter,
  initialSelection,
}) => {
  const dispatch = useDispatch();
  const { results = [], loading, error } = useSelector((state) => state.search);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState(initialSelection || null);
  const [isOpen, setIsOpen] = useState(false);

  // Clear search results on mount
  useEffect(() => {
    dispatch(clearResults()); // Clear results when the component first loads
  }, [dispatch]);

  // Trigger search when search query changes
  useEffect(() => {
    if (searchQuery) {
      const prefixedQuery = prefix ? `${prefix}${searchQuery}` : searchQuery;
      dispatch(setQuery(prefixedQuery));
      dispatch(searchApi(prefixedQuery));
    } else {
      // Clear search results if query is cleared
      dispatch(clearResults());
    }
  }, [searchQuery, prefix, dispatch]);

  // Handle initial selection if provided
  useEffect(() => {
    if (initialSelection) {
      setSelectedItem(initialSelection);
      setSearchQuery(
        displayFields
          .map((field) => getFieldDisplay(initialSelection, field))
          .join(", ")
      ); // Initialize search query with initial selection
    }
  }, [initialSelection, displayFields]);

  // Handle selection from search results
  const handleSelection = (item) => {
    setSelectedItem(item);
    setSearchQuery(
      displayFields.map((field) => getFieldDisplay(item, field)).join(", ")
    );
    setIsOpen(false);
    if (onSelection) {
      onSelection(item);
    }
  };

  // Clear previous results and search history when starting a new search
  const handleNewSearch = (value) => {
    setSearchQuery(value);
    setSelectedItem(null); // Clear selected item
    dispatch(clearResults()); // Clear previous results
    setIsOpen(true); // Open dropdown on new search
  };

  const getFieldDisplay = (item, field) => {
    if (field === "client.fullName") {
      return item.client
        ? `${item.client.firstName || ""} ${item.client.lastName || ""}`.trim()
        : "N/A";
    }

    if (field === "clinician.fullName") {
      return item.clinician
        ? `${item.clinician.firstName || ""} ${
            item.clinician.lastName || ""
          }`.trim()
        : "N/A";
    }

    if (field === "phoneNumbers" && Array.isArray(item.phoneNumbers)) {
      return item.phoneNumbers
        .map((phone) => `${phone.type}: ${phone.number}`)
        .join(", ");
    }

    const fieldParts = field.split(".");
    let value = item;

    for (const part of fieldParts) {
      if (!value || typeof value !== "object") {
        return "";
      }
      value = value[part];
    }

    return value || "";
  };

  const filteredResults = filter ? results.filter(filter) : results || [];

  return (
    <Listbox as="div" value={selectedItem} onChange={handleSelection}>
      {({ open }) => (
        <div className="">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => handleNewSearch(e.target.value)} // Clear history on new search
            onClick={() => setIsOpen(true)} // Ensure dropdown opens on click
            className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
            placeholder={placeholder}
          />

          {/* Dropdown for Search Results */}
          <Transition
            show={isOpen && filteredResults.length > 0} // Show dropdown only when results exist
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 w-full max-h-60 overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {loading && (
                <div className="py-2 pl-3 pr-9 text-gray-500">Loading...</div>
              )}
              {filteredResults.map((item) => (
                <Listbox.Option
                  key={item._id}
                  value={item}
                  className={({ active }) =>
                    `cursor-default select-none py-2 pl-3 pr-9 ${
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    }`
                  }
                  onClick={() => handleSelection(item)}
                >
                  <div className="flex">
                    <span className="font-normal truncate">
                      {displayFields
                        .map((field) => getFieldDisplay(item, field))
                        .join(", ")}
                    </span>
                  </div>
                  {selectedItem &&
                    displayFields.every(
                      (field) =>
                        getFieldDisplay(selectedItem, field) ===
                        getFieldDisplay(item, field)
                    ) && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                </Listbox.Option>
              ))}
              {error && (
                <div className="py-2 pl-3 pr-9 text-red-500">
                  Error: {error.message}
                </div>
              )}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default SearchDropdown;
