import React, { useState } from "react";
import SOAPForm from "../../applicationUi/components/SOAPForm";

function FormsPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSaveSOAP = (soapData) => {
    console.log("Saved SOAP Data:", soapData);
    // Handle saving the SOAP data to your backend or state
  };

  return (
    <div className="App">
      <button
        onClick={() => setIsModalOpen(true)}
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        Open SOAP Form
      </button>

      {isModalOpen && (
        <SOAPForm
          onSave={handleSaveSOAP}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
}

export default FormsPage;
