import React, { useState } from "react";
import QuickBooksConnectButton from "./QuickBooksConnectButton";
import QuickBooksSuccess from "./QuickBookSuccess";
import UploadClients from "./UploadClients";
import UploadSchedule from "./UploadSchedule";
import QuickBooksInvoices from "./QuickBooksInvoices";
import QuickBooksCashFlow from "./QuickBookCashFlow";
import QuickBooksSalesReport from "./QuickBookSalesReport";
import UploadProducts from "./UploadProducts";
import UploadAppointments from "./UploadAppointments";
import UploadServices from "./UploadServices";
function QuickBooks() {
    const [selectedModule, setSelectedModule] = useState();

    const handleModuleClick = (moduleName) => {
        setSelectedModule(moduleName); // Notify the parent component of the clicked module
    };

    return (
        <>
            <div className="container mx-auto mt-5 p-4 bg-white rounded-lg shadow-lg text-center">
                <h1 className="text-2xl font-semibold mb-4">
                    QuickBooks Integration
                </h1>
                <QuickBooksConnectButton />
                <QuickBooksSuccess />
            </div>
            <div>
                <div className="flex justify-center gap-2">
                    <button
                        onClick={() => handleModuleClick("Cash Flow Summary")}
                        className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
                    >
                        Cash Flow Summary
                    </button>
                    <button
                        onClick={() => handleModuleClick("Sales Report")}
                        className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
                    >
                        Sales Report
                    </button>
                    <button
                        onClick={() => handleModuleClick("Invoices")}
                        className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
                    >
                        Invoices
                    </button>
                    <button
                        onClick={() => handleModuleClick("Schedule")}
                        className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
                    >
                        Schedule
                    </button>
                    <button
                        onClick={() => handleModuleClick("Clients")}
                        className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
                    >
                        Clients
                    </button>
                    <button
                        onClick={() => handleModuleClick("Products")}
                        className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
                    >
                        Products
                    </button>
                    <button
                        onClick={() => handleModuleClick("Services")}
                        className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
                    >
                        Services
                    </button>
                    <button
                        onClick={() => handleModuleClick("Appointments")}
                        className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
                    >
                        Appointments
                    </button>
                </div>
            </div>
            <div>
                {selectedModule === "Cash Flow Summary" && (
                    <QuickBooksCashFlow />
                )}
                {selectedModule === "Sales Report" && <QuickBooksSalesReport />}
                {selectedModule === "Invoices" && <QuickBooksInvoices />}
                {selectedModule === "Schedule" && <UploadSchedule />}
                {selectedModule === "Clients" && <UploadClients />} 
                {selectedModule === "Products" && <UploadProducts />} 
                {selectedModule === "Services" && <UploadServices />} 
                {selectedModule === "Appointments" && <UploadAppointments />} 
            </div>
        </>
    );
}

export default QuickBooks;
