import React from "react";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { useSelector } from "react-redux";

function QuickBooksConnectButton() {
    const token = useSelector((state) => (state.auth.token));
    const { baseURL, config } = generateConfig({ token: token });

    const initiateOAuth = async () => {
        try {
            const response = await axios.get(
                `${baseURL}/api/startQuickBooksOAuth`,
                config
            );
            if (response.data && response.data.authUrl) {
                window.location.href = response.data.authUrl;
            }
        } catch (error) {
            console.error("Failed to initiate QuickBooks OAuth", error);
        }
    };

    return (
        <button
            onClick={initiateOAuth}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
            Connect to QuickBooks
        </button>
    );
}

export default QuickBooksConnectButton;
