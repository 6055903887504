const payrollDates = [
  {
    payPeriodNumber: 1,
    payPeriodEndingDate: "December 21, 2023",
    includeDates: "Dec 8  -  Dec 21",
    submissionDate: "December 26, 2023",
    paymentDate: "January 5, 2024",
  },
  {
    payPeriodNumber: 2,
    payPeriodEndingDate: "January 4, 2024",
    includeDates: "Dec 22 - Jan 4",
    submissionDate: "January 9, 2024",
    paymentDate: "January 19, 2024",
  },
  {
    payPeriodNumber: 3,
    payPeriodEndingDate: "January 18, 2024",
    includeDates: "Jan 5 - Jan 18",
    submissionDate: "January 23, 2024",
    paymentDate: "February 2, 2024",
  },
  {
    payPeriodNumber: 4,
    payPeriodEndingDate: "February 1, 2024",
    includeDates: "Jan 19 - Feb 1",
    submissionDate: "February 6, 2024",
    paymentDate: "February 16, 2024",
  },
  {
    payPeriodNumber: 5,
    payPeriodEndingDate: "February 15, 2024",
    includeDates: "Feb 2 - Feb 15",
    submissionDate: "February 20, 2024",
    paymentDate: "March 1, 2024",
  },
  {
    payPeriodNumber: 6,
    payPeriodEndingDate: "February 29, 2024",
    includeDates: "Feb 16 - Feb 29",
    submissionDate: "March 5, 2024",
    paymentDate: "March 15, 2024",
  },
  {
    payPeriodNumber: 7,
    payPeriodEndingDate: "March 14, 2024",
    includeDates: "Mar 1 - Mar 14",
    submissionDate: "March 19, 2024",
    paymentDate: "March 28, 2024",
  },
  {
    payPeriodNumber: 8,
    payPeriodEndingDate: "March 28, 2024",
    includeDates: "Mar 15 - Mar 28",
    submissionDate: "April 2, 2024",
    paymentDate: "April 12, 2024",
  },
  {
    payPeriodNumber: 9,
    payPeriodEndingDate: "April 11, 2024",
    includeDates: "Mar 29 - Apr 11",
    submissionDate: "April 16, 2024",
    paymentDate: "April 26, 2024",
  },
  {
    payPeriodNumber: 10,
    payPeriodEndingDate: "April 25, 2024",
    includeDates: "Apr 12 - Apr 25",
    submissionDate: "April 30, 2024",
    paymentDate: "May 10, 2024",
  },
  {
    payPeriodNumber: 11,
    payPeriodEndingDate: "May 9, 2024",
    includeDates: "Apr 26 - May 9",
    submissionDate: "May 14, 2024",
    paymentDate: "May 24, 2024",
  },
  {
    payPeriodNumber: 12,
    payPeriodEndingDate: "May 23, 2024",
    includeDates: "May 10 - May 23",
    submissionDate: "May 28, 2024",
    paymentDate: "June 7, 2024",
  },
  {
    payPeriodNumber: 13,
    payPeriodEndingDate: "June 6, 2024",
    includeDates: "May 24 - Jun 6",
    submissionDate: "June 11, 2024",
    paymentDate: "June 21, 2024",
  },
  {
    payPeriodNumber: 14,
    payPeriodEndingDate: "June 20, 2024",
    includeDates: "Jun 7 - Jun 20",
    submissionDate: "June 25, 2024",
    paymentDate: "July 5, 2024",
  },
  {
    payPeriodNumber: 15,
    payPeriodEndingDate: "July 4, 2024",
    includeDates: "Jun 21 - Jul 4",
    submissionDate: "July 9, 2024",
    paymentDate: "July 19, 2024",
  },
  {
    payPeriodNumber: 16,
    payPeriodEndingDate: "July 18, 2024",
    includeDates: "Jul 5 - Jul 18",
    submissionDate: "July 23, 2024",
    paymentDate: "August 2, 2024",
  },
  {
    payPeriodNumber: 17,
    payPeriodEndingDate: "August 1, 2024",
    includeDates: "Jul 19 - Aug 1",
    submissionDate: "August 6, 2024",
    paymentDate: "August 16, 2024",
  },
  {
    payPeriodNumber: 18,
    payPeriodEndingDate: "August 15, 2024",
    includeDates: "Aug 2 - Aug 15",
    submissionDate: "August 20, 2024",
    paymentDate: "August 30, 2024",
  },
  {
    payPeriodNumber: 19,
    payPeriodEndingDate: "August 29, 2024",
    includeDates: "Aug 16 - Aug 29",
    submissionDate: "September 3, 2024",
    paymentDate: "September 13, 2024",
  },
  {
    payPeriodNumber: 20,
    payPeriodEndingDate: "September 12, 2024",
    includeDates: "Aug 30 - Sept 12",
    submissionDate: "September 17, 2024",
    paymentDate: "September 27, 2024",
  },
  {
    payPeriodNumber: 21,
    payPeriodEndingDate: "September 26, 2024",
    includeDates: "Sept 13 - Sept 26",
    submissionDate: "October 1, 2024",
    paymentDate: "October 11, 2024",
  },
  {
    payPeriodNumber: 22,
    payPeriodEndingDate: "October 10, 2024",
    includeDates: "Sept 27 - Oct 10",
    submissionDate: "October 15, 2024",
    paymentDate: "October 25, 2024",
  },
  {
    payPeriodNumber: 23,
    payPeriodEndingDate: "October 24, 2024",
    includeDates: "Oct 11 - Oct 24",
    submissionDate: "October 29, 2024",
    paymentDate: "November 8, 2024",
  },
  {
    payPeriodNumber: 24,
    payPeriodEndingDate: "November 7, 2024",
    includeDates: "Oct 25 - Nov 7",
    submissionDate: "November 12, 2024",
    paymentDate: "November 22, 2024",
  },
  {
    payPeriodNumber: 25,
    payPeriodEndingDate: "November 21, 2024",
    includeDates: "Nov 8 - Nov 21",
    submissionDate: "November 26, 2024",
    paymentDate: "December 6, 2024",
  },
  {
    payPeriodNumber: 26,
    payPeriodEndingDate: "December 5, 2024",
    includeDates: "Nov 22 - Dec 5",
    submissionDate: "December 10, 2024",
    paymentDate: "December 20, 2024",
  },
];

export default payrollDates;
