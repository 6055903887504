import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createClinician } from "../store/thunks/cliniciansThunk";

export default function QuickAddClinicianModal({ onClose }) {
  const dispatch = useDispatch();
  const [clinicianData, setClinicianData] = useState({
    firstName: "",
    lastName: "",
    initial: "",
    title: "",
    qualifications: "",
    address: {
      aptNumber: "",
      street: "",
      city: "",
      province: "",
      postalCode: "",
    },
    clinicName: "",
    email: "",
    phone: "",
  });

  const handleSave = async (event) => {
    event.preventDefault();
    dispatch(createClinician(clinicianData));
    onClose();
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setClinicianData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setClinicianData((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [name]: value,
      },
    }));
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const clinicianFields = [
    { name: "firstName", label: "First Name", type: "text" },
    { name: "lastName", label: "Last Name", type: "text" },
    { name: "initial", label: "Initial", type: "text" },
    { name: "title", label: "Title", type: "text" },
    { name: "qualifications", label: "Qualifications", type: "text" },
    { name: "clinicName", label: "Clinic Name", type: "text" },
    { name: "email", label: "Email", type: "email" },
    { name: "phone", label: "Phone", type: "text" },
  ];

  const addressFields = [
    { name: "aptNumber", label: "Apt Number", type: "text" },
    { name: "street", label: "Street", type: "text" },
    { name: "city", label: "City", type: "text" },
    { name: "province", label: "Province", type: "text" },
    { name: "postalCode", label: "Postal Code", type: "text" },
  ];

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
      <div className="fixed inset-0 bg-gray-900 opacity-50" onClick={onClose} />
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4 relative z-10 overflow-y-auto max-h-[90vh]">
        <form onSubmit={handleSave}>
          {/* Header */}
          <div className="border-b border-gray-900/10 pb-6 mb-6">
            <h2 className="text-lg font-semibold leading-7 text-gray-900">
              Quick Add Clinician
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Use this form to quickly add a clinician with basic information.
            </p>
          </div>

          {/* Clinician Information */}
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Clinician Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Input the clinician's information.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
              {clinicianFields.map((field) => (
                <div key={field.name} className="sm:col-span-2">
                  <label
                    htmlFor={field.name}
                    className="block text-sm font-medium leading-6 text-gray-900">
                    {field.label}
                  </label>
                  <div className="mt-2">
                    <input
                      type={field.type}
                      name={field.name}
                      id={field.name}
                      value={clinicianData[field.name]}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              ))}

              {addressFields.map((field) => (
                <div key={field.name} className="sm:col-span-2">
                  <label
                    htmlFor={field.name}
                    className="block text-sm font-medium leading-6 text-gray-900">
                    {field.label}
                  </label>
                  <div className="mt-2">
                    <input
                      type={field.type}
                      name={field.name}
                      id={field.name}
                      value={clinicianData.address[field.name]}
                      onChange={handleAddressChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Save and Cancel Buttons */}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={onClose}
              className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
