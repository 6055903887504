import UserCards from "../../../applicationUi/components/UserCards";
import AppointmentList from "../../../applicationUi/components/AppointmentList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAppointmentsByClinicianId } from "../../../store/thunks/appointmentsThunk";
import ClinicianToDoList from "./ClinicianToDoList";
import { fetchTodos } from "../../../store/thunks/todoThunk";

export default function ClinicianDashboardComponent({
  clinician,
  handleClientClick,
  handleAppointmentClick,
  handleReschedule, // Add this prop
}) {
  const dispatch = useDispatch();
  const appointments = useSelector(
    (state) => state.appointments.appointments || []
  );
  // const { clinician: clinicianDetails, appointments: appointmentData } =
  //   useSelector(
  //     (state) => state.appointments || { clinician: {}, appointments: [] }
  //   );

  // const { appointments } = appointmentData;
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [refresh, setRefresh] = useState(false);

useEffect(() => {
    if (clinician?._id) {
        dispatch(fetchTodos({ userRole: "clinician", clinicianId: clinician._id }));
    }
}, [dispatch, refresh, clinician]);

  useEffect(() => {
    if (appointments) {
      setFilteredAppointments(appointments);
    }
  }, [appointments]);

  useEffect(() => {
    setFilteredAppointments(appointments);
  }, [appointments]);

  const handleFilterAppointments = (filterFunction) => {
    setFilteredAppointments(filterFunction(appointments));
  };

  useEffect(() => {
    dispatch(fetchAppointmentsByClinicianId(clinician._id));
  }, [dispatch, clinician, refresh]);

  const handleWideSidePanelClose = () => {
    setRefresh(prevRefresh => !prevRefresh);
}

  return (
    <div className="grid grid-cols-3 gap-6 pb-20">
      {/* Left Column */}
      <div className="col-span-2">
        {/* User Cards */}
        <UserCards
          appointments={appointments}
          onFilterAppointments={handleFilterAppointments}
        />

        {/* Appointment List aka Recent Activity */}
        <AppointmentList
          appointments={filteredAppointments}
          onAppointmentClick={handleAppointmentClick}
          onClientClick={handleClientClick}
          onReschedule={handleReschedule} // Pass handleReschedule to AppointmentList
        />
      </div>

      {/* Right Column */}
      <div className="col-span-1">
        <ClinicianToDoList
          onReschedule={handleReschedule} // Pass handleReschedule to ClinicianToDoList
          onWideSidePanelClose={handleWideSidePanelClose}
        />
      </div>
    </div>
  );
}
