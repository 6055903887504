import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const createProduct = createAsyncThunk(
    "products/createProduct",
    async (productData, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.post(
                `${baseURL}/api/products`,
                productData,
                config
            );
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

export const fetchProducts = createAsyncThunk(
    "products/fetchProducts",
    async (_, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.get(`${baseURL}/api/products`, config);
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

export const fetchProductById = createAsyncThunk(
    "products/fetchProductById",
    async (id, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });
            const response = await axios.get(
                `${baseURL}/api/products/${id}`,
                config
            );
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

export const fetchProductByCatalogCode = createAsyncThunk(
    "products/fetchProductByCatalogCode",
    async (catalogCode, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.get(
                `${baseURL}/api/products/catalog/${catalogCode}`,
                config
            );
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

export const updateProduct = createAsyncThunk(
    "products/updateProduct",
    async ({ id, productData }, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.put(
                `${baseURL}/api/products/${id}`,
                productData,
                config
            );
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

export const deleteProduct = createAsyncThunk(
    "products/deleteProduct",
    async (id, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.delete(
                `${baseURL}/api/products/${id}`,
                config
            );
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);
