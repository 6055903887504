import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createPayment } from "../store/thunks/paymentThunks";
import InvoiceGenerator from "../applicationUi/components/InvoiceGenerator";

const InvoicePaymentModal = ({ onClose, invoice }) => {
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(invoice?.amount || 0);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleSavePayment = () => {
    if (!paymentMethod || paymentAmount <= 0) {
      setError("Both payment method and payment amount are required.");
      return;
    }

    // Dispatch the createPayment thunk
    dispatch(
      createPayment({
        invoiceId: invoice?._id,
        paymentMethod,
        paymentAmount,
      })
    );
    // Close the modal after saving
    onClose();
  };

  // Determine if the invoice is a parent or a child based on the invoiceId suffix
  const isParentInvoice = invoice?.invoiceId?.endsWith("B");
  const isChildInvoice = invoice?.invoiceId?.endsWith("A");

  // Derive the sibling invoice ID for display (switch between A and B)
  const siblingInvoiceId = isParentInvoice
    ? `${invoice?.invoiceId?.slice(0, -1)}A` // Change last character to 'A'
    : `${invoice?.invoiceId?.slice(0, -1)}B`; // Change last character to 'B'

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white rounded-lg shadow-lg max-w-5xl w-full p-6 relative">
        <h2 className="text-base font-semibold leading-7 text-gray-900 mb-4">
          Invoice Payment
        </h2>

        {/* Two-column grid layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Left Column: Invoice Summary */}
          <div>
            <InvoiceGenerator data={invoice} />
          </div>

          {/* Right Column: Payment Details and Client Information */}
          <div className="flex flex-col justify-between h-full">
            <div>
              {/* Client Information */}
              <div className="mb-6">
                <h3 className="text-sm font-medium leading-6 text-gray-900">
                  Client: {invoice?.client?.firstName}{" "}
                  {invoice?.client?.lastName}
                </h3>
                <p className="text-sm text-gray-600">
                  Email:{" "}
                  {invoice?.client?.email ? invoice?.client?.email : "N/A"}
                </p>
                {invoice?.client?.phoneNumbers?.length > 0 && (
                  <p className="text-sm text-gray-600">
                    Phone: {invoice?.client?.phoneNumbers[0]?.number}
                  </p>
                )}
              </div>

              {/* Invoice Details */}
              <div className="mb-6">
                <h4 className="text-sm font-medium leading-6 text-gray-900">
                  Invoice Details
                </h4>
                <p className="text-sm text-gray-600">
                  Amount: ${invoice?.amount?.toFixed(2)}
                </p>
                <p className="text-sm text-gray-600">
                  Status:{" "}
                  {invoice?.status
                    ? invoice?.status.charAt(0).toUpperCase() +
                      invoice?.status.slice(1)
                    : "N/A"}
                </p>
                <p className="text-sm text-gray-600">
                  Service:{" "}
                  {invoice?.services
                    ?.map((service) => service?.description)
                    ?.join(", ")}
                </p>
              </div>

              {/* Split Billing Info */}
              {invoice?.splitBilling?.isSplit && (
                <div className="mb-6">
                  {isParentInvoice ? (
                    <p className="text-sm text-gray-600">
                      This is a Parent invoice in a split bill.
                    </p>
                  ) : (
                    <p className="text-sm text-gray-600">
                      This is a Child invoice in a split bill.
                    </p>
                  )}
                  <p className="text-sm text-gray-600">
                    {isParentInvoice
                      ? `Child invoice ID is: ${siblingInvoiceId}`
                      : `Parent invoice ID is: ${siblingInvoiceId}`}
                  </p>
                </div>
              )}
            </div>

            {/* Payment Method and Payment Amount at the bottom */}
            <div className="mt-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                {/* Payment Methods */}
                <div className="sm:col-span-1">
                  <label
                    htmlFor="payment-method"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Payment Method
                  </label>
                  <div className="mt-2">
                    <select
                      id="payment-method"
                      name="payment-method"
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <option value="">Select Payment Method</option>
                      <option value="mastercard-in-person">
                        Mastercard (In-Person)
                      </option>
                      <option value="mastercard-online">
                        Mastercard (Online)
                      </option>
                      <option value="mastercard-send-link">
                        Mastercard (Send Payment Link)
                      </option>
                      <option value="visa-in-person">Visa (In-Person)</option>
                      <option value="visa-online">Visa (Online)</option>
                      <option value="visa-send-link">
                        Visa (Send Payment Link)
                      </option>
                      <option value="debit-in-person">Debit (In-Person)</option>
                      <option value="email-money-transfer">
                        Email Money Transfer
                      </option>
                      <option value="direct-deposit">Direct Deposit</option>
                    </select>
                  </div>
                </div>

                {/* Payment Amount */}
                <div className="sm:col-span-1">
                  <label
                    htmlFor="payment-amount"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Payment Amount
                  </label>
                  <div className="mt-2">
                    <input
                      id="payment-amount"
                      name="payment-amount"
                      type="number"
                      step="0.01"
                      value={paymentAmount}
                      onChange={(e) => setPaymentAmount(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Error message */}
        {error && <div className="text-sm text-red-600 mt-4">{error}</div>}

        {/* Action Buttons */}
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={onClose}
            className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSavePayment}
            disabled={!paymentMethod || paymentAmount <= 0} // Disable the button if conditions aren't met
            className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
              !paymentMethod || paymentAmount <= 0
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            }`}>
            Mark payment as received
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoicePaymentModal;
