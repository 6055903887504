import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveAppointment,
  fetchAppointmentsByClinicianId,
  notifyClientByEmail,
  updateAppointmentStatus,
} from "../../store/thunks/appointmentsThunk";
import CancelAppointmentModal from "./CancelAppointmentModal";
import SOAPFormModal from "./SOAPForm";
import AppointmentButtonContainer from "./AppointmentButtonContainer";

export default function TwoColumnDescriptionList({
  type,
  appointment,
  futureappointment,
  client,
  onReschedule,
  refetchAppointments,
  onAppointmentCancel,
  onEmailSent,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  console.log("client", client);
  const [showSOAPForm, setShowSOAPForm] = useState(false);
  const [soapFormAppointment, setSoapFormAppointment] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (type === "client" && !client) {
    return <p>No client information available.</p>;
  }

  if (type === "appointment" && !appointment) {
    return <p>No appointment information available.</p>;
  }

  const refetchAppointment = (clinicianId) => {
    dispatch(fetchAppointmentsByClinicianId(clinicianId));
  };

  const handleButtonClick = (action) => {
    if (action.startsWith("status-")) {
      const nextStatus = action.split("status-")[1];
      handleNextStatus(nextStatus);
    } else {
      switch (action) {
        case "cancel":
          handleCancel();
          break;
        case "reschedule":
          handleReschedule();
          break;
        case "notify":
          handleNotifyClient();
          break;
        case "createSOAPNote":
          handleCreateSOAPNote();
          break;
        default:
          console.warn(`Unknown button action: ${action}`);
      }
    }
  };

  const handleNextStatus = (nextStatus) => {
    if (nextStatus) {
      dispatch(
        updateAppointmentStatus({
          appointmentId: appointment._id,
          status: nextStatus,
          comments: `Status changed to ${nextStatus}`,
          userId: user._id,
        })
      )
        .then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            console.log(`Appointment status changed to ${nextStatus}`);
            refetchAppointments(appointment.clinician._id);
          }
        })
        .catch((error) => {
          console.error(`Failed to change appointment status:`, error);
        });
    } else {
      console.log("No further status available");
    }
  };

  const handleCreateSOAPNote = () => {
    console.log("Opening SOAP form");
    setSoapFormAppointment(appointment);
    setShowSOAPForm(true);
  };

  const handleLogAppointment = () => {
    console.log("Logging appointment", appointment);
  };

  const handleFetchFutureAppointments = () => {
    console.log("Fetching future appointments");
  };

  const handleCancel = async () => {
    if (!appointment) return;

    const validStatus = "Cancelled";

    const updatedSoapNotes = appointment.soapNote.map((note) => ({
      ...note,
      createdBy: note.createdBy || user._id,
    }));

    const updatedAppointment = {
      ...appointment,
      status: [validStatus],
      soapNote: updatedSoapNotes,
      history: [
        ...appointment.history,
        {
          status: validStatus,
          timestamp: new Date(),
          comments: "Appointment cancelled",
          user: user._id,
        },
      ],
    };

    try {
      await dispatch(saveAppointment(updatedAppointment)).unwrap();
      console.log("Appointment cancelled:", updatedAppointment);
      refetchAppointment(appointment.clinician._id); // This calls the function above
      refetchAppointments(); // This calls the callback function
      onAppointmentCancel(); // Trigger the callback to refetch the appointment
      setIsModalOpen(false);
    } catch (error) {
      console.error("Failed to cancel appointment:", error);
    }
  };

  const handleModalClose = () => {
    console.log("Handle Modal Close - Two Column");
    setShowSOAPForm(false);
  };

  const handleCancelAppointment = () => {
    setIsModalOpen(true);
  };

  const handleReschedule = () => {
    onReschedule(appointment);
    refetchAppointments(appointment.clinician._id);
  };

  // const handleNotifyClient = () => {
  //   dispatch(notifyClientByEmail(appointment));
  //   refetchAppointments(appointment.clinician._id);
  //   onEmailSent();
  // };

  const handleNotifyClient = () => {
    dispatch(notifyClientByEmail({ appointment, user }));
    refetchAppointments(appointment.clinician._id);
    onEmailSent();
  };

  function calculateAge(dob) {
    if (!dob) return "N/A";
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return isNaN(age) ? "N/A" : age;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      timeZone: "UTC", // Force UTC time zone to avoid local time zone shifts
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatPrice = (price) => {
    if (typeof price !== "number" || isNaN(price)) {
      return "$0.00";
    }
    return `$${price.toFixed(2)}`;
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const timeOptions = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return `${date.toLocaleDateString(
      "en-US",
      options
    )}, ${date.toLocaleTimeString("en-US", timeOptions)}`;
  };

  return (
    <div className="border border-gray-200 rounded-md px-4 py-4">
      <div>
        <div className="flex items-center justify-between px-2s">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {type === "client"
              ? "Client Info"
              : type === "appointment"
              ? "Appointment Info"
              : "Future Appointment Info"}
          </h3>
        </div>

        <div>
          <dl
            className={`grid gap-x-2 ${
              type === "client"
                ? "grid-cols-1 sm:grid-cols-3"
                : "grid-cols-1 sm:grid-cols-2"
            }`}></dl>
        </div>

        <div className="overflow-x-auto mt-2">
          <div className="flex flex-nowrap px-2 sm:px-0 space-x-2">
            {/* Button container */}
            {(type === "appointment" || type === "futureappointment") && (
              <AppointmentButtonContainer
                appointment={appointment}
                onButtonClick={handleButtonClick}
              />
            )}
          </div>
        </div>
      </div>

      <div className="mt-2">
        <dl
          className={`grid gap-x-2 ${
            type === "client"
              ? "grid-cols-1 sm:grid-cols-3"
              : "grid-cols-1 sm:grid-cols-2"
          }`}>
          {type === "client" && client && (
            <>
              <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Client Name
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">{`${client.firstName} ${client.lastName}`}</dd>
              </div>
              <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Email
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                  {client.email}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Phone Numbers
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                  {client.phoneNumbers && client.phoneNumbers.length > 0
                    ? client.phoneNumbers.map((phone) => (
                        <div key={phone._id}>
                          {phone.number} ({phone.type})
                        </div>
                      ))
                    : "N/A"}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Date of Birth
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                  {formatDate(client.dateOfBirth)}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Age
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                  {calculateAge(client.dateOfBirth)}
                </dd>
              </div>
            </>
          )}
          {(type === "appointment" || type === "futureappointment") &&
            appointment && (
              <>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Service
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {appointment.title}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Cost
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {formatPrice(appointment.service.price)}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Date & Time
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {formatDateTime(appointment.start)}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Notes/Remarks
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1 overflow-x-auto">
                    {appointment.notes && appointment.notes.trim()
                      ? appointment.notes
                      : "N/A"}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Status
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {appointment.status}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Block Information
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {appointment.blockInfo ? (
                      <div>
                        Block appointment #{appointment.blockInfo.blockNumber} /{" "}
                        {appointment.blockInfo.totalSessions}
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </dd>
                </div>

                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Treating Clinician
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {`${appointment.clinician.firstName} ${appointment.clinician.lastName}`}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Supervising Clinician
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {client.supervisingClinician.current
                      ? `${client.supervisingClinician.current.firstName} ${client.supervisingClinician.current.lastName}`
                      : "N/A"}
                  </dd>
                </div>
              </>
            )}
        </dl>
      </div>
      <CancelAppointmentModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleCancel}
      />
      {showSOAPForm && soapFormAppointment && (
        <SOAPFormModal
          onClose={handleModalClose}
          appointment={soapFormAppointment}
        />
      )}
    </div>
  );
}
