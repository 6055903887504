import React, { useState } from "react";
import Pagination from "./Pagination";
import SearchDropdown from "./SearchDropdown";

const PayrollTable = ({
  columns,
  data = [],
  onRowClick,
  actions = [],
  title,
  description,
  description2,
  showPagination,
  currentPage,
  totalPages,
  onPageChange,
}) => {
  // Helper function to get nested property
  const getNestedProperty = (obj, path) => {
    return path
      .split(/[\.\[\]]/)
      .filter(Boolean)
      .reduce((acc, part) => acc && acc[part], obj);
  };

  return (
    <div className="px-4 py-4 sm:px-6 lg:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {title}
          </h1>
          <p className="mt-2 text-sm text-gray-700">{description}</p>
          <p className="mt-2 text-sm text-gray-700">{description2}</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex space-x-2">
          {actions.map((action, index) => (
            <button
              key={index}
              type="button"
              onClick={action.onClick}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              {action.label}
            </button>
          ))}
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-4">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-4">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map((column) => (
                      <th
                        key={column.header}
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {column.header}
                      </th>
                    ))}
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.length === 0 && (
                    <tr>
                      <td
                        colSpan={columns.length + 1}
                        className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                        No appointments available.
                      </td>
                    </tr>
                  )}
                  {data.map((item) => (
                    <tr
                      key={item._id}
                      onClick={() => onRowClick(item)}
                      className="cursor-pointer hover:bg-gray-100">
                      {columns.map((column) => (
                        <td
                          key={column.header}
                          className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {typeof column.accessor === "function"
                            ? column.accessor(item)
                            : getNestedProperty(item, column.accessor)}
                        </td>
                      ))}
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <span className="text-indigo-600 hover:text-indigo-900">
                          Edit
                          <span className="sr-only">, {item._id}</span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {showPagination && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollTable;
