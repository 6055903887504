// ClinicianInfo.jsx 

import React from 'react';

function ClinicianInfo({ clinician }) {  // Accept clinician data as a prop

  return (
    <div className="flex flex-col items-center justify-center w-full p-2 border-b border-gray-200 border-l-2">
      <h2 className="text-lg font-semibold">{clinician.firstName} {clinician.lastName} </h2>
      <p className="text-sm text-gray-500">{clinician.qualifications || clinician.credentials || "Clinician"}</p> 
    </div>
  );
}

export default ClinicianInfo;
