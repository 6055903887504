import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import GenderSelector from "../../applicationUi/components/GenderSelector";
import GoogleMapsAutocomplete from "../../applicationUi/components/GoogleAutoComplete";
import SearchDropdown from "../../applicationUi/components/SearchDropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchClinicianById } from "../../store/thunks/cliniciansThunk";
import { updateClient } from "../../store/thunks/clientsThunk";

export default function ClientDetails({ client }) {
  const dispatch = useDispatch();
  const [treatingClinician, setTreatingClinician] = useState(null);
  const [supervisingClinician, setSupervisingClinician] = useState(null);

  // Fetch clinician data
  useEffect(() => {
    if (client.treatingClinician?.current) {
      dispatch(fetchClinicianById(client.treatingClinician.current)).then(
        (response) => {
          if (response.meta.requestStatus === "fulfilled") {
            setTreatingClinician(response.payload);
          }
        }
      );
    }

    if (client.supervisingClinician?.current) {
      dispatch(fetchClinicianById(client.supervisingClinician.current)).then(
        (response) => {
          if (response.meta.requestStatus === "fulfilled") {
            setSupervisingClinician(response.payload);
          }
        }
      );
    }
  }, [client, dispatch]);

  const [formData, setFormData] = useState({
    clientId: client?.clientId ?? "",
    firstName: client?.firstName ?? "",
    lastName: client?.lastName ?? "",
    dateOfBirth: client?.dateOfBirth
      ? new Date(client.dateOfBirth).toISOString().split("T")[0]
      : "", // Store as YYYY-MM-DD format
    email: client?.email ?? "",
    healthCardNumber: client?.healthCardNumber ?? "",
    gender: client?.gender ?? "",
    QBOClientId: client?.QBOClientId ?? "",
    address: {
      aptUnit: client?.address?.aptUnit ?? "",
      streetNumber: client?.address?.streetNumber ?? "",
      streetName: client?.address?.streetName ?? "",
      city: client?.address?.city ?? "",
      province: client?.address?.province ?? "",
      postalCode: client?.address?.postalCode ?? "",
      country: client?.address?.country ?? "Canada",
    },
    phoneNumbers: client?.phoneNumbers ?? [
      { type: "Home", number: "" },
      { type: "Work", number: "" },
      { type: "Mobile", number: "" },
    ],
    treatingClinician: client?.treatingClinician ?? "",
    supervisingClinician: client?.supervisingClinician,
  });

  // State to store the original data for reset on Cancel
  const [originalData, setOriginalData] = useState({ ...client });
  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address,
        [name]: value,
      },
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      dateOfBirth: date,
    }));
  };

  const handleCancel = () => {
    setFormData({ ...originalData });
    setIsEditing(!isEditing);
  };

  const handleSave = async (event) => {
    event.preventDefault();

    const clientData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      dateOfBirth: formData.dateOfBirth,
      type: client.type,
      address: {
        aptUnit: formData.address.aptUnit,
        streetNumber: formData.address.streetNumber,
        streetName: formData.address.streetName,
        city: formData.address.city,
        province: formData.address.province,
        postalCode: formData.address.postalCode,
      },
      healthCardNumber: formData.healthCardNumber,
      gender: formData.gender,
      phoneNumbers: formData.phoneNumbers,
      treatingClinician: {
        current: treatingClinician?._id,
        history: [
          ...client.treatingClinician.history,
          { clinician: treatingClinician?._id, date: new Date() },
        ],
      },
      supervisingClinician: {
        current: supervisingClinician?._id,
        history: [
          ...client.supervisingClinician.history,
          { clinician: supervisingClinician?._id, date: new Date() },
        ],
      },
    };

    try {
      await dispatch(updateClient({ id: client._id, clientData }));
      setIsEditing(false);
      alert("Client details saved successfully.");
    } catch (error) {
      alert("Failed to save client details. Please try again.");
    }
  };

  const handlePhoneChange = (index, e) => {
    const { name, value } = e.target;
    const updatedPhones = formData.phoneNumbers.map((phone, i) =>
      i === index ? { ...phone, [name]: value } : phone
    );
    setFormData((prevData) => ({
      ...prevData,
      phoneNumbers: updatedPhones,
    }));
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  // Function to handle the selected place from Google Maps Autocomplete
  const handlePlaceSelected = (place) => {
    const addressComponents = place.address_components;

    // Helper function to get the component by type
    const getComponent = (type) =>
      addressComponents.find((component) => component.types.includes(type))
        ?.long_name;

    // Update formData with the place details
    setFormData((prevData) => ({
      ...prevData,
      address: {
        aptUnit: getComponent("subpremise") || prevData.address.aptUnit,
        streetNumber:
          getComponent("street_number") || prevData.address.streetNumber,
        streetName: getComponent("route") || prevData.address.streetName,
        city: getComponent("locality") || prevData.address.city,
        province:
          getComponent("administrative_area_level_1") ||
          prevData.address.province,
        postalCode: getComponent("postal_code") || prevData.address.postalCode,
        country: getComponent("country") || prevData.address.country,
      },
    }));
  };

  return (
    <div className="space-y-6">
      {!isEditing && (
        <button
          type="button"
          onClick={handleEditClick}
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
          Edit
        </button>
      )}

      {isEditing && (
        <>
          <button
            type="button"
            onClick={handleSave}
            className="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
            Save
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
            Cancel
          </button>
        </>
      )}

      <div className="space-y-4">
        {/* Client ID and Clinicians */}
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="text-sm font-medium text-gray-700">
              Client ID
            </label>
            <input
              type="text"
              name="clientId"
              value={formData.clientId}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
            />
          </div>
          <div>
            <label className="text-sm font-medium text-gray-700">
              Treating Clinician
            </label>
            {isEditing ? (
              <SearchDropdown
                prefix=":user:"
                displayFields={["firstName", "lastName", "email"]}
                onSelection={(user) => setTreatingClinician(user)}
                placeholder={
                  treatingClinician
                    ? `${treatingClinician.firstName} ${treatingClinician.lastName}`
                    : "Search for a treating clinician"
                }
              />
            ) : (
              <input
                type="text"
                name="treatingClinician"
                value={
                  treatingClinician
                    ? `${treatingClinician.firstName} ${treatingClinician.lastName}`
                    : "No treating clinician assigned"
                }
                onChange={handleInputChange}
                readOnly
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            )}
          </div>
          <div>
            <label className="text-sm font-medium text-gray-700">
              Supervising Clinician
            </label>
            {isEditing ? (
              <SearchDropdown
                prefix=":user:"
                displayFields={["firstName", "lastName", "email"]}
                onSelection={(user) => setSupervisingClinician(user)}
                placeholder={
                  supervisingClinician
                    ? `${supervisingClinician.firstName} ${supervisingClinician.lastName}`
                    : "Search for a supervising clinician"
                }
              />
            ) : (
              <input
                type="text"
                name="supervisingClinician"
                value={
                  supervisingClinician
                    ? `${supervisingClinician.firstName} ${supervisingClinician.lastName}`
                    : "None assigned"
                }
                onChange={handleInputChange}
                readOnly
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            )}
          </div>
        </div>

        {/* Full Name */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="text-sm font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
            />
          </div>
          <div>
            <label className="text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
            />
          </div>
        </div>

        {/* Date of Birth, Email, Health Card */}
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm px-3 py-2"
            />
          </div>

          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Health Card #
            </label>
            <input
              type="text"
              name="healthCardNumber"
              value={formData.healthCardNumber}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm px-3 py-2"
            />
          </div>
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              DOB (dd/mm/yyyy)
            </label>
            <div className="mt-1">
              <DatePicker
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm px-3 py-2"
                selected={formData.dateOfBirth}
                onChange={handleDateChange}
                readOnly={!isEditing}
                disabled={!isEditing}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        </div>

        {/* Gender, QBOClientId */}
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Gender
            </label>
            <div className="mt-1">
              <GenderSelector
                value={formData.gender}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    gender: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div>
            <label className="text-sm font-medium text-gray-700">
              QBO Client ID
            </label>
            <input
              type="text"
              name="QBOClientId"
              value={formData.QBOClientId}
              onChange={handleInputChange}
              readOnly
              className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
            />
          </div>
        </div>

        {/* Address */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Address</label>
          {isEditing && (
            <div className="space-y-2">
              <GoogleMapsAutocomplete
                onPlaceSelected={handlePlaceSelected}
                inputId="autocomplete-address"
              />
            </div>
          )}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <input
                type="text"
                name="aptUnit"
                value={formData.address.aptUnit}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="Apt/Unit"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
            <div>
              <input
                type="text"
                name="streetNumber"
                value={formData.address.streetNumber}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="Street Number"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
            <div>
              <input
                type="text"
                name="streetName"
                value={formData.address.streetName}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="Street Name"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
            <div>
              <input
                type="text"
                name="city"
                value={formData.address.city}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="City"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
            <div>
              <input
                type="text"
                name="province"
                value={formData.address.province}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="Province"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
            <div>
              <input
                type="text"
                name="postalCode"
                value={formData.address.postalCode}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="Postal Code"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
            <div>
              <input
                type="text"
                name="country"
                value={formData.address.country}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="Country"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
          </div>
        </div>

        {/* Phone Numbers */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">
            Phone Numbers
          </label>
          {formData.phoneNumbers.map((phone, index) => (
            <div key={index} className="grid grid-cols-2 gap-4">
              <input
                type="text"
                name="type"
                value={phone.type}
                onChange={(e) => handlePhoneChange(index, e)}
                readOnly={!isEditing}
                placeholder="Phone Type"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
              <input
                type="text"
                name="number"
                value={phone.number}
                onChange={(e) => handlePhoneChange(index, e)}
                readOnly={!isEditing}
                placeholder="Phone Number"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
