export const generateConfig = ({ token } = {}) => {
  let config = {};

  // console.log("Gen Config " + token);

  if (token) {
    config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": process.env.REACT_APP_TOKEN,
        "x-api-secret": process.env.REACT_APP_SECRET,
        "x-user-token": token,
      },
    };
  } else {
    config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": process.env.REACT_APP_TOKEN,
        "x-api-secret": process.env.REACT_APP_SECRET,
      },
    };
  }
  // console.log(JSON.stringify(config));

  const baseURL = process.env.REACT_APP_BASE_URL;

  if (!baseURL) {
    throw new Error("REACT_APP_BASE_URL is not defined in .env file");
  }

  return { config, baseURL };
};

export const generateUploadConfig = ({ token } = {}) => {
  let config = {};

  if (token) {
    config = {
      headers: {
        "x-api-token": process.env.REACT_APP_TOKEN,
        "x-api-secret": process.env.REACT_APP_SECRET,
        "x-user-token": token,
        // Do not set Content-Type to allow axios to automatically handle it
      },
    };
  } else {
    config = {
      headers: {
        "x-api-token": process.env.REACT_APP_TOKEN,
        "x-api-secret": process.env.REACT_APP_SECRET,
        // Do not set Content-Type to allow axios to automatically handle it
      },
    };
  }

  const baseURL = process.env.REACT_APP_BASE_URL;

  if (!baseURL) {
    throw new Error("REACT_APP_BASE_URL is not defined in .env file");
  }

  return { config, baseURL };
};
