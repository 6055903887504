import React, { useEffect, useState } from "react";
import {
    CalendarIcon,
    UserPlusIcon,
    PhoneIcon,
} from "@heroicons/react/24/outline";
import CircularButton from "./CircularButton";
import IncrementButton from "./IncrementButton";
import Search from "./Search";
import { format } from "date-fns";

function UserBanner({
    onQuickAddClientClick,
    onQuickAddApptClick,
    onScheduleModalClick,
    user,
}) {
    const onAddPhoneClick = () => {
        console.log("extra call");
    };

    const roles = user.role;

    function getGreeting() {
        const currentHour = new Date().getHours(); // Get current hour (0-23)

        if (currentHour < 12) {
            return "Good Morning";
        } else if (currentHour < 18) {
            return "Good Afternoon";
        } else {
            return "Good Evening";
        }
    }

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => setCurrentTime(new Date()), 1000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div className="bg-white shadow border border-gray-200 rounded-md">
            <div className="px-4 sm:px-6 lg:mx-auto">
                <div className="py-6 md:flex md:items-center md:justify-between">
                    {/* Profile */}
                    <div className="flex items-center">
                        <img
                            className="hidden h-16 w-16 rounded-full sm:block"
                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                            alt=""
                        />
                        <div>
                            <div className="flex items-center">
                                <img
                                    className="h-16 w-16 rounded-full sm:hidden"
                                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                                    alt=""
                                />
                                <h1 className="ml-3 text-xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                                    {getGreeting()}, {user.firstName}{" "}
                                    {user.lastName}
                                </h1>
                            </div>
                            <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                                <dt className="sr-only">Company</dt>
                                <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                                    {roles && roles.length > 0 ? ( // Check if roles exist and are not empty
                                        roles.map((role, index) => (
                                            <span
                                                key={index}
                                                className="text-sm font-medium capitalize text-gray-500 sm:mr-1"
                                            >
                                                {role}
                                                {index < roles.length - 1 &&
                                                    ", "}{" "}
                                                {/* Add comma if it's not the last role */}
                                            </span>
                                        ))
                                    ) : (
                                        <span className="text-sm font-medium capitalize text-gray-500 sm:mr-6">
                                            No role assigned
                                        </span>
                                    )}
                                </dd>
                                <dt className="sr-only">Account status</dt>
                            </dl>
                        </div>
                    </div>

                    {/* Search and Quick Actions */}
                    <div className="flex-col items-center">
                        {/* Search */}
                        <div className="mb-4">
                            <Search />
                        </div>

                        {/* Quick Actions */}
                        <div className="relative p-4 border border-gray-400 rounded-md">
                            <div className="absolute inset-x-0 top-[-10px] flex justify-center">
                                <label className="bg-white px-2 text-sm font-medium text-gray-400">
                                    Quick Actions
                                </label>
                            </div>
                            <div className="mt-6 flex space-x-3 justify-center md:mt-0">
                                <CircularButton
                                    icon={
                                        <UserPlusIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    }
                                    onClick={onQuickAddClientClick} // Call the function to open the modal
                                    ariaLabel="Quick Add Client"
                                />

                                {/* <CircularButton
                  icon={<CalendarIcon className="h-5 w-5" aria-hidden="true" />}
                  onClick={onQuickAddApptClick}
                  ariaLabel="Quick Add Appointment"
                  showPlus={true}
                /> */}

                                <IncrementButton
                                    icon={
                                        <PhoneIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    }
                                    onClick={onAddPhoneClick} // Call the function to open the modal
                                    ariaLabel="Add Phone Call"
                                />

                                <CircularButton
                                    icon={
                                        <CalendarIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    }
                                    onClick={onScheduleModalClick}
                                    ariaLabel="Show Schedule"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Date and Time */}
                    <div className="mt-6 md:mt-0 md:ml-6">
                        <div className="text-gray-500 text-sm">
                            <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                                {format(new Date(), "MMMM d yyyy")}
                            </h1>
                            <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                                {currentTime.toLocaleTimeString()}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserBanner;
