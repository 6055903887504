// src/store/thunks/invoiceThunk.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const createInvoice = createAsyncThunk(
  "invoices/createInvoice",
  async (invoiceData, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/invoices`,
        invoiceData,
        config
      );

      return response.data.invoice;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const createSplitInvoice = createAsyncThunk(
  "invoices/createSplitInvoice",
  async (
    { childInvoiceData, parentInvoiceData },
    { rejectWithValue, getState, dispatch }
  ) => {
    console.log("childInvoiceData:", childInvoiceData);
    console.log("parentInvoiceData:", parentInvoiceData);

    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/invoices/split`,
        { childInvoiceData, parentInvoiceData }, // Ensure correct structure here
        config
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchInvoices = createAsyncThunk(
  "invoices/fetchInvoices",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/invoices`, config);
      return response.data.invoices;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchInvoiceById = createAsyncThunk(
  "invoices/fetchInvoiceById",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/invoices/${id}`, config);
      return response.data.invoice;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchInvoicesByClientId = createAsyncThunk(
  "invoices/fetchInvoicesByClientId",
  async (clientId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/invoices/client/${clientId}`,
        config
      );
      return response.data.invoices;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const updateInvoice = createAsyncThunk(
  "invoices/updateInvoice",
  async ({ id, updateData }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.put(
        `${baseURL}/api/invoices/${id}`,
        updateData,
        config
      );

      return response.data.invoice;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  "invoices/deleteInvoice",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      await axios.delete(`${baseURL}/api/invoices/${id}`, config);
      return id;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

const statusSequence = ["created", "sent", "paid", "void"];

// Thunk to update invoice status
export const updateInvoiceStatus = createAsyncThunk(
  "invoices/updateInvoiceStatus",
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      // Fetch current invoice details
      const fetchResponse = await axios.get(
        `${baseURL}/api/invoices/${id}`,
        config
      );
      const invoice = fetchResponse.data.invoice;

      // Determine the next status
      const currentStatusIndex = statusSequence.indexOf(invoice.status);
      const nextStatusIndex = currentStatusIndex + 1;

      if (nextStatusIndex >= statusSequence.length) {
        throw new Error("No more statuses to update");
      }

      const nextStatus = statusSequence[nextStatusIndex];

      // Update invoice status
      const updateResponse = await axios.put(
        `${baseURL}/api/invoices/${id}/status`,
        { status: nextStatus },
        config
      );

      return updateResponse.data.invoice;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);
