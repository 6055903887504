import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchClinics } from "../../store/thunks/clinicThunk";
import ClinicSlideOver from "./ClinicSlideOver";
import Table from "../../applicationUi/components/Table";

export default function ClinicPage() {
    const dispatch = useDispatch();
    const clinics = useSelector((state) => state.clinics.clinics);
    const isLoading = useSelector(
        (state) => state.clinics.status === "loading"
    );
    const error = useSelector((state) => state.clinics.error);
    const [selectedClinicId, setSelectedClinicId] = useState(null);
    const [isEditClinicSlideOver, setIsEditClinicSlideOver] = useState(false);

    useEffect(() => {
        dispatch(fetchClinics());
    }, [dispatch]);

    console.log("SELECT CLINIC ID: " + selectedClinicId);

    const handleClinicClick = (clinicId) => {
        console.log(clinicId);
        setSelectedClinicId(clinicId);
        setIsEditClinicSlideOver(true);
    };

    const handleAddClinic = () => {
        console.log("Add Clinic");
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <div className="pt-4">
                <Table
                    columns={[
                        { header: "Name", accessor: "name" },
                        { header: "Address", accessor: "address.street" },
                        { header: "Email", accessor: "email" },
                        { header: "Phone", accessor: "phoneNumber" },
                    ]}
                    data={clinics}
                    onRowClick={handleClinicClick}
                    actions={[{ label: "Add Clinic", onClick: handleAddClinic }]}
                    title="Clinics"
                    description="A list of all clinics connected to your account."
                />
            </div>
            {isEditClinicSlideOver && selectedClinicId && (
                <ClinicSlideOver
                    isOpen={isEditClinicSlideOver}
                    selectedClinicId={selectedClinicId}
                    onClose={() => setIsEditClinicSlideOver(false)}
                />
            )}
        </>
    );
}
