import ClinicSchedule from "./ClinicSchedule";

function SchedulePage() {
  return (
    <>
      <ClinicSchedule />
    </>
  );
}

export default SchedulePage;
