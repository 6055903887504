import React from "react";

export default function CircularButton({
  icon,
  onClick,
  ariaLabel = "Button",
  showPlus = false,
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="relative flex items-center justify-center rounded-full bg-indigo-600 p-2.5 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      aria-label={ariaLabel}>
      {icon}
      {showPlus && (
        <span className=" absolute inset-0 flex items-center justify-center">
          <span className="absolute text-white text-[14px] font-bold">+</span>
        </span>
      )}
    </button>
  );
}
