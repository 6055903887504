// src/store/slices/uploadsSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { fetchUploads, uploadFile, fetchFile } from "../thunks/uploadsThunk";

const uploadsSlice = createSlice({
  name: "uploads",
  initialState: {
    files: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  // In your slice
  extraReducers: (builder) => {
    builder
      // Fetch all uploads for a specific client
      .addCase(fetchUploads.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUploads.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.files = action.payload;
      })
      .addCase(fetchUploads.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch a file
      .addCase(fetchFile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFile.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Store only file metadata
        state.files.push({
          fileName: action.payload.fileName,
          fileType: action.payload.fileType,
        });
      })
      .addCase(fetchFile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default uploadsSlice.reducer;
