import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTodos } from "../../../store/thunks/todoThunk";
import ToDoItem from "../../../applicationUi/components/toDoItem";
import Accordion from "../../../applicationUi/components/Accordion";

function ClinicianToDoList({ onReschedule, onWideSidePanelClose }) {
  const { todos, loading, error } = useSelector((state) => state.todos);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!todos || typeof todos !== "object") {
    return <div>No To-Do items found.</div>; // Handle case where todos is not an object
  }

  return (
    <div>
      <h2 className="mx-auto mt-8 max-w-6xl text-lg font-medium leading-6 text-gray-900">
        To Do List - {Object.values(todos).flat().length} items
      </h2>
      <div className="mt-2 mx-auto max-w-8xl max-h-112 overflow-y-auto divide-y divide-gray-200">
        {Object.entries(todos).map(([category, todosInCategory]) => (
          <Accordion
            key={category}
            title={`${category} (${
              Array.isArray(todosInCategory) ? todosInCategory.length : 0
            })`}>
            <ul>
              {Array.isArray(todosInCategory) ? (
                todosInCategory.map((todo) => (
                  <ToDoItem
                    key={todo._id}
                    todo={todo}
                    onReschedule={onReschedule}
                    onWideSidePanelClose={onWideSidePanelClose} // Ensure this is passed down
                  />
                ))
              ) : (
                <li>No items found in this category</li>
              )}
            </ul>
          </Accordion>
        ))}
      </div>
    </div>
  );
}

export default ClinicianToDoList;
