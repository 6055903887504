import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

// Thunk to create a payment
export const createPayment = createAsyncThunk(
  "payments/createPayment",
  async (
    { invoiceId, paymentMethod, paymentAmount },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/payments`,
        { invoiceId, paymentMethod, amount: paymentAmount },
        config
      );
      return response.data.payment;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Thunk to fetch payments for an invoice
export const fetchPaymentsByInvoice = createAsyncThunk(
  "payments/fetchPaymentsByInvoice",
  async (invoiceId, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/payments/${invoiceId}`,
        config
      );
      return response.data.payments;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);
