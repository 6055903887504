import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

// Create a new service
export const createService = createAsyncThunk(
  "services/createService",
  async (serviceData, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/services`, // Adjust API endpoint
        serviceData,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Fetch all services
export const fetchServices = createAsyncThunk(
    "services/fetchServices",
    async ({ page = 1, limit = 10 }, { rejectWithValue, getState, dispatch }) => {
      try {
        const state = getState();
        const token = state.auth.token;
        const { baseURL, config } = generateConfig({ token });
  
        // Add query parameters for pagination
        const response = await axios.get(`${baseURL}/api/services?page=${page}&limit=${limit}`, config);
  
        // Assuming your backend returns pagination data along with the services
        return {
          services: response.data.services, // Adjust if your backend response structure is different
          pagination: response.data.pagination, // Adjust if your backend response structure is different
        };
      } catch (error) {
        return handleApiError(error, rejectWithValue, dispatch);
      }
    }
  );

// Fetch a service by ID
export const fetchServiceById = createAsyncThunk(
  "services/fetchServiceById",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });
      const response = await axios.get(
        `${baseURL}/api/services/${id}`, // Adjust API endpoint
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Update an existing service 
export const updateService = createAsyncThunk(
  "services/updateService",
  async ({ id, serviceData }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.put(
        `${baseURL}/api/services/${id}`, // Adjust API endpoint
        serviceData,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Delete a service
export const deleteService = createAsyncThunk(
  "services/deleteService",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.delete(
        `${baseURL}/api/services/${id}`, // Adjust API endpoint
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);