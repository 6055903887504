// src/store/thunks/appointmentsThunk.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const createAppointment = createAsyncThunk(
  "appointments/createAppointment",
  async (appointmentData, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.post(
        `${baseURL}/api/appointments`,
        appointmentData,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchAppointmentsList = createAsyncThunk(
  "appointments/fetchAppointmentsList",
  async (_, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.get(`${baseURL}/api/appointments`, config);
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchAppointmentsByClientId = createAsyncThunk(
  "appointments/fetchAppointmentsByClientId",
  async (clientId, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    // console.log("clientid in thunk", clientId);
    try {
      const response = await axios.get(
        `${baseURL}/api/appointments/clients/${clientId}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchAppointmentsByClinicianId = createAsyncThunk(
  "appointments/fetchAppointmentsByClinician",
  async (clinicianId, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.get(
        `${baseURL}/api/appointments/clinician/${clinicianId}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchAppointmentById = createAsyncThunk(
  "appointments/fetchAppointmentById",
  async (appointmentId, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.get(
        `${baseURL}/api/appointments/${appointmentId}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// NEW - AUG 2
// Thunk for fetching appointments by date range
export const fetchAppointmentsByDateRange = createAsyncThunk(
  "appointments/fetchByDateRange",
  async ({ startDate, endDate }, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });
    console.log("fetchAppointmentsByDateRange" + startDate, endDate);
    try {
      const response = await axios.get(
        `${baseURL}/api/appointments/payroll/byDateRange?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// NEW - Aug 21st
// Thunk for fetching appointments only for specific date
export const fetchAppointmentsByDate = createAsyncThunk(
  "appointments/fetchByDate",
  async ({ date }, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      // Pass the date as data in the request body
      const response = await axios.post(
        `${baseURL}/api/appointments/date`,
        { requestedDate: new Date(date).toISOString() },
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchAppointmentsByClinicianAndDateRange = createAsyncThunk(
  "appointments/fetchByClinicianAndDateRange",
  async (
    { clinicianId, startDate, endDate },
    { rejectWithValue, getState, dispatch }
  ) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const url = endDate
        ? `${baseURL}/api/appointments/payroll/${clinicianId}/byDateRange?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        : `${baseURL}/api/appointments/payroll/${clinicianId}/byDateRange?startDate=${startDate.toISOString()}`;

      const response = await axios.get(url, config);
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchAppointmentsByClinicianDateRangeAndStatus = createAsyncThunk(
  "appointments/fetchByClinicianDateRangeAndStatus",
  async (
    { clinicianId = null, startDate, endDate, statuses, excludeStatus = false }, // Make clinicianId optional
    { rejectWithValue, getState, dispatch }
  ) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.get(
        `${baseURL}/api/appointments/payroll/${
          clinicianId ? clinicianId : "all"
        }/byDateRangeAndStatus?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&statuses=${statuses.join(
          ","
        )}&excludeStatus=${excludeStatus}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchCarryoverAppointments = createAsyncThunk(
  "appointments/fetchCarryoverAppointments",
  async (
    { clinicianId = null, startDate }, // clinicianId is now optional
    { rejectWithValue, getState, dispatch }
  ) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.get(
        `${baseURL}/api/appointments/payroll/${
          clinicianId ? clinicianId : "all"
        }/carryover?startDate=${startDate.toISOString()}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchSoapNotes = createAsyncThunk(
  "appointments/fetchSoapNotes",
  async (_, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.get(
        `${baseURL}/api/appointments/soapNotes`,
        config
      );
      return response.data; // Return the raw data without any extra formatting
    } catch (error) {
      console.log("Error fetching SOAP notes:", error);
      return rejectWithValue(
        error.response?.data || "Failed to fetch SOAP notes"
      );
    }
  }
);

export const fetchSoapNotesByClientId = createAsyncThunk(
  "appointments/fetchSoapNotesByClientId",
  async (clientId, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.get(
        `${baseURL}/api/appointments/soapNotes/${clientId}`,
        config
      );
      return response.data; // Return the raw data without any extra formatting
    } catch (error) {
      console.log("Error fetching SOAP notes:", error);
      return rejectWithValue(
        error.response?.data || "Failed to fetch SOAP notes"
      );
    }
  }
);

export const saveSoapNote = createAsyncThunk(
  "appointments/saveSoapNote",
  async ([appointment, soapNote], { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.post(
        `${baseURL}/api/appointments/saveSoap`,
        {
          appointment,
          soapNote,
        },
        config
      );
      console.log("Payload:", { appointment, soapNote }); // Debugging line
      console.log("Saved SOAP Note Response:", response.data); // Log saved SOAP note response

      return response.data;
    } catch (error) {
      console.log("Error Saving SOAP Note:", error); // Log error
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const updateSoapNote = createAsyncThunk(
  "appointments/updateSoapNote",
  async ([appointment, soapNote], { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.put(
        `${baseURL}/api/appointments/updateSoap`,
        {
          appointment,
          soapNote,
        },
        config
      );
      console.log("Updated SOAP Note Response:", response.data);
      return response.data;
    } catch (error) {
      console.log("Error Updating SOAP Note:", error);
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const signOffSoapNote = createAsyncThunk(
  "appointments/signOffSoapNote",
  async (
    { appointment, user, soapNote },
    { rejectWithValue, getState, dispatch }
  ) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      // First, save the SOAP note
      const saveResponse = await axios.post(
        `${baseURL}/api/appointments/saveSoap`,
        { appointment, soapNote },
        config
      );

      const latestSoapNoteId =
        saveResponse.data.soapNote[saveResponse.data.soapNote.length - 1]._id;

      // Then, sign off the SOAP note
      const signOffResponse = await axios.post(
        `${baseURL}/api/appointments/signOffSoap`,
        {
          appointment: saveResponse.data,
          user,
          soapNoteId: latestSoapNoteId,
        },
        config
      );

      return signOffResponse.data;
    } catch (error) {
      console.log("Error Signing Off SOAP Note:", error);
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const saveAppointment = createAsyncThunk(
  "appointments/saveAppointment",
  async (appointmentData, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.patch(
        `${baseURL}/api/appointments/${appointmentData._id}`,
        appointmentData,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const updateAppointmentStatus = createAsyncThunk(
  "appointments/updateAppointmentStatus",
  async (
    { appointmentId, status, comments, userId },
    { rejectWithValue, getState, dispatch }
  ) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.patch(
        `${baseURL}/api/appointments/status/${appointmentId}`,
        { status, comments, userId },
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const notifyClientByEmail = createAsyncThunk(
  "appointments/notifyClientByEmail",
  async ({ appointment, user }, { rejectWithValue }) => {
    const { baseURL, config } = generateConfig({ token: user.token });

    try {
      const response = await axios.post(
        `${baseURL}/api/appointments/notify`,
        { appointment, user },
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkForConflicts = createAsyncThunk(
  "appointments/checkForConflicts",
  async ({ blockDates, clinicianId }, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.post(
        `${baseURL}/api/appointments/checkConflicts`,
        { blockDates, clinicianId },
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
