// src/components/EditUser.js

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modifyUser, fetchUser, resetUserPassword } from "../../store/thunks/userThunk"; // Adjust the path as necessary
import { generateRandomPassword } from "../../utils/generateRandomPassword";
import GoogleMapsAutocomplete from "../../applicationUi/components/GoogleAutoComplete";

const EditUser = ({ user, onClose }) => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.users.loading);
    const error = useSelector((state) => state.users.error);
    const [password, setPassword] = useState(generateRandomPassword());

    const [formData, setFormData] = useState({
        _id: "",
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        phoneNumbers: [{ type: "", number: "" }],
        employeeId: "",
        role: ["clinician"],
        address: {
            aptNumber: "",
            street: "",
            city: "",
            province: "",
            postalCode: "",
        },
        startDate: "",
        terminationDate: "",
        jobTitle: "",
        qualifications: "",
        defaultLocation: "",
        calendarColor: "",
        calendarDisplayName: "",
        doubleBookingAllowed: false,
        showClientArrivedNotification: true,
        showClientReadyNotification: false,
        onlineBookingEnabled: false,
    });

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userData = await dispatch(fetchUser(user)).unwrap();
                setFormData({
                    _id: userData._id || "",
                    email: userData.email || "",
                    password: userData.password || "",
                    firstName: userData.firstName || "",
                    lastName: userData.lastName || "",
                    phoneNumbers: userData.phoneNumbers || [{ type: "", number: "" }],
                    employeeId: userData.employeeId || "",
                    role: userData.role || ["clinician"],
                    address: {
                        aptNumber: userData.address?.aptNumber || "",
                        street: userData.address?.street || "",
                        city: userData.address?.city || "",
                        province: userData.address?.province || "",
                        postalCode: userData.address?.postalCode || "",
                    },
                    startDate: userData.startDate || "",
                    terminationDate: userData.terminationDate || "",
                    jobTitle: userData.jobTitle || "",
                    qualifications: userData.qualifications || "",
                    defaultLocation: userData.defaultLocation || "",
                    calendarColor: userData.calendarColor || "",
                    calendarDisplayName: userData.calendarDisplayName || "",
                    doubleBookingAllowed: userData.doubleBookingAllowed || false,
                    showClientArrivedNotification: userData.showClientArrivedNotification || true,
                    showClientReadyNotification: userData.showClientReadyNotification || false,
                    onlineBookingEnabled: userData.onlineBookingEnabled || false,
                });
            } catch (error) {
                console.error("Failed to fetch user data:", error);
            }
        };

        fetchUserData();
    }, [dispatch, user]);

    useEffect(() => {
        setFormData((prevState) => ({ ...prevState, password }));
    }, [password]);

    console.log(user);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "select-one") {
          setFormData((prevState) => ({
            ...prevState,
            [name]: value, // Ensure this is a scalar value
          }));
        } else {
          setFormData((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
          }));
        }
      };

    const handleGenerateNewPassword = () => {
        const newPassword = generateRandomPassword();
        setPassword(newPassword);
        setFormData(prevState => ({
            ...prevState,
            password: newPassword
        }));
        
        // Dispatch action to reset user password
        dispatch(resetUserPassword({ userId: user.id, newPassword }))
            .unwrap()
            .then(() => {
                // Handle success (e.g., show a success message)
                console.log('Password reset successfully');
            })
            .catch((error) => {
                // Handle error (e.g., show an error message)
                console.error('Failed to reset password:', error);
            });
    };
    const handlePhoneNumberChange = (index, e) => {
        const { name, value } = e.target;
        const updatedPhoneNumbers = [...formData.phoneNumbers];
        updatedPhoneNumbers[index][name] = value;
        setFormData({ ...formData, phoneNumbers: updatedPhoneNumbers });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(modifyUser(formData));
    };

    return (
        <div className="p-6 bg-white shadow rounded-lg">
            <div className="flex flex-col">
            <div className="flex justify-between items-center mb-4">
                    <h1 className="text-xl font-semibold">Edit User</h1>
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Close
                    </button>
                </div>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <form onSubmit={handleSubmit} className="space-y-4">
                    {/* Email and Password */}
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Reset Password
                            </label>
                            <div className="flex items-center mt-1 space-x-2">
                                <button
                                    type="button"
                                    onClick={handleGenerateNewPassword}
                                    className="px-4 py-2 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Generate New
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Name and Phone Numbers */}
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                First Name
                            </label>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Last Name
                            </label>
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                    </div>

                    {formData.phoneNumbers?.map((phone, index) => (
                        <div key={index} className="flex flex-wrap gap-4">
                            <div className="flex-1 min-w-[200px]">
                                <label className="block text-sm font-medium text-gray-700">
                                    Phone Number {index + 1}
                                </label>
                                <input
                                    type="text"
                                    name="number"
                                    value={phone.number}
                                    onChange={(e) =>
                                        handlePhoneNumberChange(index, e)
                                    }
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    placeholder="Phone number"
                                />
                            </div>
                            <div className="flex-1 min-w-[200px]">
                                <label className="block text-sm font-medium text-gray-700">
                                    Type
                                </label>
                                <select
                                    name="type"
                                    value={phone.type}
                                    onChange={(e) =>
                                        handlePhoneNumberChange(index, e)
                                    }
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                    <option value="">Select type</option>
                                    <option value="Home">Home</option>
                                    <option value="Work">Work</option>
                                    <option value="Mobile">Mobile</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                    ))}

                    {/* Address and Employee ID */}
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Address Search
                            </label>
                            <GoogleMapsAutocomplete
                                inputId="address-search"
                                onPlaceSelected={(place) => {
                                    console.log('Selected place:', place);
                                    const addressComponents = place.address_components;
                                    const streetNumber = addressComponents.find(c => c.types.includes('street_number'))?.long_name || '';
                                    const route = addressComponents.find(c => c.types.includes('route'))?.long_name || '';
                                    const formattedAddress = {
                                        street: `${streetNumber} ${route}`.trim(),
                                        city: addressComponents.find(c => c.types.includes('locality'))?.long_name || '',
                                        province: addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.short_name || '',
                                        postalCode: addressComponents.find(c => c.types.includes('postal_code'))?.long_name || ''
                                    };
                                    setFormData(prevData => ({
                                        ...prevData,
                                        address: formattedAddress
                                    }));
                                }}
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Street
                            </label>
                            <input
                                type="text"
                                name="address.street"
                                value={formData.address?.street}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Street"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                City
                            </label>
                            <input
                                type="text"
                                name="address.city"
                                value={formData.address?.city}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="City"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Province
                            </label>
                            <input
                                type="text"
                                name="address.province"
                                value={formData.address?.province}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Province"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Postal Code
                            </label>
                            <input
                                type="text"
                                name="address.postalCode"
                                value={formData.address?.postalCode}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Postal Code"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Employee ID
                            </label>
                            <input
                                type="text"
                                name="employeeId"
                                value={formData.employeeId}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Employee ID"
                            />
                        </div>
                    </div>

                    {/* Role */}
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Role(s)
                            </label>
                            <div className="space-y-2">
                                {['clinician', 'admin', 'owner', 'hr', 'officeManager', 'bookkeeper'].map((role) => (
                                    <div key={role} className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id={role}
                                            name="role"
                                            value={role}
                                            checked={formData.role.includes(role)}
                                            onChange={(e) => {
                                                const updatedRoles = e.target.checked
                                                    ? [...formData.role, role]
                                                    : formData.role.filter((r) => r !== role);
                                                setFormData({ ...formData, role: updatedRoles });
                                            }}
                                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                        />
                                        <label htmlFor={role} className="ml-2 block text-sm text-gray-900 capitalize">
                                            {role.replace(/([A-Z])/g, ' $1').trim()}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Conditional Fields for Clinician Role */}
                    {formData.role.includes("clinician") && (
                        <>
                            <div className="flex flex-wrap gap-4">
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Job Title
                                    </label>
                                    <input
                                        type="text"
                                        name="jobTitle"
                                        value={formData.jobTitle}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        placeholder="Job Title"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Qualifications
                                    </label>
                                    <input
                                        type="text"
                                        name="qualifications"
                                        value={formData.qualifications}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        placeholder="Qualifications"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Default Location
                                    </label>
                                    <input
                                        type="text"
                                        name="defaultLocation"
                                        value={formData.defaultLocation}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        placeholder="Default Location"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Calendar Color
                                    </label>
                                    <input
                                        type="color"
                                        name="calendarColor"
                                        value={formData.calendarColor}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Calendar Display Name
                                    </label>
                                    <input
                                        type="text"
                                        name="calendarDisplayName"
                                        value={formData.calendarDisplayName}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        placeholder="Calendar Display Name"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Double Booking Allowed
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="doubleBookingAllowed"
                                        checked={formData.doubleBookingAllowed}
                                        onChange={handleChange}
                                        className="mt-1 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Show Client Arrived Notification
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="showClientArrivedNotification"
                                        checked={
                                            formData.showClientArrivedNotification
                                        }
                                        onChange={handleChange}
                                        className="mt-1 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Show Client Ready Notification
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="showClientReadyNotification"
                                        checked={
                                            formData.showClientReadyNotification
                                        }
                                        onChange={handleChange}
                                        className="mt-1 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Online Booking Enabled
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="onlineBookingEnabled"
                                        checked={formData.onlineBookingEnabled}
                                        onChange={handleChange}
                                        className="mt-1 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {/* Dates */}
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Start Date
                            </label>
                            <input
                                type="date"
                                name="startDate"
                                value={formData.startDate}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <label className="block text-sm font-medium text-gray-700">
                                Termination Date
                            </label>
                            <input
                                type="date"
                                name="terminationDate"
                                value={formData.terminationDate}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="py-2 px-4 bg-gray-200 text-gray-800 font-semibold rounded-md shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={loading}
                            className="inline-flex justify-center py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {loading ? "Updating..." : "Update User"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditUser;
