import React, { useEffect, useState } from "react";
import Table from "../../applicationUi/components/Table";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../../store/thunks/servicesThunk"; // Import your service fetching action
import EditServiceModal from "../services/EditServiceModal";

function ServicesPage() {
    const dispatch = useDispatch();
    const {
        services, // Assuming this now holds your services data
        pagination,
        status: serviceStatus,
        error: serviceError,
    } = useSelector((state) => state.services); // Access the services slice
    const [isEditServiceModalOpen, setIsEditServiceModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    console.log(services);

    useEffect(() => {
        // Fetch services when the component mounts
        dispatch(fetchServices({ page: 1, limit: pagination?.pageSize || 10 }));
    }, [dispatch, pagination?.pageSize]);

    // Handle row click (you'll need to implement this)
    const handleRowClick = (serviceId) => { 
        // Find the service within the component
        const selectedService = services.find(service => service._id === serviceId);
    
        setIsEditServiceModalOpen(true);
        setSelectedService(selectedService); 
        console.log("Clicked service:", JSON.stringify(selectedService)); 
      };

    return (
        <div>
            {serviceStatus === "loading" && <p>Loading services...</p>}
            {serviceStatus === "failed" && (
                <p>
                    Error loading clients:{" "}
                    {typeof clientError === "object"
                        ? JSON.stringify(serviceError)
                        : serviceError}
                </p>
            )}
            {serviceStatus === "succeeded" && services.length === 0 && (
                <p>No services available</p>
            )}
            {serviceStatus === "succeeded" && services.length > 0 && (
                <Table
                    columns={[
                        { header: "Service Code", accessor: "serviceCode" }, // Adjust based on your service data structure
                        { header: "Description", accessor: "description" },
                        { header: "Duration", accessor: "duration"},
                        { header: "Price", accessor: "price" },
                        { heade: "Color", accessor: "color"}
                    ]}
                    data={services}
                    onRowClick={handleRowClick}
                    actions={[
                        {
                            label: "Add Service",
                            onClick: () => setIsEditServiceModalOpen(true), // Adjust this to open your service creation modal
                        },
                    ]}
                    title="Services"
                    description="A list of all services offered by the company."
                    showPagination={pagination.totalPages > 1}
                    currentPage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    onPageChange={(page) =>
                        dispatch(
                            fetchServices({ page, limit: pagination.pageSize })
                        )
                    }
                />
            )}
            {/* Edit Client Modal */}
            {isEditServiceModalOpen && selectedService && (
                <EditServiceModal
                    service={selectedService}
                    onClose={() => setIsEditServiceModalOpen(false)}
                />
            )}
        </div>
    );
}

export default ServicesPage;
