import React, { useState, useRef, useEffect } from "react";

function GoogleMapsAutocomplete({ onPlaceSelected, inputId }) {
  const [searchInput, setSearchInput] = useState(""); // Added to keep track of the input value
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (window.google) {
      // Check if Google Maps API is loaded
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        document.getElementById(inputId), // Use the provided input ID
        {
          types: ["geocode"], // Limit to address results
          componentRestrictions: { country: "CA" }, // Restrict to Canada (or your desired country)
        }
      );

      autocompleteRef.current.addListener("place_changed", () => {
        const place = autocompleteRef.current.getPlace();
        if (place && onPlaceSelected) {
          onPlaceSelected(place); // Call the callback with the selected place object
        }
      });
    }
  }, [onPlaceSelected, inputId]);

  return (
    <div>
      <input
        id={inputId}
        type="text"
        placeholder="Enter address..."
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
  );
}

export default GoogleMapsAutocomplete;
