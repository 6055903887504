import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WideSidePanel from "../../applicationUi/components/WideSidePanel";
import ClinicianDashboardComponent from "./clinician/ClinicianDashboardComponent";
import LockScreen from "../../auth/lockScreen";
import QuickAddModal from "../../modals/QuickAddModals";
import ClinicScheduleModal from "../schedulePage/ClinicScheduleModal";
import AdminDashboardComponent from "./admin/AdminDashboardComponent";
import OwnerDashboardComponent from "./owner/OwnerDashboardComponent";
import { fetchUsers } from "../../store/thunks/userThunk";

function DashboardPage({ user }) {
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isWideSidePanelOpen, setWideSidePanelOpen] = useState(false);
    const [isScreenLocked, setIsScreenLocked] = useState(false);
    const [activeModal, setActiveModal] = useState(null);
    const [rescheduleData, setRescheduleData] = useState(null);
    const [selectedUser, setSelectedUser] = useState(user);
    const [selectedDashboard, setSelectedDashboard] = useState("clinician");
    const users = useSelector((state) => state.users.users || []);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    const handleAppointmentClick = (appointment) => {
        setSelectedAppointment(appointment);
        setWideSidePanelOpen(true);
    };

    const handleClientClick = (client) => {
        setSelectedClient(client);
        setWideSidePanelOpen(true);
    };

    const handleCloseWideSidePanel = () => {
        setWideSidePanelOpen(false);
    };

    const handleReschedule = (appointment) => {
        setRescheduleData(appointment);
        setWideSidePanelOpen(false);
        setActiveModal("schedule");
    };

    const handleCloseQuickAddModal = () => {
        setActiveModal(null);
    };

    const renderDashboard = () => {
        const roles = (selectedUser?.role || []).map((role) =>
            role.toLowerCase()
        );
        const components = [];

        if (roles.includes("clinician") && selectedDashboard === "clinician") {
            components.push(
                <ClinicianDashboardComponent
                    key="clinician-dashboard"
                    handleAppointmentClick={handleAppointmentClick}
                    handleClientClick={handleClientClick}
                    handleReschedule={handleReschedule}
                    clinician={selectedUser}
                />
            );
        }

        if (roles.includes("admin") && selectedDashboard === "admin") {
            components.push(
                <AdminDashboardComponent
                    key="admin-dashboard"
                    handleAppointmentClick={handleAppointmentClick}
                    handleClientClick={handleClientClick}
                    handleReschedule={handleReschedule}
                    clinician={selectedUser}
                />
            );
        }

        if (roles.includes("owner") && selectedDashboard === "owner") {
            components.push(
                <OwnerDashboardComponent
                    key="owner-dashboard"
                    handleAppointmentClick={handleAppointmentClick}
                    handleClientClick={handleClientClick}
                    handleReschedule={handleReschedule}
                    clinician={selectedUser}
                />
            );
        }

        if (components.length === 0) {
            return <div>Role not recognized</div>;
        }

        return <div>{components}</div>;
    };

    return (
        <>
            {isScreenLocked && <LockScreen />}
            <div>
                <label>Select User:</label>
                <select
                    value={selectedUser?._id || ""}
                    onChange={(e) =>
                        setSelectedUser(
                            users.find((u) => u._id === e.target.value) || {}
                        )
                    }
                >
                    {Array.isArray(users) &&
                        users.map((user) => (
                            <option key={user._id} value={user._id}>
                                {user.firstName} {user.lastName}{" "}
                                {/* Displaying first and last name */}
                            </option>
                        ))}
                </select>

                <label>Select Dashboard:</label>
                <select
                    value={selectedDashboard}
                    onChange={(e) => setSelectedDashboard(e.target.value)}
                >
                    <option value="clinician">Clinician</option>
                    <option value="admin">Admin</option>
                    <option value="owner">Owner</option>
                </select>
            </div>

            {renderDashboard()}

            {selectedAppointment && (
                <WideSidePanel
                    title={"Appointment Summary"}
                    open={isWideSidePanelOpen}
                    onClose={handleCloseWideSidePanel}
                    appointment={selectedAppointment}
                    onAppointmentClick={handleAppointmentClick}
                    onReschedule={handleReschedule}
                />
            )}
            <QuickAddModal
                activeModal={activeModal}
                onClose={handleCloseQuickAddModal}
            />
            {activeModal === "schedule" && (
                <ClinicScheduleModal
                    setModalOpen={(state) => {
                        setActiveModal(state ? "schedule" : null);
                    }}
                    selectedClient={rescheduleData?.client}
                    selectedService={rescheduleData?.service}
                    selectedProduct={rescheduleData?.product}
                    selectedClinician={rescheduleData?.clinician}
                    rescheduleData={rescheduleData}
                />
            )}
        </>
    );
}

export default DashboardPage;
