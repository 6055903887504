"use client";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUserAsync } from "../store/thunks/authThunk";

export default function Register() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [formError, setFormError] = useState(null); // Separate form error state
    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Get registration status and error from Redux store
    const { isRegistrationSuccessful, loading } = useSelector(
        (state) => state.auth
    );

    useEffect(() => {
        if (isRegistrationSuccessful) {
            // Registration was successful, navigate to login
            navigate("/login");
        }
    }, [isRegistrationSuccessful, navigate]);

    const handleSubmit = async (e) => {
        console.log("Form Submitted");
        e.preventDefault();
        setFormError(null); // Clear form-specific errors

        // Input Validation (Client-Side)
        if (!validateEmail(email)) {
            setFormError("Invalid email address.");
            return;
        }
        if (password !== confirmPassword) {
            setFormError("Passwords do not match.");
            return;
        }
        if (password.length < 8) {
            setFormError("Password must be at least 8 characters long.");
            return;
        }

        try {
            console.log("Dispatch");
            await dispatch(registerUserAsync({ email, password })); // Dispatch and wait for thunk to complete
        } catch (err) {
            // This should not be necessary as the thunk handles the error.
            // Only errors outside of the thunk should be handled here.
            console.error("Unexpected registration error:", err);
            setError("An unexpected error occurred.");
        }
    };

    // Helper function for email validation
    function validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                {/* ... (Your logo) */}
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Create Your Account
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Password
                        </label>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    {/* Confirm Password Input */}
                    <div>
                        <label
                            htmlFor="confirmPassword"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Confirm Password
                        </label>
                        <div className="mt-2">
                            <input
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                }
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    {/* Error Display */}
                    {error && <p className="text-red-500">{error}</p>}

                    {formError ? (
                        <p className="text-red-500">{formError}</p>
                    ) : error ? (
                        <p className="text-red-500">{error}</p>
                    ) : null}
                    <div>
                        <button
                            type="submit"
                            disabled={loading} // Disable button while loading
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            {loading ? "Registering..." : "Register"}{" "}
                            {/* Show loading state */}
                        </button>
                    </div>
                </form>

                {/* ... (Your "Not a member?" link) */}
            </div>
        </div>
    );
}
