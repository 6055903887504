import { createSlice } from '@reduxjs/toolkit';
import { fetchClinics, fetchClinicById, createClinic, updateClinic, deleteClinic } from '../thunks/clinicThunk';

export const clinicSlice = createSlice({
  name: 'clinics',
  initialState: {
    clinics: [],
    status: "idle",
    error: null,
    selectedClinic: null,
  },
  reducers: {}, // You might add other reducers here for local state changes
  extraReducers(builder) {
    builder
      // Fetch all clinics
      .addCase(fetchClinics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClinics.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clinics = action.payload;
      })
      .addCase(fetchClinics.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Fetch single clinic
      .addCase(fetchClinicById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClinicById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentClinic = action.payload;
      })
      .addCase(fetchClinicById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Create clinic
      // ... (similar structure for createClinic, updateClinic, and deleteClinic)
  },
});

export default clinicSlice.reducer; 
