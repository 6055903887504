import { createSlice } from "@reduxjs/toolkit";
import { fetchReferrals, createReferral } from "../thunks/referralsThunk";

const initialState = {
  referrals: [], // Store the fetched referral sources
  loading: false,
  error: null,
};

const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {}, // You might add other reducers if needed
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferrals.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReferrals.fulfilled, (state, action) => {
        state.loading = false;
        state.referrals = action.payload;
      })
      .addCase(fetchReferrals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createReferral.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createReferral.fulfilled, (state, action) => {
        state.loading = false;
        state.referralSources.push(action.payload); // Add new referral to the list
      })
      .addCase(createReferral.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default referralSlice.reducer;
