import { createSlice } from "@reduxjs/toolkit";
import { generatePDF } from "../thunks/pdfThunk";

const pdfSlice = createSlice({
  name: "pdf",
  initialState: {
    pdfBlob: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generatePDF.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(generatePDF.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pdfBlob = action.payload;
      })
      .addCase(generatePDF.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default pdfSlice.reducer;
