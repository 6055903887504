import { createSlice } from "@reduxjs/toolkit";
import { fetchInsurers, createInsurer } from "../thunks/insurerThunk";

const initialState = {
  insurers: [],
  loading: false,
  error: null,
  createdInsurer: null, 
};

const insurerSlice = createSlice({
  name: "insurer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInsurers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInsurers.fulfilled, (state, action) => {
        state.loading = false;
        state.insurers = action.payload;
      })
      .addCase(fetchInsurers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createInsurer.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createdInsurer = null;
      })
      .addCase(createInsurer.fulfilled, (state, action) => {
        state.loading = false;
        state.createdInsurer = action.payload; 
      })
      .addCase(createInsurer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default insurerSlice.reducer;
