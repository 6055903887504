import React from "react";
import { useSelector } from "react-redux";
import ToDoItem from "../../../applicationUi/components/toDoItem";
import Accordion from "../../../applicationUi/components/Accordion";

function AdminToDoList({ onReschedule, onWideSidePanelClose }) {
  const { todos, loading, error } = useSelector((state) => state.todos);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!todos || typeof todos !== "object") {
    return <div>No To-Do items found.</div>; // Handle case where todos is not an object
  }

  return (
    <div>
      <h2 className="mx-auto mt-8 max-w-6xl text-lg font-medium leading-6 text-gray-900">
        To Do List - {Object.values(todos).flat().length} items
      </h2>
      <div className="mt-2 mx-auto max-w-8xl max-h-112 overflow-y-auto divide-y divide-gray-200">
        {Object.entries(todos).map(([category, todosInCategory]) => (
          <Accordion
            key={category}
            title={`${category} (${todosInCategory.length})`}>
            <ul>
              {todosInCategory.map((todo) => (
                <ToDoItem
                  key={todo._id}
                  todo={todo}
                  onReschedule={onReschedule}
                  onWideSidePanelClose={onWideSidePanelClose} // Ensure this is passed down
                />
              ))}
            </ul>
          </Accordion>
        ))}
      </div>
    </div>
  );
}

export default AdminToDoList;
