// Email validation
export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Phone number validation (basic format: xxx-xxx-xxxx or (xxx) xxx-xxxx)
export const isValidPhoneNumber = (phoneNumber) => {
  const phoneRegex = /^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  return phoneRegex.test(phoneNumber);
};

// Name validation (allows letters, spaces, hyphens, and apostrophes)
export const isValidName = (name) => {
  const nameRegex = /^[a-zA-Z\s'-]+$/;
  return nameRegex.test(name) && name.length >= 2;
};

// Password strength validation
export const isStrongPassword = (password) => {
  // At least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
};

// URL validation
export const isValidURL = (url) => {
  const urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  return urlRegex.test(url);
};

// Date validation (YYYY-MM-DD format)
export const isValidDate = (dateString) => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(dateString)) return false;
  
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date);
};

// Zip code validation (US format)
export const isValidZipCode = (zipCode) => {
  const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  return zipRegex.test(zipCode);
};

// Credit card number validation (basic check)
export const isValidCreditCard = (cardNumber) => {
  const cardRegex = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
  return cardRegex.test(cardNumber.replace(/\s/g, ''));
};

// Form field is not empty
export const isNotEmpty = (value) => {
  return value.trim().length > 0;
};

// Validate form data object
export const validateFormData = (formData, requiredFields) => {
  const errors = {};
  
  requiredFields.forEach(field => {
    if (!isNotEmpty(formData[field])) {
      errors[field] = `${field} is required`;
    }
  });

  if (formData.email && !isValidEmail(formData.email)) {
    errors.email = 'Invalid email format';
  }

  if (formData.phoneNumber && !isValidPhoneNumber(formData.phoneNumber)) {
    errors.phoneNumber = 'Invalid phone number format';
  }

  // Add more specific validations as needed

  return errors;
};
