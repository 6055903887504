import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAppointmentsByClinicianAndDateRange,
  fetchAppointmentsByClinicianDateRangeAndStatus,
  fetchCarryoverAppointments,
} from "../../store/thunks/appointmentsThunk";
import { fetchPayrollsByClinicianStatusAndPayPeriod } from "../../store/thunks/payrollThunk";
import { fetchClinicians } from "../../store/thunks/cliniciansThunk";
import HRPayrollPage from "./HRPayrollPage";
import PayrollScheduleTable from "./PayrollSchedule";
import PayrollSlideOver from "./PayrollSlideOver";
import payrollDates from "./payrollDates";
import PayrollStats from "./PayrollStats";
import { Listbox } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

export default function HRPayrollOverviewPage() {
  const dispatch = useDispatch();
  const clinicians = useSelector((state) => state.clinicians.clinicians || []); // Default to an empty array if undefined

  const [isPayrollSlideOver, setIsPayrollSlideOver] = useState(false);
  const [ishrpayrollpage, setishrpayrollpage] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showPayrollSchedule, setShowPayrollSchedule] = useState(false);
  const [selectedClinician, setSelectedClinician] = useState(null);
  const [payperiodAppointments, setPayperiodAppointments] = useState([]);
  const [completedPayrolls, setCompletedPayrolls] = useState([]);
  const [carryoverAppointments, setCarryoverAppointments] = useState([]);
  const [clinicianData, setClinicianData] = useState([]);

  useEffect(() => {
    dispatch(fetchClinicians());
  }, [dispatch]);

  const findClosestPayPeriod = () => {
    const currentDate = new Date();
    let closestPeriod = payrollDates[0];
    let minDiff = Number.MAX_VALUE;

    payrollDates.forEach((period) => {
      const [startDateString, endDateString] = period.includeDates.split("-");
      const startDate = new Date(`${startDateString} 2024`);
      const endDate = new Date(`${endDateString} 2024`);

      if (currentDate >= startDate && currentDate <= endDate) {
        closestPeriod = period;
        minDiff = 0;
      } else {
        const diff = Math.min(
          Math.abs(currentDate - startDate),
          Math.abs(currentDate - endDate)
        );
        if (diff < minDiff) {
          closestPeriod = period;
          minDiff = diff;
        }
      }
    });

    return closestPeriod;
  };

  const [selectedPeriod, setSelectedPeriod] = useState(findClosestPayPeriod());

  const fetchData = () => {
    if (selectedPeriod) {
      const [start, end] = selectedPeriod.includeDates
        .split("-")
        .map((date) => new Date(`${date.trim()} 2024`)); // Ensure the date strings are trimmed
      setStartDate(start);
      setEndDate(end);

      const currentDate = new Date();
      const submissionDate = new Date(`${selectedPeriod.submissionDate}`);

      if (clinicians && clinicians.length > 0) {
        const promises = clinicians.map((clinician) => {
          const clinicianId = clinician._id;

          // Fetch Appointments based on submission date logic
          const appointmentsPromise = dispatch(
            fetchAppointmentsByClinicianAndDateRange({
              clinicianId: clinician._id,
              startDate: start,
            })
          ).then((result) => ({
            clinicianId,
            pendingAppointments:
              currentDate <= submissionDate ? result.payload : [],
            carryoverAppointments:
              currentDate > submissionDate ? result.payload : [],
          }));

          // Fetch Completed Payrolls
          const payrollsPromise = dispatch(
            fetchPayrollsByClinicianStatusAndPayPeriod({
              clinicianId: clinician._id,
              payPeriod: selectedPeriod.payPeriodNumber,
              statuses: ["Pending", "Ready", "Paid to Clinician"],
            })
          ).then((result) => ({
            clinicianId,
            completedAppointments: result.payload,
          }));

          return Promise.all([appointmentsPromise, payrollsPromise]).then(
            ([appointmentsData, payrollsData]) => ({
              clinicianId,
              email: clinician.email,
              pendingAppointments: appointmentsData.pendingAppointments,
              completedAppointments: payrollsData.completedAppointments,
              carryoverAppointments: appointmentsData.carryoverAppointments,
            })
          );
        });

        Promise.all(promises)
          .then((results) => {
            const updatedData = clinicians.map((clinician, index) => ({
              ...clinician,
              clinicianId: clinician._id,
              completedAppointments: results[index].completedAppointments || [],
              pendingAppointments: results[index].pendingAppointments || [],
              carryoverAppointments: results[index].carryoverAppointments || [],
            }));

            setClinicianData(updatedData);

            // Summing data for overview
            const overviewData = results.reduce(
              (acc, result) => {
                acc.pendingAppointments.push(...result.pendingAppointments);
                acc.completedAppointments.push(...result.completedAppointments);
                acc.carryoverAppointments.push(...result.carryoverAppointments);
                return acc;
              },
              {
                pendingAppointments: [],
                completedAppointments: [],
                carryoverAppointments: [],
              }
            );

            // Set overview data
            setPayperiodAppointments(overviewData.pendingAppointments);
            setCompletedPayrolls(overviewData.completedAppointments);
            setCarryoverAppointments(overviewData.carryoverAppointments);
          })
          .catch((error) => {
            console.error("Error fetching clinician data:", error);
          });
      } else {
        console.error("No clinicians found in the store");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, selectedPeriod]);

  const handleCloseModal = () => {
    setShowPayrollSchedule(false);
  };

  const calculatePendingAppointments = () => {
    return payperiodAppointments.filter((appointment) => {
      const hasSoapNotesComplete = appointment.history.some(
        (historyItem) => historyItem.status === "Soap Notes Complete"
      );
      const hasInvoicePaid =
        appointment.invoice && appointment.invoice.status === "paid";

      return !(hasSoapNotesComplete && hasInvoicePaid);
    }).length;
  };

  const calculateAppointmentsCompleted = () => {
    return completedPayrolls.length;
  };

  const calculateCarryoverAppointments = () => {
    if (!Array.isArray(clinicianData)) {
      return "0";
    }

    // Sum the length of carryoverAppointments arrays across all clinicians
    const totalCarryoverAppointments = clinicianData.reduce(
      (sum, clinician) => {
        if (Array.isArray(clinician.carryoverAppointments)) {
          return sum + clinician.carryoverAppointments.length;
        }
        return sum;
      },
      0
    );

    return totalCarryoverAppointments.toString();
  };

  const calculatePendingPay = () => {
    const totalPay = payperiodAppointments.reduce((total, appointment) => {
      const price = appointment.service?.price || 0;
      return total + price * 0.45;
    }, 0);

    return totalPay.toFixed(2);
  };

  const calculateEstimatedPay = () => {
    if (!Array.isArray(completedPayrolls)) {
      return "0.00";
    }
    return completedPayrolls
      .reduce((sum, payroll) => sum + (payroll.payrollAmount || 0), 0)
      .toFixed(2);
  };

  const calculateCarryoverPay = () => {
    if (!Array.isArray(clinicianData)) {
      return "0.00";
    }

    // Sum carryover pay across all clinicians
    const totalCarryoverPay = clinicianData.reduce((sum, clinician) => {
      if (Array.isArray(clinician.carryoverAppointments)) {
        return (
          sum +
          clinician.carryoverAppointments.reduce((subSum, appointment) => {
            const price = appointment.service?.price || 0;
            const isTaxable = appointment.service?.tax?.isTaxable || false;
            const taxRate = appointment.service?.tax?.taxRate || 0;

            // Apply tax if taxable, else just the price
            const finalPrice = isTaxable ? price * (1 + taxRate) : price;

            // Return subtotal sum for this clinician's appointments
            return subSum + finalPrice;
          }, 0)
        );
      }
      return sum;
    }, 0);

    return totalCarryoverPay.toFixed(2); // Ensure result is rounded to 2 decimal places
  };

  const getSubmissionDate = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const submissionDate = new Date(currentPeriod.submissionDate);
      return submissionDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const getScheduledPayDay = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const paymentDate = new Date(currentPeriod.paymentDate);
      return paymentDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  // Calculate stats
  const stats = [
    {
      name: `Pay Period ${selectedPeriod.payPeriodNumber}`,
      value: `${selectedPeriod.includeDates}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Submit Appointments By",
      value: `${getSubmissionDate()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pay Day",
      value: `${getScheduledPayDay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pending Appointments",
      value: `${calculatePendingAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Completed Appointments",
      value: `${calculateAppointmentsCompleted()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Carryover Appointments",
      value: `${calculateCarryoverAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Pending Pay",
      value: `$${calculatePendingPay()}`,
      change: "",
      changeType: "neutral",
      extra: "(Default 45%)",
    },
    {
      name: "Total Completed Pay",
      value: `$${calculateEstimatedPay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Carryover Pay",
      value: `$${calculateCarryoverPay()}`,
      change: "",
      changeType: "neutral",
    },
  ];

  const handleClinicianClick = (clinician) => {
    setSelectedClinician(clinician);
    setishrpayrollpage(true);
    console.log("Clinician Click", clinician);
  };

  const getBorderStyle = (data) => {
    const hasPendingAppointments = data.pendingAppointments.length > 0;
    const hasReadyCompletedAppointments = data.completedAppointments.some(
      (appointment) => appointment.status === "Ready"
    );
    const hasPaidCompletedAppointments = data.completedAppointments.some(
      (appointment) => appointment.status === "Paid to Clinician"
    );

    if (hasReadyCompletedAppointments) {
      return "border-green-500 border-dashed";
    } else if (hasPaidCompletedAppointments) {
      return "border-green-500 border-solid";
    } else if (hasPendingAppointments) {
      return "border-yellow-500 border-dashed";
    } else {
      return "border-yellow-500 border-solid";
    }
  };

  return (
    <>
      {/* <div className="py-4 pb-20"> */}
      <div className="py-4 pb-20">
        <PayrollStats stats={stats} />
        {/* Pay period selector and toggles */}
        <div className="flex space-x-4 mb-4 justify-around items-center">
          <div className="flex flex-col items-center space-y-2">
            <Listbox value={selectedPeriod} onChange={setSelectedPeriod}>
              <div className="flex flex-col items-center">
                <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                  Select Payroll Period
                </Listbox.Label>
                <div className="relative mt-2 w-full">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <span className="block truncate">
                      {`Pay Period ${selectedPeriod.payPeriodNumber} (${selectedPeriod.includeDates})`}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {payrollDates.map((period) => (
                      <Listbox.Option
                        key={period.payPeriodNumber}
                        value={period}
                        className={({ active, selected }) =>
                          `relative cursor-default select-none py-2 pl-3 pr-9 ${
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900"
                          }`
                        }>
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-semibold" : "font-normal"
                              }`}>
                              {`Pay Period ${period.payPeriodNumber} (${period.includeDates})`}
                            </span>
                            {selected && (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </div>
            </Listbox>
          </div>

          <div>
            <button
              type="button"
              onClick={() => setShowPayrollSchedule(!showPayrollSchedule)}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              View Payroll Schedule
            </button>
          </div>
        </div>

        {/* Clinician grid tiles */}
        <div>
          <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
            {clinicianData.map((data) => (
              <li
                key={data.clinicianId}
                className={`overflow-hidden rounded-xl border ${getBorderStyle(
                  data
                )}`}
                onClick={() => handleClinicianClick(data)}>
                <div className="flex items-center gap-x-2 border-b border-gray-900/5 bg-gray-50 p-4">
                  <div className="text-sm font-medium leading-6 text-gray-900">
                    {data.firstName} {data.lastName}
                  </div>
                </div>
                <dl className="-my-3 divide-y divide-gray-100 px-4 py-4 text-sm leading-6">
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Pending Appts</dt>
                    <dd className="font-medium text-gray-900">
                      {data.pendingAppointments.length}
                    </dd>
                  </div>
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Completed Appts</dt>
                    <dd className="font-medium text-gray-900">
                      {data.completedAppointments.length}
                    </dd>
                  </div>
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Carryover Appts</dt>
                    <dd className="font-medium text-gray-900">
                      {data.carryoverAppointments.length}
                    </dd>
                  </div>
                </dl>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {showPayrollSchedule && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={handleCloseModal}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Escape") handleCloseModal();
          }}>
          <div
            className="bg-white rounded-lg shadow-lg p-8 max-w-4xl w-full relative"
            onClick={(e) => e.stopPropagation()}>
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
              <XMarkIcon className="h-6 w-6" />
            </button>
            <PayrollScheduleTable />
          </div>
        </div>
      )}

      {ishrpayrollpage && selectedClinician && (
        <HRPayrollPage
          clinician={selectedClinician}
          selectedPeriod={selectedPeriod}
          isOpen={ishrpayrollpage}
          onClose={() => {
            setishrpayrollpage(false);
            fetchData();
          }}
        />
      )}

      {isPayrollSlideOver && selectedAppointment && (
        <PayrollSlideOver
          isOpen={isPayrollSlideOver}
          appointment={selectedAppointment}
          onClose={() => setIsPayrollSlideOver(false)}
          selectedPeriod={selectedPeriod.payPeriodNumber}
          selectedPeriodDates={selectedPeriod.includeDates}
          isHr={true}
          onUpdateSuccess={fetchData}
        />
      )}
    </>
  );
}
