import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig, generateUploadConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const uploadFile = createAsyncThunk(
  "uploads/uploadFile",
  async (
    { clientId, formData, folderName },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateUploadConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/uploads/${clientId}/uploads?folderName=${folderName}`,
        formData,
        config
      );

      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.data.message === "File with this name already exists"
      ) {
        return rejectWithValue(
          "File with this name already exists. Please rename your file."
        );
      }
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchUploads = createAsyncThunk(
  "uploads/fetchUploads",
  async (clientId, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/uploads/${clientId}/uploads`,
        config
      );
      return response.data.files; // Ensure this is an array of strings
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch uploads."
      );
    }
  }
);

export const fetchUploadsWithDetails = createAsyncThunk(
  "uploads/fetchUploadsWithDetails",
  async (clientId, { getState }) => {
    const { baseURL, config } = generateConfig();
    const token = getState().auth.token;
    const response = await axios.get(
      `${baseURL}/api/uploads/${clientId}/uploads-with-details`,
      {
        headers: {
          ...config.headers,
          "x-user-token": token,
        },
      }
    );
    return response.data;
  }
);

// This thunk returns the metadata
export const fetchFile = createAsyncThunk(
  "uploads/fetchFile",
  async ({ clientId, fileName }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/uploads/${clientId}/uploads/${fileName}`,
        { ...config, responseType: "blob" } // Ensure responseType is blob for binary data
      );

      // Return only metadata
      return {
        fileName,
        fileType: response.headers["content-type"],
        fileBlob: response.data, // Move this to a different location
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch file.");
    }
  }
);

export const fetchFilePassword = createAsyncThunk(
  "uploads/fetchFilePassword",
  async ({ clientId, fileName }, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });
    const response = await axios.get(
      `${baseURL}/api/uploads/${clientId}/password/${fileName}`,
      config
    );
    return response.data;
  }
);

export const checkFilePassword = createAsyncThunk(
  "uploads/checkFilePassword",
  async ({ clientId, fileName, password }, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });
    const response = await axios.post(
      `${baseURL}/api/uploads/check-password`,
      { clientId, fileName, password },
      config
    );
    return response.data;
  }
);

export const recordPasswordAccess = createAsyncThunk(
  "uploads/recordPasswordAccess",
  async ({ clientId, fileName, userId }, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });
    const response = await axios.post(
      `${baseURL}/api/uploads/record-access`,
      { clientId, fileName, userId },
      config
    );
    return response.data;
  }
);
