import React, { useEffect, useState } from "react";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import Table from "../../applicationUi/components/Table";
import { fetchUsers } from "../../store/thunks/userThunk";
import { useDispatch, useSelector } from "react-redux";

function HRPage() {
    const [isAddUserOpen, setIsAddUserOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isEditUserOpen, setIsEditUserOpen] = useState(false);

    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.users || []);

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    const openAddUser = () => setIsAddUserOpen(true);
    const closeAddUser = () => setIsAddUserOpen(false);

    const openEditUser = (user) => {
        console.log("Opening edit user", user);
        setSelectedUser(user);
        setIsEditUserOpen(true);
    };

    const closeEditUser = () => {
        setIsEditUserOpen(false);
        setSelectedUser(null);
    };

    const handleUserClick = (user) => {
        console.log("Handling user click", user);
        openEditUser(user);
    };

    return (
        <>
            <div>
                <div className="mb-10"></div>
                {!isAddUserOpen && !isEditUserOpen && (
                    <>
                        <Table
                            columns={[
                                { header: "First Name", accessor: "firstName" },
                                { header: "Last Name", accessor: "lastName" },
                                { header: "Role", accessor: "role" },
                                { header: "Email", accessor: "email" },
                                { header: "Phone", accessor: "phone" },
                                {
                                    header: "Clinic Name",
                                    accessor: "defaultLocation.name",
                                },
                                {
                                    header: "Qualifications",
                                    accessor: "qualifications",
                                },
                            ]}
                            data={users}
                            onRowClick={handleUserClick}
                            actions={[
                                {
                                    label: "Add User",
                                    onClick: (user) => openAddUser(user),
                                },
                            ]}
                            title="Users"
                            description="A list of all users connected to your account."
                        />
                    </>
                )}
            </div>

            {isAddUserOpen && <AddUser onClose={closeAddUser} />}
            {isEditUserOpen && (
                <EditUser user={selectedUser} onClose={closeEditUser} />
            )}
        </>
    );
}

export default HRPage;
