import React from "react";
import CountUp from "react-countup";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PayrollStats = ({ stats }) => (
  <dl className="mx-auto grid grid-cols-1 gap-px my-4 border border-gray-200 bg-gray-900/5 sm:grid-cols-3 lg:grid-cols-3">
    {stats.map((stat) => (
      <div
        key={stat.name}
        className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-2 py-6 sm:px-2 xl:px-4">
        <dt className="text-sm font-medium leading-6 text-gray-500">
          {stat.name} {stat.extra}
        </dt>
        <dd
          className={classNames(
            stat.changeType === "negative" ? "text-rose-600" : "text-gray-700",
            "text-xs font-medium"
          )}>
          {stat.change}
        </dd>
        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
          {[
            "Pending Appointments",
            "Completed Appointments",
            "Carryover Appointments",
            "Estimated Pay",
            "Total Pending Pay",
            "Total Completed Pay",
            "Total Carryover Pay",
          ].includes(stat.name) ? (
            stat.name.includes("Pay") ? (
              <CountUp
                start={0}
                end={parseFloat(stat.value.replace(/[^0-9.-]+/g, ""))}
                duration={2}
                decimals={2}
                prefix="$"
              />
            ) : (
              <CountUp start={0} end={parseInt(stat.value, 10)} duration={2} />
            )
          ) : (
            stat.value
          )}
        </dd>
      </div>
    ))}
  </dl>
);

export default PayrollStats;
