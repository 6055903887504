import React, { useState } from "react"; // Import useState
import ReportDetail from "./ReportDetails";
import ReportList from "./ReportList";

function ReportsPage() {
    const [selectedReport, setSelectedReport] = useState(null); // Use useState

    const handleSelectReport = (reportName) => {
        setSelectedReport(reportName);
    };

    const handleBack = () => {
        setSelectedReport(null);
    };

    return (
        <div className="min-h-screen">
            {!selectedReport ? (
                <ReportList onSelectReport={handleSelectReport} />
            ) : (
                <ReportDetail reportName={selectedReport} onBack={handleBack} />
            )}
        </div>
    );
}

export default ReportsPage;
