import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

// Fetch all clinics
export const fetchClinics = createAsyncThunk(
  "clinics/fetchAll",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/clinics`, config);
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Fetch a single clinic by ID
export const fetchClinicById = createAsyncThunk(
  "clinics/fetchById",
  async (clinicId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      console.log(`Fetching clinic with ID: ${clinicId}`);
      const response = await axios.get(
        `${baseURL}/api/clinics/${clinicId}`,
        config
      );
      console.log("Clinic fetched successfully:", response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching clinic:", error);
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Create a new clinic
export const createClinic = createAsyncThunk(
  "clinics/create",
  async (clinicData, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/clinics`,
        clinicData,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Update a clinic
export const updateClinic = createAsyncThunk(
  "clinics/update",
  async ({ id, clinicData }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.patch(
        `${baseURL}/api/clinics/${id}`,
        clinicData,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Delete a clinic
export const deleteClinic = createAsyncThunk(
  "clinics/delete",
  async (clinicId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      await axios.delete(`${baseURL}/api/clinics/${clinicId}`, config);
      return clinicId; // Return the deleted clinic's ID
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);
