import UserCards from "../../../applicationUi/components/UserCards";
import AppointmentList from "../../../applicationUi/components/AppointmentList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAppointmentsList } from "../../../store/thunks/appointmentsThunk";
import AdminToDoList from "./AdminToDoList";
import { fetchTodos } from "../../../store/thunks/todoThunk";

export default function AdminDashboardComponent({
    handleClientClick,
    handleAppointmentClick,
    handleReschedule,
}) {
    const dispatch = useDispatch();
    const appointments = useSelector(
        (state) => state.appointments.appointments || []
    );
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        dispatch(fetchAppointmentsList());
        dispatch(fetchTodos({ userRole: "admin", clinicianId: "" }));
    }, [dispatch, refresh]);

    useEffect(() => {
        setFilteredAppointments(appointments);
    }, [appointments]);

    const handleFilterAppointments = (filterFunction) => {
        setFilteredAppointments(filterFunction(appointments));
    };

    const handleWideSidePanelClose = () => {
        setRefresh(prevRefresh => !prevRefresh);
    }

    return (
        <div className="grid grid-cols-3 gap-6 pb-20">
            {/* Left Column */}
            <div className="col-span-2">
                {/* User Cards */}
                <UserCards
                    appointments={appointments}
                    onFilterAppointments={handleFilterAppointments}
                />

                {/* Appointment List aka Recent Activity */}
                <AppointmentList
                    appointments={filteredAppointments}
                    onAppointmentClick={handleAppointmentClick}
                    onClientClick={handleClientClick}
                    onReschedule={handleReschedule} // Pass handleReschedule to AppointmentList
                />
            </div>

            {/* Right Column */}
            <div className="col-span-1">
                <AdminToDoList
                    onReschedule={handleReschedule}
                    onWideSidePanelClose={handleWideSidePanelClose} // Pass the handler down
                />
            </div>
        </div>
    );
}
