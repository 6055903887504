// src/features/kpi/kpiThunk.js

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const fetchWaagData = createAsyncThunk(
  'kpi/fetchKpiData',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token; // Adjust if using different auth state
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/kpi`, config);

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);
