import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from "@heroicons/react/24/outline";
import {
  saveAppointment,
  fetchAppointmentsByClinicianId,
} from "../../store/thunks/appointmentsThunk";
import { fetchClinicianById } from "../../store/thunks/cliniciansThunk";
import { toast } from "react-toastify";
import StatusLights from "./StatusLights";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function formatDateTime(dateString) {
  const date = new Date(dateString);
  const dateOptions = { year: "numeric", month: "short", day: "numeric" };
  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };
  return `${date.toLocaleDateString(
    "en-US",
    dateOptions
  )}, ${date.toLocaleTimeString("en-US", timeOptions)}`;
}

export default function StepsVerticalList({ appointment, refetchAppointment }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [newComment, setNewComment] = useState("");
  const [historyWithUsers, setHistoryWithUsers] = useState([]);

  // Refetch appointments
  const refetchAppointments = (clinicianId) => {
    if (!clinicianId) {
      console.error("Clinician ID is undefined");
      return;
    }
    dispatch(fetchAppointmentsByClinicianId(clinicianId));
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const updatedHistory = await Promise.all(
        appointment.history.map(async (entry) => {
          if (entry.user && typeof entry.user === "string") {
            try {
              const userDetails = await dispatch(
                fetchClinicianById(entry.user)
              ).unwrap();
              return { ...entry, user: userDetails };
            } catch (error) {
              console.error(
                `Error fetching user details for ID ${entry.user}:`,
                error
              );
            }
          }
          return entry;
        })
      );
      setHistoryWithUsers(updatedHistory);
    };

    fetchUsers();
  }, [appointment.history, dispatch]);

  const handleSaveComment = async () => {
    if (!newComment.trim()) return;

    const updatedHistory = [
      ...appointment.history,
      {
        status: "Comments",
        comments: newComment,
        timestamp: new Date().toISOString(),
        user: user._id,
      },
    ];

    const updatedAppointment = {
      ...appointment,
      history: updatedHistory,
    };

    try {
      await dispatch(saveAppointment(updatedAppointment)).unwrap();
      setNewComment("");
      console.log("Commente added successfully");
      toast.success("Comment added successfully");
      refetchAppointment();
      refetchAppointments(appointment.clinician?._id);
    } catch (error) {
      console.log("Comment failed to add", error);
      refetchAppointments(appointment.clinician?._id);
      toast.error("Failed to add comment");
    }
  };

  return (
    <div className="border border-gray-200 rounded-md px-4 py-4 h-full flex flex-col">
      <div className="pb-6 flex items-center ">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Activity Feed
        </h3>
        <div className=" pl-4">
          <StatusLights appointment={appointment} />
        </div>
      </div>
      <nav aria-label="Progress" className="overflow-y-auto flex-1">
        <ol className="overflow-hidden">
          {historyWithUsers.map((step, stepIdx) => (
            <li
              key={step._id || stepIdx}
              className={classNames(
                stepIdx !== historyWithUsers.length - 1 ? "pb-10" : "",
                "relative"
              )}>
              <div
                className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                aria-hidden="true"
              />
              <button
                type="button"
                className="group relative flex items-start text-left w-full"
                aria-current={step.status === "Tentative" ? "step" : undefined}>
                <span className="flex h-9 items-center" aria-hidden="true">
                  <span
                    className={classNames(
                      "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                    )}>
                    {step.status === "Tentative" ? (
                      <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                    ) : step.status === "Comments" ? (
                      <ChatBubbleOvalLeftEllipsisIcon
                        className="h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckIcon
                        className="h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                </span>

                <span className="ml-4 flex min-w-0 flex-col">
                  <span className="text-sm font-medium">{step.status}</span>
                  <span className="text-sm text-gray-500">{step.comments}</span>
                  <span className="text-sm text-gray-400">
                    {formatDateTime(step.timestamp)}
                  </span>
                  {step.user && step.user.firstName && (
                    <span className="text-sm text-gray-400">
                      By: {step.user.firstName} {step.user.lastName} (
                      {step.user.email})
                    </span>
                  )}
                </span>
              </button>
            </li>
          ))}
        </ol>
      </nav>
      <div className="mt-4">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Add a comment"
          className="border border-gray-300 rounded-md px-2 py-1 w-full"
        />
        <button
          onClick={handleSaveComment}
          className="mt-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
          Save Comment
        </button>
      </div>
    </div>
  );
}
